import React, {useEffect, useState} from 'react';
import AuthContext from "./AuthContext";
import {Token} from "../../types/Token";

export const AuthProvider = ({ children } : React.HTMLAttributes<Element>) => {
  const [token, setToken] = useState<Token>({jwt: '', headers: {}});

  useEffect(() => {
    setToken(token)
  }, [token]);

  return (
    <AuthContext.Provider value={token}>{children}</AuthContext.Provider>
  );
};
