import './PatientDetails.css';
import React, {useContext, useEffect} from 'react';
import {IonContent, IonPage} from '@ionic/react';
import SeatUserId from "../../components/PatientDetails/SeatUserId";
import {useHistory} from "react-router-dom";
import {LocationData} from "../../types/LocationData";
import AppLocationContext from "../../components/Includes/AppLocationContext";

const NewPatient: React.FC = () => {
  const history = useHistory();
  const locationContext = useContext<LocationData>(AppLocationContext);

  const handleConfirmView = () => {
    history.push('/patient-details/confirm');
    locationContext.returnView = '/patient-details/confirm'
  }

  const handleBackToWifi = () => {
    history.push('/wifi');
    locationContext.returnView = '/wifi'
  }

  useEffect(() => {
    document.title = "Select a Patient";
  }, [])


  return (
    <IonPage>
      <IonContent fullscreen>
        <SeatUserId confirmPatient={handleConfirmView} handleBackView={handleBackToWifi}/>
      </IonContent>
    </IonPage>
  );
};

export default NewPatient;
