import './PatientDetails.css';
import React, {useContext, useEffect} from 'react';
import {IonContent, IonPage} from '@ionic/react';
import ConfirmSeatUserScreen from "../../components/PatientDetails/ConfirmSeatUserScreen";
import {useHistory} from "react-router-dom";
import {LocationData} from "../../types/LocationData";
import AppLocationContext from "../../components/Includes/AppLocationContext";

const ConfirmPatient: React.FC = () => {
  const history = useHistory();
  const locationContext = useContext<LocationData>(AppLocationContext);

  // patient-details/form
  const handlePatientDetails = () => {
    history.push('/patient-details/form');
    locationContext.returnView = '/patient-details/form'
  }

  // patient-details/new
  const handleSelectView = () => {
    history.push('/patient-details/new');
    locationContext.returnView = '/patient-details/new'
  }


  useEffect(() => {
    document.title = "Confirm patient";
  }, [])

  return (
    <IonPage>
      <IonContent fullscreen>
        <ConfirmSeatUserScreen handleCurrentPatient={handlePatientDetails} handleSelectView={handleSelectView}/>
      </IonContent>
    </IonPage>
  );
};

export default ConfirmPatient;
