import axios from 'axios';
import Service from '../Service/Service';
import {AccountSettings, PasswordSettings} from "../../types/AccountSettings";
import {
  useAccountSettingsData,
  useAccountSettingsPasswordData
} from "../../components/AccountSettings/AccountSettingsHook";
import {localStorageRefs} from "../../Refs";

export default class AccountSettingsService extends Service {

  private casanaCloudApiUrl: string;
  private resetEmailVerifyUrl: string;
  private resetEmailCancelUrl: string;
  private changePasswordUrl: string;
  public accountSettings = useAccountSettingsData();
  public passwordSettings = useAccountSettingsPasswordData();

  constructor(authContext: any) {
    super(authContext);
    this.casanaCloudApiUrl = `${process.env.REACT_APP_API_URL}/v2/setup_wizard/user`;
    this.resetEmailVerifyUrl = `${process.env.REACT_APP_API_URL}/v2/reset_email_request/verify`;
    this.resetEmailCancelUrl = `${process.env.REACT_APP_API_URL}/v2/reset_email_requests`;
    this.changePasswordUrl = `${process.env.REACT_APP_API_URL}/v2/users/{installer-id}/password`
  }

  /**
   * Connect to the Casana Cloud to retrieve the logged-in user's account information
   **/
  public retrieveAccountSettingsData(): any {

    let config = this.getRequestConfig();

    return axios.get(this.casanaCloudApiUrl, config).then((response) => {

        return {
          success: true,
          data: response.data
        }
      }
    )
      .catch(function (error) {
        return {
          success: false,
          data: {
            errors: error.message,
            status_code: error.response.data.code
          }
        }
      });
  }

  /**
   * Update the currently logged-in User entity on the Casana Cloud via the API.
   */
  public updateAccountSettingsData(accountSettings: AccountSettings): any {
    let config = this.getRequestConfig();

    let data: any = {
      "firstName": accountSettings.firstName,
      "lastName": accountSettings.lastName,
      "email": accountSettings.email,
      "cellPhoneId": accountSettings.cellPhoneId,
      "cellPhone": accountSettings.cellPhone,
      "timezone": accountSettings.timezone,
      "enableTwoFactorAuth": accountSettings.enableTwoFactorAuth
    }

    return axios.patch(this.casanaCloudApiUrl, data, config).then(
      (response) => {
        return {
          success: true,
          data: response.data
        }
      })
      .catch(function (error) {
        return {
          success: false,
          data: {
            errors: error.response.data,
            status_code: error.response.status
          }
        }
      });
  }

  /**
   * Update the currently logged-in User's password on the Casana Cloud via the API.
   */
  public updatePassword(passwordSettings: PasswordSettings): any {
    let config = this.getRequestConfig();

    let data: any = {
      "currentPassword": passwordSettings.currentPassword,
      "newPassword": passwordSettings.newPassword,
      "confirmPassword": passwordSettings.confirmPassword
    }

    let installerId = localStorage.getItem(localStorageRefs.installerId) ?? '0';
    let url = this.changePasswordUrl.replace('{installer-id}', installerId);

    return axios.patch(url, data, config).then(
      (response) => {
        console.debug('AccountSettingsService.tsx -> updatePassword()', response);
        return {
          success: true
        }
      })
      .catch(function (error) {
        let message = '';
        for (let violation of error.response.data.violations) {
          message += ` ${violation.message}`;
        }

        return {
          success: false,
          data: {
            errors: message,
            status_code: error.response.status
          }
        }
      });
  }

  /**
   * Update the currently logged-in User's email if the token provided is correct.
   */
  public async verifyEmailChangeWithToken(token: string) {
    let config = this.getRequestConfig();

    let data: any = {
      "token": token
    }

    return axios.patch(this.resetEmailVerifyUrl, data, config).then(
      (response) => {
        console.debug('AccountSettingsService -> verifyEmailChangeWithToken()', response);
        return {
          success: true
        }
      })
      .catch(function (error) {
        return {
          success: false,
          data: {
            errors: error.response.data,
            status_code: error.response.status
          }
        }
      });
  }

  public cancelEmailChange(resetEmailRequestId: number): any {
    let config = this.getRequestConfig();

    return axios.delete(`${this.resetEmailCancelUrl}/${resetEmailRequestId}`, config).then(
      (response) => {
        return {
          success: true
        }
      })
      .catch(function (error) {
        return {
          success: false,
          data: {
            errors: error.response.data,
            status_code: error.response.status
          }
        }
      });
  }

  public refreshEmailVerificationToken(): any {
    let config = this.getRequestConfig();

    return axios.post(`${this.casanaCloudApiUrl}user/refresh-email-verification-token`, {}, config).then(
      (response) => {
        return {
          success: true
        }
      })
      .catch(function (error) {
        return {
          success: false,
          data: {
            errors: error.response.data,
            status_code: error.response.status
          }
        }
      });
  }

}
