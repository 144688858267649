import axios from 'axios';
import Service from '../Service/Service';
import {localStorageRefs, sessionRefs} from "../../Refs";
import SessionHelper from "../../helpers/SessionHelper";

export default class AuthenticationService extends Service {

  private loginURL: string;
  private resetPasswordRequestURL: string;
  private resetPasswordURL: string;
  private logoutURL: string;
  private refreshSessionURL: string;
  private confirmMultifactorCodeURL: string;
  private completeUserRegistrationUrl: string;
  private checkUserRegistrationTokenUrl: string;
  private getEulaURL: string;
  private acceptEulaURL: string;

  constructor(authContext: any) {
    super(authContext);
    this.loginURL = `${process.env.REACT_APP_API_URL}/auth`;
    this.resetPasswordRequestURL = `${process.env.REACT_APP_API_URL}/auth/reset_password_request/request`;
    this.resetPasswordURL = `${process.env.REACT_APP_API_URL}/auth/reset_password_request/reset`;
    this.logoutURL = `${process.env.REACT_APP_API_URL}/v2/logout`;
    this.refreshSessionURL = `${process.env.REACT_APP_API_URL}/v2/refresh`;
    this.confirmMultifactorCodeURL = `${process.env.REACT_APP_API_URL}/v2/verify_second_factor`;
    this.completeUserRegistrationUrl = `${process.env.REACT_APP_API_URL}/auth/users/setup`;
    this.checkUserRegistrationTokenUrl = `${process.env.REACT_APP_API_URL}/auth/users/setup/check_token`;
    this.getEulaURL = `${process.env.REACT_APP_API_URL}/v2/eula`;
    this.acceptEulaURL = `${process.env.REACT_APP_API_URL}/v2/eula/{user_id}`;
  }

  /**
   * Log the user in and setup token and header ata correctly. Also enables the heartbeat pulse.
   *
   * @param email
   * @param password
   */
  public login(email: string, password: string): any {
    this.token.username = email;

    let data = {email: this.token.username, password: password}
    let config = {headers: {'Accept': this.token.headers?.accept, 'X-Casana-Allow-Admin': true, 'X-Casana-Remote-Env': 'SetupWizard'}}

    return axios.post(this.loginURL, data, config).then((response) => {
      console.debug('AuthenticationService.ts -> login()', response);
      localStorage.setItem(localStorageRefs.installerId, response.data.user.id);
      this.setJwt(response.data.token);
      this.token.jwt = response.data.token
      return {
        success: true,
        data: {
          jwt: response.data.token,
          '2fa': response.data['2fa'],
          acceptedUserAgreementAt: response.data.user.acceptedUserAgreementAt
        }
      }
    })
      .catch(function (error) {
        localStorage.removeItem(localStorageRefs.sessionToken);
        localStorage.removeItem(localStorageRefs.installerId);

        // User that are not installers are handled here.
        if (error.response.status === 403) {
          return {
            success: false,
            data: {errors: 'Only users with installer permissions may log in to Setup Wizard'}
          }
        }

        let retVal = {
          success: false,
          data: {}
        }

        if (error.response) {
          retVal.data = {
            errors: error.response.data.message,
            status_code: error.response.status
          }
        }

        return retVal;
      });
  }

  public resetPasswordRequest(email: string): any {
    let data = {email: email}

    let config = {headers: {'Accept': 'application/json', 'X-Casana-Allow-Admin': true, 'X-Casana-Remote-Env': 'SetupWizard'}}

    return axios.post(this.resetPasswordRequestURL, data, config).then((response) => {
      return {success: true}
    })
      .catch(function (error) {
        return {success: false, errors: error.response.data.message};
      });
  }

  public resetPassword(password: string, confirmPassword: string, hashedToken: string): any {
    let data = {password: password, confirmPassword: confirmPassword, hashedToken: hashedToken}

    let config = {headers: {'Accept': 'application/json', 'X-Casana-Allow-Admin': true, 'X-Casana-Remote-Env': 'SetupWizard'}}

    return axios.post(this.resetPasswordURL, data, config).then((response) => {
      return {success: true}
    })
      .catch(function (error) {
        // Expired token message, which will inform user a new token has been generated and emailed to them.
        if (error.response.status === 419) {
          return {success: false, errors: error.response?.data?.message ?? 'An unknown error occurred'}
        }

        return {success: false, errors: error.response.data?.violations?.password?.message ? `Error changing password: ${error.response.data.violations.password.message}` : 'There was an error changing your password.'};
      });
  }

  public checkUserRegistrationToken(setupHashedToken: string): any {

    let data = {
      setupHashedToken: setupHashedToken
    }

    let config = {headers: {'Accept': 'application/json', 'X-Casana-Allow-Admin': true, 'X-Casana-Remote-Env': 'SetupWizard'}}

    return axios.post(this.checkUserRegistrationTokenUrl, data, config).then((response) => {
      return {success: true}
    })
      .catch(function (error) {
        return {success: false, errors: error.response.data.message};
      });
  }

  public completeUserRegistration(
    firstName: string,
    lastName: string,
    password: string,
    confirmPassword: string,
    setupHashedToken: string
  ): any {

    let data = {
      firstName: firstName,
      lastName: lastName,
      password: password,
      confirmPassword: confirmPassword,
      setupHashedToken: setupHashedToken
    }

    let config = {headers: {'Accept': 'application/json', 'X-Casana-Allow-Admin': true, 'X-Casana-Remote-Env': 'SetupWizard'}}

    return axios.patch(this.completeUserRegistrationUrl, data, config).then((response) => {
      return {success: true}
    })
      .catch(function (error) {
        return {success: false, errors: error.response.data.message};
      });
  }

  public confirmMultifactorCode(code: string, trusted: boolean): any {
    let data = {auth_code: code, trusted: trusted}

    let config = {
      headers: {
        'Accept': 'application/json',
        'X-Casana-Allow-Admin': true,
        'X-Casana-Remote-Env': 'SetupWizard',
        'Authorization': `Bearer ${this.token.jwt}`
      }
    }

    return axios.post(this.confirmMultifactorCodeURL, data, config).then((response) => {
      console.debug('AuthenticationService.ts confirmMultifactorCode()', response);
      return {success: true}
    })
      .catch(function (error) {
        return {success: false, errors: error.response.data.message};
      });
  }

  public getEula(): any {
    let config = {
      headers: {
        'Accept': 'application/json',
        'X-Casana-Allow-Admin': true,
        'X-Casana-Remote-Env': 'SetupWizard',
        'Authorization': `Bearer ${this.token.jwt}`
      }
    }

    return axios.get(this.getEulaURL, config).then((response) => {
      console.debug('AuthenticationService.ts getEula()', response);
      return {success: true, data: response.data[0].eulaHtml}
    })
      .catch(function (error) {
        return {success: false, errors: error.response.data.message};
      });
  }

  public acceptEula(): any {
    let data = {acceptEula: true}

    let config = {
      headers: {
        'Accept': 'application/json',
        'X-Casana-Allow-Admin': true,
        'X-Casana-Remote-Env': 'SetupWizard',
        'Authorization': `Bearer ${this.token.jwt}`
      }
    }

    let url = this.acceptEulaURL.replace('{user_id}', localStorage.getItem(localStorageRefs.installerId) ?? '0');

    return axios.patch(url, data, config).then((response) => {
      console.debug('AuthenticationService.ts acceptEula()', response);
      return {success: true}
    })
      .catch(function (error) {
        return {success: false, errors: error.response.data.message};
      });
  }

  /**
   * Logout of the session.
   */
  public logout(): any {
    console.debug('AuthenticationService.ts - logout()');
    let config = this.getRequestConfig();

    return axios.get(this.logoutURL, config).then(() => {
      this.token.jwt = '';
      this.token.username = '';
      SessionHelper.clearData();
    }).catch(function (error) {
      console.error('AuthenticationService.ts logout() - ERROR', error);
      SessionHelper.clearData();
    });
  }

  /**
   * Calls the refresh session endpoint then updates session time back to default session expiry of 30 minutes.
   */
  public async refreshSession(): Promise<any> {
    let config = this.getRequestConfig();

    return axios.get(
      this.refreshSessionURL,
      config
    ).then(function () {
      window.clearInterval(window.sessionTimer);
      window.sessionTimer = window.sessionTimerMethod(Number(sessionRefs.expiryMs));
      return {success: true, data: {authenticated: true}}
    }).catch(function (error) {
        return {
          success: false,
          data: {
            errors: error.message,
            status_code: error.status_code
          }
        }
      }
    )
  };
}
