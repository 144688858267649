import React from 'react';
import {IonModal, IonButton, IonContent, IonIcon, IonRow, IonCol} from '@ionic/react';
import './confirmcancelmodal.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDiamondExclamation} from "@fortawesome/pro-solid-svg-icons";

interface ConfirmCancelModalProps {
  isOpen: boolean;
  headerText: string;
  onButtonAction1: () => void;
  actionButtonText1: string;
  subheaderText?: string;
  footerText?: string;
  onButtonAction2?: () => void;
  actionButtonText2?: string;
  actionButtonStyle1?: string;
  actionButtonStyle2?: string;
  showWarningIcon?: boolean;
  bigHeader?: boolean;
  border?: boolean;
}

const ConfirmCancelModal: React.FC<ConfirmCancelModalProps> =
  ({
     isOpen,
     headerText,
     subheaderText,
     onButtonAction1,
     onButtonAction2,
     actionButtonText1,
     actionButtonText2,
     actionButtonStyle1,
     actionButtonStyle2,
     showWarningIcon,
     bigHeader,
     border,
     footerText
   }) => {

    let actionButtonClass1 = actionButtonStyle1 || "confirm-cancel-button";
    let actionButtonClass2 = onButtonAction2
      ? (actionButtonStyle2 || "confirm-cancel-button confirm-cancel-cancel-button")
      : 'ion-hide';

    return (
      <IonModal
        isOpen={isOpen}
        backdropDismiss={false}
        className={border ? "confirm-cancel-modal confirm-cancel-modal-border" : "confirm-cancel-modal" }
      >
        <div className="confirm-cancel-modal-content-wrapper">
          <div>
            <IonRow className={showWarningIcon ? 'ion-show' : 'ion-hide'}>
              <IonCol size="2" className="confirm-cancel-modal-icon-container">
                <FontAwesomeIcon icon={faDiamondExclamation} className="confirm-cancel-modal-icon" size="4x"/>
              </IonCol>
              <IonCol size="10" className="confirm-cancel-modal-header">
                <span className={bigHeader ? "big-header" : "small-header"}><strong>{headerText}</strong></span>
                <p className={subheaderText ? 'ion-show confirm-cancel-modal-subheader' : 'ion-hide'}>{subheaderText}</p>
                <p className={footerText ? 'ion-show confirm-cancel-modal-footer' : 'ion-hide'}>{footerText}</p>
              </IonCol>
            </IonRow>
            <IonRow className={showWarningIcon ? 'ion-hide' : 'ion-show'}>
              <IonCol size="12" className="confirm-cancel-modal-header">
                <span className={bigHeader ? "big-header" : "small-header"}><strong>{headerText}</strong></span>
                <p className={subheaderText ? 'ion-show confirm-cancel-subheader' : 'ion-hide'}>{subheaderText}</p>
                <p className={footerText ? 'ion-show confirm-cancel-modal-footer' : 'ion-hide'}>{footerText}</p>
              </IonCol>
            </IonRow>
          </div>
        </div>
        <div className="confirm-cancel-modal-actions">
          <IonButton
            className={actionButtonClass2}
            onClick={onButtonAction2}>{actionButtonText2}
          </IonButton>
          <IonButton
            className={actionButtonClass1}
            onClick={onButtonAction1}>{actionButtonText1}
          </IonButton>
        </div>
      </IonModal>
    );
  };

export default ConfirmCancelModal;
