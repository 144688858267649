export default class UnitConversionHelper {

  /**
   * Ratio of pounds to kilograms
   */
  static POUND_TO_KILO = 2.2046;

  /**
   * Ratio of centimeters to inches
   */
  static CENTIMETER_TO_INCH = 2.54;

  /**
   * Ratio of inches to feet
   */
  static INCH_TO_FEET = 12;

  /**
   * Default decimal places for conversion functions
   */
  static DECIMAL_PLACES = 2;

  /**
   * Key name for feet for the feet/inches array
   */
  static UNIT_FEET = 'feet';

  /**
   * Key name for inches for the feet/inches array
   */
  static UNIT_INCHES = 'inches';

  /**
   * Convert pounds to kilograms.
   *
   * @return float
   * @param pounds
   * @param decimalPlaces
   */
  static poundsToKilos(pounds: number, decimalPlaces = this.DECIMAL_PLACES): number {
    return Number((pounds / this.POUND_TO_KILO).toFixed(decimalPlaces));
  }

  /**
   * Convert kilograms to pounds.
   *
   * @return float
   * @param kilos
   * @param decimalPlaces
   */
  static kilosToPounds(kilos: number, decimalPlaces = this.DECIMAL_PLACES): number {
    return Number((kilos * this.POUND_TO_KILO).toFixed(decimalPlaces));
  }

  /**
   * Convert inches to centimeters.
   *
   * @return float
   * @param inches
   * @param decimalPlaces
   */
  static inchesToCentimeters(inches: number, decimalPlaces = this.DECIMAL_PLACES): number {
    return Number((inches * this.CENTIMETER_TO_INCH).toFixed(decimalPlaces));
  }

  /**
   * Convert centimeters to inches
   *
   * @return float|null
   * @param cm
   * @param decimalPlaces
   */
  static centimetersToInches(cm: number, decimalPlaces = this.DECIMAL_PLACES): number|null {
    if (cm === null) {
      return null;
    }

    return Number((cm / this.CENTIMETER_TO_INCH).toFixed(decimalPlaces));
  }

  /**
   * Convert feet and inches to centimeters
   *
   *
   * @return float
   * @param ft
   * @param inches
   * @param decimalPlaces
   */
  public static feetInchesToCentimeters(ft: number, inches: number, decimalPlaces = this.DECIMAL_PLACES) {
    inches = Number(ft * this.INCH_TO_FEET) + Number(inches);
    return this.inchesToCentimeters(inches, decimalPlaces);
  }

  /**
   * Convert centimeters to feet and inches as key/value pairs
   *
   * @param cm
   * @param decimalPlaces
   */
  static centimetersToFeetInches(cm: number, decimalPlaces = this.DECIMAL_PLACES) {
    if (cm === null) {
      return {
        feet: null,
        inches: null
      }
    }

    let inches = this.centimetersToInches(cm, decimalPlaces);
    // @ts-ignore
    let feet = Math.floor(inches / this.INCH_TO_FEET);
    // @ts-ignore
    inches = this.fmod(inches, this.INCH_TO_FEET).toFixed(decimalPlaces);

    return {
      feet: feet,
      inches: inches
    }
  }

  static fmod(a: number, b: number) {
    return Number((a - (Math.floor(a / b) * b)).toPrecision(8));
  };

}
