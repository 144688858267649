import './Initialize.css';
import React, {useContext, useEffect, useState} from 'react';
import {
  IonContent,
  IonPage,
  IonText,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent, IonRow, IonCol, IonLabel, IonFooter, IonButton
} from '@ionic/react';
import {useHistory} from "react-router-dom";
import {InitializationProvider} from "../../components/Initialize/InitializationProvider";
import useSeatSettings from "../../components/SeatSettings/SeatSettingsHook";
import hsi from "../../lib/HeartSeatInterface";
import {LocationData} from "../../types/LocationData";
import AppLocationContext from "../../components/Includes/AppLocationContext";
import {localStorageRefs} from "../../Refs";
import useInitializationData from "../../components/Initialize/InitializationHook";

interface ContainerProps {
  toggleDebug: Function
}

const InitializeFailure: React.FC<ContainerProps> = ({toggleDebug}) => {
  const history = useHistory();
  const seatSettings = useSeatSettings();
  const locationContext = useContext<LocationData>(AppLocationContext);
  const init = useInitializationData();

  const [errorText, setErrorText] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [successText, setSuccessText] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);

  useEffect(() => {
    document.title = "Initialize your seat";
  }, []);

  const clearBanner = () => {
    setSuccess(false);
    setError(false);
  }

  const returnToInitForm = () => {
    clearBanner();
    history.push('/initialize/form')
    locationContext.returnView = '/initialize/form';
  }

  const handleBackToStart = () => {
    clearBanner();
    history.push('/initialize')
    locationContext.returnView = '/initialize';
  }

  /**
   * Clear modals, re-enable auto upload and recording for seat, remove items from local storage, and return to start
   */
  const resetAndRestart = async () => {
    clearBanner();
    await hsi.handleCmd('checkin', null);
    localStorage.removeItem(localStorageRefs.patientId);
    localStorage.removeItem(localStorageRefs.seatId);
    toggleDebug(false);
    history.push("/pair");
    locationContext.returnView = '/pair';
    seatSettings.setButtonClass('off');
    hsi.unregisterAllConnectionStatusHandler();
    await hsi.disconnect();
  }

  return (
    <IonPage>
      <InitializationProvider>
        <IonContent fullscreen className="container">
          <IonCard className={error ? 'ion-show flash-message' : 'ion-hide flash-message'}>
            <IonText className="danger">
              {errorText}
            </IonText>
          </IonCard>
          <IonCard className={success ? 'ion-show flash-message' : 'ion-hide flash-message'}>
            <IonText className="success">
              {successText}
            </IonText>
          </IonCard>
          <IonCard className="standard-container">
            <IonCardHeader>
              <IonCardTitle>Something went wrong...</IonCardTitle>
              <IonCardSubtitle className="m-t-20 m-b-20">The initialization returned an error. If you notice a mistake
                in the parameters listed below, please select the "Back" button to re-enter the cuff values and
                re-analyze the initialization.
              </IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
              <IonRow>
                <IonCol size="4" size-md="4">

                  <IonText className="blockquote">

                    <IonLabel>
                      <IonCardSubtitle className="bold">Initialization Date:</IonCardSubtitle>
                    </IonLabel>
                    <IonText>
                      <IonCardSubtitle>{new Date(init.data.sitStartTime || '').toLocaleTimeString('en-us', {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true
                      })}</IonCardSubtitle>
                    </IonText>

                    <IonLabel><IonCardSubtitle className="bold">Initialization Parameters:</IonCardSubtitle></IonLabel>

                    <IonText>
                      <IonCardSubtitle>BP1: {init.data.bpSystolic1} / {init.data.bpDiastolic1}</IonCardSubtitle>
                      <IonCardSubtitle>BP2: {init.data.bpSystolic2} / {init.data.bpDiastolic2}</IonCardSubtitle>
                      <IonCardSubtitle
                        className="ion-padding-bottom">BP3: {init.data.bpSystolic3} / {init.data.bpDiastolic3}</IonCardSubtitle>

                      <IonCardSubtitle>Sternal notch: {init.data.sternalNotch} in</IonCardSubtitle>
                      <IonCardSubtitle>Height: {init.data.heightFeet} ft {init.data.heightInches} in</IonCardSubtitle>
                      <IonCardSubtitle>Age: {init.data.age} years</IonCardSubtitle>
                      <IonCardSubtitle>Weight: {init.data.weight} lb</IonCardSubtitle>
                    </IonText>
                  </IonText>
                </IonCol>

                {init.data.apiError &&
                  <IonCol size="8" size-md="8">
                    <IonCardSubtitle className="bold">An error occurred. Please try again. If this error persists, contact Casana Support. [Error Code: {init.data.apiError}]</IonCardSubtitle>
                  </IonCol>
                }

                {!init.data.apiError &&
                  <IonCol size="8" size-md="8">
                    <IonCardSubtitle className="bold">Error(s) returned:</IonCardSubtitle>
                    <IonContent scrollY={true} className="initialize-failure-list">
                      <ul>
                        {
                          init.data.errors.map((message: any, key: any) => {
                            return (<li className="init-error-message" key={key}>{message}</li>);
                          })
                        }
                      </ul>
                    </IonContent>
                  </IonCol>
                }

              </IonRow>

            </IonCardContent>
            <IonFooter className="standard-container-footer">
              <IonButton className="btn ion-float-right" onClick={resetAndRestart}>Finish</IonButton>
              <IonButton className="btn btn-back ion-float-right" onClick={handleBackToStart}>Try Again</IonButton>
              <IonButton className="btn btn-back ion-float-right" onClick={returnToInitForm}>Back</IonButton>
            </IonFooter>
          </IonCard>
        </IonContent>
      </InitializationProvider>
    </IonPage>
  );
};

export default InitializeFailure;

