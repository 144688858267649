import React, {useEffect, useContext, useState} from 'react';
import './AccountSettings.css';
import {timezones} from '../../lib/utility/timezones';

import {
  IonGrid,
  IonRow,
  IonCol,
  IonCardContent,
  IonCardTitle,
  IonContent,
  IonCard,
  IonLabel,
  IonItem,
  IonInput,
  IonNote,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonButton,
  IonFooter,
  IonText,
  IonPage
} from '@ionic/react';

import AuthContext from "../../components/Auth/AuthContext";
import AccountSettingsService from "../../services/AccountSettings/AccountSettingsService";
import {
  useAccountSettingsData,
  useAccountSettingsPasswordData
} from "../../components/AccountSettings/AccountSettingsHook";
import {
  saveOutline,
  star,
  personOutline,
  lockClosedOutline,
  arrowForwardOutline,
  arrowBackOutline,
  person,
  mail
} from "ionicons/icons";
import FormHelper from "../../helpers/FormHelper";
import AppLocationContext from "../../components/Includes/AppLocationContext";
import {useHistory, useLocation} from "react-router-dom";
import {validationRefs} from "../../Refs";

const AccountSettings: React.FC = () => {

  const auth = useContext(AuthContext);
  const accountSettingsService = new AccountSettingsService(auth);
  const appLocationContext = useContext(AppLocationContext);
  const location = useLocation();
  const history = useHistory();

  let accountSettings = useAccountSettingsData();
  let passwordSettings = useAccountSettingsPasswordData();

  interface AccountSettingsResponseInterface {
    'firstName': string,
    'lastName': string,
    'email': string,
    'resetEmailRequestId': number,
    'cellPhoneId': number | null,
    'cellPhone': string | null,
    'timezone': string,
    'enableTwoFactorAuth': boolean,
  }

  const [name, setName] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [cellPhone, setCellPhone] = useState<string>('');
  const [timezone, setTimezone] = useState<string>('');
  const [enableTwoFactorAuth, setEnableTwoFactorAuth] = useState<boolean>(false);
  const [resetEmailRequestId, setResetEmailRequestId] = useState<number>(0);

  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const [successMsg, setSuccessMsg] = useState<string>('');
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [accountSettingsSelected, setAccountSettingsSelected] = useState<boolean>(true);

  const [isFirstNameValid, setIsFirstNameValid] = useState<boolean>(true);
  const [isLastNameValid, setIsLastNameValid] = useState<boolean>(true);
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
  const [isCellPhoneValid, setIsCellPhoneValid] = useState<boolean>(true);
  const [isTimezoneValid, setIsTimezoneValid] = useState<boolean>(true);

  const [isCurrentPasswordValid, setIsCurrentPasswordValid] = useState<boolean>(true);
  const [isNewPasswordValid, setIsNewPasswordValid] = useState<boolean>(true);
  const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState<boolean>(true);

  useEffect(() => {
    document.title = "Account Settings";
    if (initialLoad) {
      loadAccountSettings();
      setInitialLoad(false);
    }
  }, []);

  const loadAccountSettings = () => {
    accountSettingsService.retrieveAccountSettingsData().then(async (response: any) => {
      if (response.success) {
        refreshAccountSettingsData(response.data);
      } else {
        setError(true);
        setErrorMsg('There was an error loading account settings: ' + response.data.errors);
      }

    }).catch((error: any) => {
      setError(true);
      setErrorMsg('There was an error loading account settings: ' + error.data.errors);
    });
  }

  const saveAccountSettings = () => {
    clearBanner();

    if (!formIsValid()) {
      setError(true);
      setErrorMsg('There were errors with your form submission');
      return;
    }

    accountSettingsService.updateAccountSettingsData(accountSettings.data).then(async (response: any) => {
      console.debug('AccountSettings.tsx saveAccountSettings()', response);
      if (response.success) {
        setSuccess(true);
        setSuccessMsg('Account settings updated.');
        refreshAccountSettingsData(response.data);
      } else {
        setError(true);

        if (response.data.status_code === 422) {
          let validationErrors =
            response.data.errors.detail.replaceAll('[', '').replaceAll(']', '').replaceAll('"', '').replaceAll(',', '. ');
          setErrorMsg('Error updating account settings: ' + validationErrors);
        } else {
          setErrorMsg('Error updating account settings: ' + response.data.errors);
        }

      }
    }).catch((error: any) => {
      setError(true);
      setErrorMsg('There was an error with your request: ' + JSON.stringify(error));
    });
  };

  const refreshAccountSettingsData = (data: AccountSettingsResponseInterface) => {
    accountSettings.data.firstName = data.firstName;
    accountSettings.data.lastName = data.lastName;
    accountSettings.data.email = data.email;
    accountSettings.data.resetEmailRequestId = data.resetEmailRequestId;
    accountSettings.data.cellPhoneId = data.cellPhoneId === null ? 0 : data.cellPhoneId;
    accountSettings.data.cellPhone = data.cellPhone === null ? '' : data.cellPhone;
    accountSettings.data.timezone = data.timezone;
    accountSettings.data.enableTwoFactorAuth = data.enableTwoFactorAuth;

    setName(`${accountSettings.data.firstName} ${accountSettings.data.lastName}`);
    setFirstName(accountSettings.data.firstName);
    setLastName(accountSettings.data.lastName);
    setEmail(accountSettings.data.email);
    setCellPhone(accountSettings.data.cellPhone);
    setTimezone(accountSettings.data.timezone);
    setEnableTwoFactorAuth(accountSettings.data.enableTwoFactorAuth);
    setResetEmailRequestId(accountSettings.data.resetEmailRequestId);
  }

  const clearPasswordData = () => {
    setCurrentPassword('');
    setNewPassword('');
    setConfirmPassword('');
    setIsCurrentPasswordValid(true);
    setIsNewPasswordValid(true);
    setIsConfirmPasswordValid(true);
  }

  const handleFirstNameChange = (val: string): boolean => {
    accountSettings.data.firstName = val;
    setFirstName(val);
    let isValid = !(val.length === 0 || !val.match(validationRefs.nameRegex));

    setIsFirstNameValid(isValid);
    return isValid;
  }

  const handleLastNameChange = (val: string): boolean => {
    accountSettings.data.lastName = val;
    setLastName(val);
    let isValid = !(val.length === 0 || !val.match(validationRefs.nameRegex));

    setIsLastNameValid(isValid);
    return isValid;
  }

  const handleTimezoneChange = (val: string): boolean => {
    accountSettings.data.timezone = val;
    setTimezone(val);
    let isValid = val.length !== 0;
    setIsTimezoneValid(isValid);
    return isValid;
  }

  const handleEnableTwoFactorAuthChange = (val: string): boolean => {
    accountSettings.data.enableTwoFactorAuth = (val === 'true');
    setEnableTwoFactorAuth(val === 'true');
    return true;
  }

  const handleEmailChange = (email: string): boolean => {
    accountSettings.data.email = email;
    setEmail(email);
    let isValid = checkEmailValid(email);

    setIsEmailValid(isValid);
    return isValid;
  }

  const checkEmailValid = (email: string): boolean => {
    return !!String(email).toLowerCase().match(validationRefs.emailRegex);
  };

  const handleCellPhoneChange = (val: string): boolean => {

    if (val === '' || val === null) {
      accountSettings.data.cellPhone = val;
      setIsCellPhoneValid(true);
      setCellPhone(val);
      return true;
    }

    let formattedCellPhone = val ? FormHelper.formatPhoneNumber(val) : '';
    const parsedVal = val.replace(/[^0-9]/g, '');

    if (parsedVal && formattedCellPhone) {
      accountSettings.data.cellPhone = formattedCellPhone;
      setCellPhone(formattedCellPhone);
    } else {
      accountSettings.data.cellPhone = '';
      setCellPhone('');
    }

    let isValid = parsedVal.length >= 10;
    setIsCellPhoneValid(isValid);

    return isValid;
  }

  const checkPassword = (val: string): boolean => {

    if (val.length === 0) {
      return true;
    }

    return !!val.match(validationRefs.passwordRegex);
  }

  const handleCurrentPasswordChange = (val: string): boolean => {
    passwordSettings.data.currentPassword = val;
    setCurrentPassword(val);
    let isValid = checkPassword(val);
    setIsCurrentPasswordValid(isValid);

    return isValid;
  }

  const handleNewPasswordChange = (val: string): boolean => {
    passwordSettings.data.newPassword = val;
    setNewPassword(val);
    let isValid = checkPassword(val);
    setIsNewPasswordValid(isValid);

    return isValid;
  }

  const handleConfirmPasswordChange = (val: string): boolean => {
    passwordSettings.data.confirmPassword = val;
    setConfirmPassword(val);
    let isValid = checkPassword(val);
    setIsConfirmPasswordValid(isValid);

    return isValid;
  }

  const clearBanner = () => {
    setSuccess(false);
    setSuccessMsg('');
    setError(false);
    setErrorMsg('');
  }

  const cancelAccountSettings = () => {
    clearBanner();
    history.goBack();
    location.pathname = appLocationContext.returnView;
  };

  const cancelPassword = () => {
    clearBanner();
    clearPasswordData();
    loadAccountSettings();
    setAccountSettingsSelected(true);
  };

  const savePassword = () => {
    clearBanner();

    if (!formIsValid()) {
      setError(true);
      setErrorMsg('There were errors with your form submission');
      return;
    }

    accountSettingsService.updatePassword(passwordSettings.data).then(async (response: any) => {
      if (response.success) {
        setSuccess(true);
        setSuccessMsg('Password updated.');
        clearPasswordData();
      } else {
        setError(true);
        setErrorMsg('Error updating password: ' + response.data.errors);
      }
    }).catch((error: any) => {
      setError(true);
      setErrorMsg('Error updating password: ' + JSON.stringify(error));
    });
  };

  const cancelEmailChange = () => {
    clearBanner();

    accountSettingsService.cancelEmailChange(resetEmailRequestId).then(async (response: any) => {
      if (response.success) {
        setSuccess(true);
        setSuccessMsg('Email address change has been canceled.');
        loadAccountSettings();
      } else {
        setError(true);
        setErrorMsg('Error changing email address: ' + response.data.errors);
      }
    }).catch((error: any) => {
      setError(true);
      setErrorMsg('Error changing email address: ' + JSON.stringify(error));
    });
  }

  // @todo this doesn't currently exist in V2 - do we want this?
  const refreshEmailVerificationToken = () => {
    clearBanner();

    accountSettingsService.refreshEmailVerificationToken().then(async (response: any) => {
      if (response.success) {
        setSuccess(true);
        setSuccessMsg('A new token has been sent to your email address.');
        loadAccountSettings();
      } else {
        setError(true);
        setErrorMsg('Error requesting new email verification token: ' + response.data.errors);
      }
    }).catch((error: any) => {
      setError(true);
      setErrorMsg('Error requesting new email verification token: ' + JSON.stringify(error));
    });
  }

  const formIsValid = () => {
    return handleFirstNameChange(accountSettings.data.firstName ?? '')
      && handleLastNameChange(accountSettings.data.lastName ?? '')
      && handleEmailChange(accountSettings.data.email ?? '')
      && handleCellPhoneChange(accountSettings.data.cellPhone ?? '')
      && handleTimezoneChange(accountSettings.data.timezone ?? '');
  }

  const clickGeneralButton = () => {
    clearBanner();
    clearPasswordData();
    setAccountSettingsSelected(true);
  }

  const clickPasswordButton = () => {
    clearBanner();
    clearPasswordData();
    setAccountSettingsSelected(false);
  }

  return (
    <IonPage>
      <IonContent id="main">
        <IonContent className="account-settings-modal-container">
          <IonCard className={error ? 'ion-show flash-message' : 'ion-hide flash-message'}>
            <IonText className="danger">
              {errorMsg}
            </IonText>
          </IonCard>
          <IonCard className={success ? 'ion-show flash-message' : 'ion-hide flash-message'}>
            <IonText className="success">
              {successMsg}
            </IonText>
          </IonCard>
        </IonContent>
        <IonGrid>
          <IonRow>
            <IonCol size-xs="1" size-md="1" className="account-settings-spacer-left-col"></IonCol>
            <IonCol size-xs="3" size-md="3" className="account-settings-left-col">
              <IonCard className="standard-container-content account-settings-button-panel">
                <span>
                  <IonIcon slot="start" ios={person} md={person} className="account-settings-title-icon"/>
                  <IonCardTitle className="account-settings-title">{name}</IonCardTitle>
                </span>
                <div
                  className={accountSettingsSelected ? 'panel-button-enabled' : 'panel-button-disabled'}
                  onClick={clickGeneralButton}>
                  <div className="panel-button-content">
                    <IonLabel>
                      <IonIcon slot="start" ios={personOutline} md={personOutline}/>
                      <span> General </span>
                      <IonIcon
                        className={
                          accountSettingsSelected
                            ? 'ion-show ion-float-right account-settings-button-arrow'
                            : 'ion-hide ion-float-right account-settings-button-arrow'
                        }
                        slot="start"
                        ios={arrowForwardOutline}
                        md={arrowForwardOutline}/>
                    </IonLabel>
                  </div>
                </div>
                <div
                  className={accountSettingsSelected ? 'panel-button-disabled' : 'panel-button-enabled'}
                  onClick={clickPasswordButton}>
                  <div className="panel-button-content">
                    <IonLabel>
                      <IonIcon slot="start" ios={lockClosedOutline} md={lockClosedOutline}/>
                      <span> Change Password </span>
                      <IonIcon
                        className={
                          !accountSettingsSelected
                            ? 'ion-show ion-float-right account-settings-button-arrow'
                            : 'ion-hide ion-float-right account-settings-button-arrow'
                        }
                        slot="start"
                        ios={arrowForwardOutline}
                        md={arrowForwardOutline}/>
                    </IonLabel>
                  </div>
                </div>
              </IonCard>
            </IonCol>
            <IonCol size-xs="8" size-md="8" className="account-settings-panel">
              <IonCard className="standard-container">

                {/* Email Verify Modal */}
                <IonCardContent className={resetEmailRequestId ? 'ion-show' : 'ion-hide'}>
                  <IonCard className="account-settings-email-verification-card">
                    <div className='account-settings-email-verification-title-container'>
                    <span>
                        <IonIcon className="account-settings-email-icon" slot="start" ios={mail}
                                 md={mail}/>
                        <IonCardTitle className="account-settings-title-email-verification">
                          You have requested an email change
                        </IonCardTitle>
                    </span>
                      <p><span>Please verify your updated email by clicking the email we sent to your new email address. Click the Cancel	button to cancel this request.</span></p>
                    </div>
                    <IonCardContent>
                      <form className="casana-form">
                        <IonGrid className="casana-form">
                          <IonRow>
                            <IonCol size="9" size-md="9"></IonCol>
                            <IonCol size="3" size-md="3">
                              <IonButton fill="outline"
                                         className="btn btn-type-code ion-float-right ion-text-capitalize account-settings-alt-button account-settings-email-button-cancel"
                                         onClick={cancelEmailChange}>
                                Cancel
                              </IonButton>
                            </IonCol>

                            {/* @todo I don't see an endpoint for this in V2, wondering if we want/need it */}
                            {/*<IonCol size="3" size-md="3">*/}
                            {/*  <IonButton*/}
                            {/*    fill="outline"*/}
                            {/*    className="btn btn-type-code ion-float-right ion-text-capitalize account-settings-alt-button account-settings-email-button-refresh-token"*/}
                            {/*    onClick={refreshEmailVerificationToken}>*/}
                            {/*    Request New*/}
                            {/*  </IonButton>*/}
                            {/*</IonCol>*/}
                          </IonRow>
                        </IonGrid>
                      </form>
                    </IonCardContent>
                  </IonCard>
                </IonCardContent>

                {/* Account Settings Form */}
                <IonCardContent className={accountSettingsSelected ? 'ion-show' : 'ion-hide'}>
                  <form className="casana-form">
                    <IonGrid className="casana-form">
                      <IonRow>
                        <IonCol size="12" size-md="6">
                          <IonLabel><IonIcon className="required" slot="start" ios={star} md={star}/>First
                            name</IonLabel>
                          <IonItem
                            className={isFirstNameValid ? 'ion-valid' : 'ion-invalid'}>
                            <IonInput
                              required={true}
                              autofocus={true}
                              inputmode={"text"}
                              maxlength={255}
                              type="text"
                              debounce={300}
                              placeholder="First name"
                              onIonChange={(e: any) => handleFirstNameChange(e.detail.value)}
                              value={firstName}
                            />
                            <IonNote slot="error">First name is a required field and cannot include numbers or special
                              characters.</IonNote>
                          </IonItem>
                        </IonCol>
                        <IonCol size="12" size-md="6">
                          <IonLabel><IonIcon className="required" slot="start" ios={star} md={star}/>Last
                            name</IonLabel>
                          <IonItem
                            className={isLastNameValid ? 'ion-valid' : 'ion-invalid'}>
                            <IonInput
                              required={true}
                              autofocus={true}
                              inputmode={"text"}
                              maxlength={255}
                              type="text"
                              debounce={300}
                              placeholder="Last name"
                              onIonChange={(e: any) => handleLastNameChange(e.detail.value)}
                              value={lastName}
                            />
                            <IonNote slot="error">Last name is a required field and cannot include numbers or special
                              characters.</IonNote>
                          </IonItem>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol size="12" size-md="6">
                          <IonLabel><IonIcon className="required" slot="start" ios={star} md={star}/>Email</IonLabel>
                          <IonItem className={isEmailValid ? 'ion-valid' : 'ion-invalid'}>
                            <IonInput
                              required={true}
                              autofocus={true}
                              inputmode={"text"}
                              maxlength={180}
                              type="text"
                              debounce={300}
                              placeholder="Email"
                              onIonChange={(e: any) => handleEmailChange(e.detail.value)}
                              value={email}
                            />
                            <IonNote slot="error">Email must be a valid email address.</IonNote>
                          </IonItem>
                        </IonCol>
                        <IonCol size="12" size-md="6">
                          <IonLabel><IonIcon/>Phone number</IonLabel>
                          <IonItem
                            className={isCellPhoneValid ? 'ion-valid' : 'ion-invalid'}>
                            <IonInput
                              autofocus={true}
                              inputmode="numeric"
                              type="text"
                              placeholder="(555) 555-5555"
                              minlength={14} // 10 digits min plus the four formatting characters for domestic numbers.
                              maxlength={16} // 15 digits max plus the leading + character for international numbers.
                              debounce={300}
                              onIonChange={(e: any) => handleCellPhoneChange(e.detail.value)}
                              value={cellPhone}
                            />
                            <IonNote slot="error">Must be 10-15 digits only, no dashes or spaces.</IonNote>
                          </IonItem>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol size="6" size-md="6">
                          <IonLabel><IonIcon className="required" slot="start" ios={star} md={star}/>Timezone</IonLabel>
                          <IonItem className={
                            isTimezoneValid
                              ? 'account-settings-single-line-item ion-valid'
                              : 'account-settings-single-line-item ion-invalid'}
                          >
                            <IonSelect
                              placeholder="Select timezone"
                              value={timezone}
                              onIonChange={(e: any) => handleTimezoneChange(e.detail.value)}
                            >
                              {timezones.map((timezone) => {
                                return (<IonSelectOption key={timezone} value={timezone}>{timezone}</IonSelectOption>);
                              })};
                            </IonSelect>
                            <IonNote slot="error">Timezone is a required field.</IonNote>
                          </IonItem>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol size="12" size-md="12">
                          <IonLabel>
                            <strong>Enable Two Factor Authentication</strong>
                            <p>Enabling two-factor authentication will send a security code to the email associated with
                              your Casana account.
                              You will need this code in order to sign in to the Casana Cloud.
                            </p>
                          </IonLabel>
                          <IonItem className="account-settings-two-factor">
                            <IonSelect
                              placeholder="Select timezone"
                              value={enableTwoFactorAuth ? "true" : "false"}
                              onIonChange={(e: any) => handleEnableTwoFactorAuthChange(e.detail.value)}
                            >
                              <IonSelectOption value="true">On</IonSelectOption>
                              <IonSelectOption value="false">Off</IonSelectOption>
                            </IonSelect>
                          </IonItem>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </form>
                  <IonItem></IonItem>
                  <IonFooter className="md account-settings-container-footer">
                    <IonRow>
                      <IonCol size="12" size-md="12">
                        <IonButton
                          className="btn btn-type-code ion-float-right account-settings-alt-button account-settings-footer-button"
                          onClick={saveAccountSettings}>
                          <IonIcon slot="start" ios={saveOutline} md={saveOutline}></IonIcon> Save
                        </IonButton>
                        <IonButton fill="outline"
                                   className="btn btn-type-code ion-float-right account-settings-alt-button account-settings-footer-button"
                                   onClick={cancelAccountSettings}>
                          <IonIcon slot="start" ios={arrowBackOutline} md={arrowBackOutline}></IonIcon> Cancel
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  </IonFooter>
                </IonCardContent>

                {/* Password Change Form */}
                <IonCardContent className={!accountSettingsSelected ? 'ion-show' : 'ion-hide'}>
                  <form className="casana-form">
                    <IonGrid className="casana-form">
                      <IonRow>
                        <IonCol size="12" size-md="6">
                          <IonLabel><IonIcon className="required" slot="start" ios={star} md={star}/>Current
                            Password</IonLabel>
                          <IonItem
                            className={
                              isCurrentPasswordValid
                                ? 'account-settings-single-line-item ion-valid'
                                : 'account-settings-single-line-item ion-invalid'}
                          >
                            <IonInput
                              required={true}
                              autofocus={true}
                              inputmode={"text"}
                              minlength={10}
                              maxlength={255}
                              type="password"
                              debounce={300}
                              placeholder="Current password"
                              onIonChange={(e: any) => handleCurrentPasswordChange(e.detail.value)}
                              value={currentPassword}
                            />
                            <IonNote slot="error">{validationRefs.passwordMsg}</IonNote>
                          </IonItem>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol size="12" size-md="6">
                          <IonLabel><IonIcon className="required" slot="start" ios={star} md={star}/>New
                            Password</IonLabel>
                          <IonItem
                            className={isNewPasswordValid ? 'ion-valid' : 'ion-invalid'}>
                            <IonInput
                              required={true}
                              autofocus={true}
                              inputmode={"text"}
                              minlength={10}
                              maxlength={255}
                              type="password"
                              debounce={300}
                              placeholder="New Password"
                              onIonChange={(e: any) => handleNewPasswordChange(e.detail.value)}
                              value={newPassword}
                            />
                            <IonNote slot="error">{validationRefs.passwordMsg}</IonNote>
                          </IonItem>
                        </IonCol>
                        <IonCol size="12" size-md="6">
                          <IonLabel><IonIcon className="required" slot="start" ios={star} md={star}/>Confirm
                            Password</IonLabel>
                          <IonItem
                            className={isConfirmPasswordValid ? 'ion-valid' : 'ion-invalid'}>
                            <IonInput
                              required={true}
                              autofocus={true}
                              inputmode={"text"}
                              minlength={10}
                              maxlength={255}
                              type="password"
                              debounce={300}
                              placeholder="Confirm Password"
                              onIonChange={(e: any) => handleConfirmPasswordChange(e.detail.value)}
                              value={confirmPassword}
                            />
                            <IonNote slot="error">{validationRefs.passwordMsg}</IonNote>
                          </IonItem>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </form>
                  <IonItem></IonItem>
                  <IonFooter className="md account-settings-container-footer">
                    <IonRow>
                      <IonCol size="12" size-md="12">
                        <IonButton
                          className="btn btn-type-code ion-float-right account-settings-alt-button account-settings-footer-button"
                          onClick={savePassword}>
                          <IonIcon slot="start" ios={saveOutline} md={saveOutline}></IonIcon> Save
                        </IonButton>
                        <IonButton fill="outline"
                                   className="btn btn-type-code ion-float-right account-settings-alt-button account-settings-footer-button"
                                   onClick={cancelPassword}>
                          <IonIcon slot="start" ios={arrowBackOutline} md={arrowBackOutline}></IonIcon> Cancel
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  </IonFooter>
                </IonCardContent>

              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default AccountSettings;
