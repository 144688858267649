import './Initialize.css';
import React, {useContext, useEffect, useState} from 'react';
import {
  IonContent,
  IonPage,
  IonText,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonProgressBar,
  IonCardContent, IonRow, IonCol, IonItem, IonInput, IonNote, IonButton, IonFooter
} from '@ionic/react';
import {useHistory} from "react-router-dom";
import {InitializationProvider} from "../../components/Initialize/InitializationProvider";
import {LocationData} from "../../types/LocationData";
import AppLocationContext from "../../components/Includes/AppLocationContext";
import {initRefs, localStorageRefs} from "../../Refs";
import UnitConversionHelper from "../../helpers/UnitConversionHelper";
import hsi from "../../lib/HeartSeatInterface";
import useInitializationData from "../../components/Initialize/InitializationHook";
import usePatientData from "../../components/PatientDetails/PatientHook";
import AuthContext from "../../components/Auth/AuthContext";
import {Token} from "../../types/Token";
import InitializationService from "../../services/InitializationService/InitializationService";
import CloudApiService from "../../services/CloudApiService/CloudApiService";
import useSeatSettings from "../../components/SeatSettings/SeatSettingsHook";
import ConfirmCancelModal from "../../components/Modal/ConfirmCancelModal";


const InitializeForm: React.FC = () => {
  const history = useHistory();
  const locationContext = useContext<LocationData>(AppLocationContext);

  const [errorText, setErrorText] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [successText, setSuccessText] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);

  const init = useInitializationData();
  const patient = usePatientData();
  const authContext = useContext(AuthContext);
  const auth = useContext<Token>(AuthContext);
  const initService = new InitializationService(authContext);
  const ApiService = new CloudApiService(auth);
  const seatSettings = useSeatSettings();
  const seatId = localStorage.getItem(localStorageRefs.seatId);

  const [systolicBp1, setSystolicBp1] = useState(0);
  const [diastolicBp1, setDiastolicBp1] = useState(0);
  const [systolicBp2, setSystolicBp2] = useState(0);
  const [diastolicBp2, setDiastolicBp2] = useState(0);
  const [systolicBp3, setSystolicBp3] = useState(0);
  const [diastolicBp3, setDiastolicBp3] = useState(0);
  const [weight, setWeight] = useState(0);
  const [isSystolic1Valid, setIsSystolic1Valid] = useState<boolean>(true);
  const [isDiastolic1Valid, setIsDiastolic1Valid] = useState<boolean>(true);
  const [isSystolic2Valid, setIsSystolic2Valid] = useState<boolean>(true);
  const [isDiastolic2Valid, setIsDiastolic2Valid] = useState<boolean>(true);
  const [isSystolic3Valid, setIsSystolic3Valid] = useState<boolean>(true);
  const [isDiastolic3Valid, setIsDiastolic3Valid] = useState<boolean>(true);
  const [isWeightValid, setIsWeightValid] = useState<boolean>(true);
  const [isPolling, setIsPolling] = useState<boolean>(false);
  const [hasInitStatusTimeoutError, setHasInitStatusTimeoutError] = useState<boolean>(false);

  useEffect(() => {
    document.title = "Initialize your seat";
    console.debug('InitializeFormScreen.tsx -> useEffect() - patient', patient);
    console.debug('InitializeFormScreen.tsx -> useEffect() - seatSettings', seatSettings);
    console.debug('InitializeFormScreen.tsx -> useEffect() - initSettings', init);
  }, []);

  const setBanner = (success: boolean, message: string) => {
    if (success) {
      setSuccess(true);
      setSuccessText(message);
      setError(false);
    } else {
      setError(true);
      setErrorText(message);
      setSuccess(false);
    }
  }

  const clearBanner = () => {
    setSuccess(false);
    setError(false);
  }

  const handleInitializationSuccess = () => {
    history.push('/initialize/success')
    locationContext.returnView = '/initialize/success';
  }

  const handleInitializationFailure = () => {
    history.push('/initialize/failure')
    locationContext.returnView = '/initialize/failure';
  }

  const handleBackToStart = () => {
    clearBanner();
    setHasInitStatusTimeoutError(false);
    setIsPolling(false);
    setTimeout(function() {
      history.push('/initialize')
      locationContext.returnView = '/initialize';
    }, 50);
  }

  /**
   * Handle setting the diastolic error states correctly.
   */
  const setSystolicInvalid = () => {
    if (systolicBp1 && systolicBp1 >= initRefs.systolicBpMin && systolicBp1 <= initRefs.systolicBpMax) {
      setIsSystolic1Valid(true);
    } else {
      setIsSystolic1Valid(false);
    }

    if (systolicBp2 && systolicBp2 >= initRefs.systolicBpMin && systolicBp2 <= initRefs.systolicBpMax) {
      setIsSystolic2Valid(true);
    } else {
      setIsSystolic2Valid(false);
    }

    if (systolicBp3 && systolicBp3 >= initRefs.systolicBpMin && systolicBp3 <= initRefs.systolicBpMax) {
      setIsSystolic3Valid(true);
    } else {
      setIsSystolic3Valid(false);
    }
  }

  /**
   * Handle setting the diastolic error states correctly.
   */
  const setDiastolicInvalid = () => {
    if (diastolicBp1 && diastolicBp1 >= initRefs.diastolicBpMin && diastolicBp1 <= initRefs.diastolicBpMax) {
      setIsDiastolic1Valid(true);
    } else {
      setIsDiastolic1Valid(false);
    }

    if (diastolicBp2 && diastolicBp2 >= initRefs.diastolicBpMin && diastolicBp2 <= initRefs.diastolicBpMax) {
      setIsDiastolic2Valid(true);
    } else {
      setIsDiastolic2Valid(false);
    }

    if (diastolicBp3 && diastolicBp3 >= initRefs.diastolicBpMin && diastolicBp3 <= initRefs.diastolicBpMax) {
      setIsDiastolic3Valid(true);
    } else {
      setIsDiastolic3Valid(false);
    }
  }

  /**
   * Handle the form validation checking.
   */
  const formIsValid = (): boolean => {
    let systolicValid: boolean;
    let diastolicValid: boolean;
    let weightValid: boolean;

    if (
      diastolicBp1 && diastolicBp2 && diastolicBp3 &&
      diastolicBp1 >= initRefs.diastolicBpMin && diastolicBp3 >= initRefs.diastolicBpMin && diastolicBp3 >= initRefs.diastolicBpMin
      && diastolicBp1 <= initRefs.diastolicBpMax && diastolicBp2 <= initRefs.diastolicBpMax && diastolicBp3 <= initRefs.diastolicBpMax
    ) {
      setIsDiastolic1Valid(true);
      setIsDiastolic2Valid(true);
      setIsDiastolic3Valid(true);
      diastolicValid = true
    } else {
      setDiastolicInvalid()
      diastolicValid = false;
    }

    if (
      systolicBp1 && systolicBp2 && systolicBp3 &&
      systolicBp1 >= initRefs.systolicBpMin && systolicBp2 >= initRefs.systolicBpMin && systolicBp3 >= initRefs.systolicBpMin &&
      systolicBp1 <= initRefs.systolicBpMax && systolicBp2 <= initRefs.systolicBpMax && systolicBp3 <= initRefs.systolicBpMax
    ) {
      setIsSystolic1Valid(true);
      setIsSystolic2Valid(true);
      setIsSystolic3Valid(true);
      systolicValid = true
    } else {
      setSystolicInvalid()
      systolicValid = false;
    }

    if (weight && weight >= initRefs.weightLbsMin && weight <= initRefs.weightLbsMax) {
      setIsWeightValid(true);
      weightValid = true;
    } else {
      setIsWeightValid(false);
      weightValid = false;
    }

    return diastolicValid && systolicValid && weightValid;
  }

  const submitInit = () => {
    clearBanner();

    if (!formIsValid()) {
      setBanner(false, 'There are errors with your initialization values')
      return;
    }

    setIsPolling(true);

    let postData = initializePostData();

    init.data.bpSystolic1 = systolicBp1;
    init.data.bpDiastolic1 = diastolicBp1;
    init.data.bpSystolic2 = systolicBp2;
    init.data.bpDiastolic2 = diastolicBp2;
    init.data.bpSystolic3 = systolicBp3;
    init.data.bpDiastolic3 = diastolicBp3;
    init.data.weight = weight;
    init.data.age = getPatientAge(patient.data.dateOfBirth);
    init.data.sexAssignedAtBirth = patient.data.sexAssignedAtBirth
    init.data.heightFeet = patient.data.seatUserCalibrations.heightFeet;
    init.data.heightInches = patient.data.seatUserCalibrations.heightInches;
    init.data.sternalNotch = patient.data.seatUserCalibrations.sternalNotch

    postData.systolic_bp_list = [
      Number(systolicBp1),
      Number(systolicBp2),
      Number(systolicBp3)
    ];

    postData.diastolic_bp_list = [
      Number(diastolicBp1),
      Number(diastolicBp2),
      Number(diastolicBp3)
    ]

    // Convert imperial to metric to work with V2 init endpoint
    postData.height = UnitConversionHelper.feetInchesToCentimeters(
      patient.data.seatUserCalibrations.heightFeet,
      patient.data.seatUserCalibrations.heightInches
    );
    postData.seat_to_sternal_notch = UnitConversionHelper.inchesToCentimeters(
      patient.data.seatUserCalibrations.sternalNotch
    );
    postData.weight = UnitConversionHelper.poundsToKilos(weight);
    postData.age = getPatientAge(patient.data.dateOfBirth);
    postData.sex = patient.data.sexAssignedAtBirth

    let patientId = localStorage.getItem(localStorageRefs.patientId) ?? '0';
    let sitSessionId = init.data.sitSessionId.toString();

    initService.submitInit(postData, patientId, sitSessionId).then(async (response: any) => {
      console.debug('InitializeFormScreen - submitInit()', response);
      if (!response.success) {
        let error = response.error.message;
        setBanner(false, 'There was an error with your submission: ' + error);
        setIsPolling(false);
        return;
      }

      let token = response.data.token;
      let getInitStatusTries = 0;
      let maxInitStatusTries = 25;

      let statusCheck = window.setInterval(function () {
        initService.getInitStatus(token).then(async (response: any) => {
          // If the Algo service doesn't return a success or failure after more than 2 minutes, display timeout modal.
          if (++getInitStatusTries >= maxInitStatusTries) {
            clearInterval(statusCheck);
            setHasInitStatusTimeoutError(true);
            setIsPolling(false);
            return;
          }

          let status = response.status;

          if (status === 'success') {
            clearInterval(statusCheck);
            await handleStatusSucceeded();
          }

          if (status === 'failed') {
            clearInterval(statusCheck);

            /**
             * This code simply de-dupes any duplicated error/warning message strings.
             */
            init.data.errors = [...new Set(Object.keys(response.errors).map((code: any, key: any) => {
              return response.errors[code];
            }))];
            init.data.warnings = [...new Set(Object.keys(response.warnings).map((code: any, key: any) => {
              return response.warnings[code];
            }))];

            init.data.apiError = response.apiError ?? null;
            await handleStatusFailed();
          }

        });
      }, 5000);

    }).catch((error: any) => {
      console.error('InitializeFormScreen.tsx -> submitInit() - ERROR', error);
    });
  }

  /**
   * Schema for posting initializations.
   */
  const initializePostData = () => {
    return {
      seat_to_sternal_notch: 0,
      height: 0,
      age: 0,
      weight: 0,
      sex: '',
      diastolic_bp_list: [
        0,
        0,
        0
      ],
      systolic_bp_list: [
        0,
        0,
        0
      ]
    }
  }

  const handleStatusSucceeded = async () => {
    setBanner(true, 'Initialization job completed successfully');
    setIsPolling(false);

    if (seatId) {
      await ApiService.revertSeatConfiguration(seatId);
      await hsi.handleCmd('checkin', null);
    }

    handleInitializationSuccess();
  }

  const handleStatusFailed = async () => {
    setIsPolling(false);

    if (seatId) {
      await ApiService.revertSeatConfiguration(seatId);
      await hsi.handleCmd('checkin', null);
    }

    handleInitializationFailure();
  }

  const getPatientAge = (dateOfBirth: string | undefined): number => {
    if (!dateOfBirth) {
      return 20;
    }

    let today = new Date();
    let birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  }

  // Hack to submit form if enter key is pressed.
  const checkEnter = async (e: any) => {
    if (e.key === 'Enter') {
      await submitInit();
    }
  }

  return (
    <IonPage>
      <InitializationProvider>
        <IonContent fullscreen className="container">
          <IonCard className={error ? 'ion-show flash-message' : 'ion-hide flash-message'}>
            <IonText className="danger">
              {errorText}
            </IonText>
          </IonCard>
          <IonCard className={success ? 'ion-show flash-message' : 'ion-hide flash-message'}>
            <IonText className="success">
              {successText}
            </IonText>
          </IonCard>
          <IonCard className="standard-container">
            <IonCardHeader>
              <IonCardTitle className="init-form-title">Enter initialization information</IonCardTitle>
              {isPolling ? <IonProgressBar type="indeterminate"></IonProgressBar> : null}
            </IonCardHeader>
            <IonCardContent className="init-form casana-form initialize-screen">
              <IonRow className="blood-pressure-row">
                <IonCol size="3" size-md="4" className="ion-text-right init-form-bp-1"></IonCol>
                <IonCol size="3" size-md="2">
                  <IonItem className={`${isSystolic1Valid && 'ion-valid'} ${!isSystolic1Valid && 'ion-invalid'}`}>
                    <IonInput id="bp-sys-1"
                              placeholder=""
                              type="number"
                              min={initRefs.systolicBpMin}
                              max={initRefs.systolicBpMax}
                              onIonChange={(e: any) => setSystolicBp1(e.detail.value)}
                    ></IonInput>
                    <IonNote slot="error">Must be between 60 and 299</IonNote>
                  </IonItem>
                </IonCol>
                <IonCol size="1" size-md="1" className="ion-text-center">/</IonCol>
                <IonCol size="3" size-md="2">
                  <IonItem className={`${isDiastolic1Valid && 'ion-valid'} ${!isDiastolic1Valid && 'ion-invalid'}`}>
                    <IonInput id="bp-dia-1"
                              placeholder=""
                              type="number"
                              min={initRefs.diastolicBpMin}
                              max={initRefs.diastolicBpMax}
                              onIonChange={(e: any) => setDiastolicBp1(e.detail.value)}
                    ></IonInput>
                    <IonNote slot="error">Must be between 40 and 299</IonNote>
                  </IonItem>
                </IonCol>
                <IonCol size="2" size-md="3"></IonCol>
              </IonRow>
              <IonRow className="blood-pressure-row">
                <IonCol size="3" size-md="4" className="ion-text-right init-form-bp-2"></IonCol>
                <IonCol size="3" size-md="2">
                  <IonItem className={`${isSystolic2Valid && 'ion-valid'} ${!isSystolic2Valid && 'ion-invalid'}`}>
                    <IonInput id="bp-sys-2"
                              placeholder=""
                              type="number"
                              min={initRefs.systolicBpMin}
                              max={initRefs.systolicBpMax}
                              onIonChange={(e: any) => setSystolicBp2(e.detail.value)}
                    ></IonInput>
                    <IonNote slot="error">Must be between 60 and 299</IonNote>
                  </IonItem>
                </IonCol>
                <IonCol size="1" size-md="1" className="ion-text-center">/</IonCol>
                <IonCol size="3" size-md="2">
                  <IonItem className={`${isDiastolic2Valid && 'ion-valid'} ${!isDiastolic2Valid && 'ion-invalid'}`}>
                    <IonInput id="bp-dia-2"
                              placeholder=""
                              type="number"
                              min={initRefs.diastolicBpMin}
                              max={initRefs.diastolicBpMax}
                              onIonChange={(e: any) => setDiastolicBp2(e.detail.value)}
                    ></IonInput>
                    <IonNote slot="error">Must be between 40 and 299</IonNote>
                  </IonItem>
                </IonCol>
                <IonCol size="2" size-md="3"></IonCol>
              </IonRow>
              <IonRow className="blood-pressure-row">
                <IonCol size="3" size-md="4" className="ion-text-right init-form-bp-3"></IonCol>
                <IonCol size="3" size-md="2">
                  <IonItem className={`${isSystolic3Valid && 'ion-valid'} ${!isSystolic3Valid && 'ion-invalid'}`}>
                    <IonInput id="bp-sys-3"
                              placeholder=""
                              type="number"
                              min={initRefs.systolicBpMin}
                              max={initRefs.systolicBpMax}
                              onIonChange={(e: any) => setSystolicBp3(e.detail.value)}
                    ></IonInput>
                    <IonNote slot="error">Must be between 60 and 299</IonNote>
                  </IonItem>
                </IonCol>
                <IonCol size="1" size-md="1" className="ion-text-center">/</IonCol>
                <IonCol size="3" size-md="2">
                  <IonItem className={`${isDiastolic3Valid && 'ion-valid'} ${!isDiastolic3Valid && 'ion-invalid'}`}>
                    <IonInput id="bp-dia-3"
                              placeholder=""
                              type="number"
                              min={initRefs.diastolicBpMin}
                              max={initRefs.diastolicBpMax}
                              onIonChange={(e: any) => setDiastolicBp3(e.detail.value)}
                    ></IonInput>
                    <IonNote slot="error">Must be between 40 and 299</IonNote>
                  </IonItem>
                </IonCol>
                <IonCol size="2" size-md="3"></IonCol>
              </IonRow>
              <IonRow className="blood-pressure-row">
                <IonCol size="3" size-md="4" className="ion-text-right">Weight:</IonCol>
                <IonCol size="3" size-md="5">
                  <IonItem className={`${isWeightValid && 'ion-valid init-weight-field'} ${!isWeightValid && 'ion-invalid init-weight-field'}`}>
                    <IonInput id="weight"
                              placeholder="Weight in lb"
                              type="number"
                              min={initRefs.weightLbsMin}
                              max={initRefs.weightLbsMax}
                              onIonChange={(e: any) => setWeight(e.detail.value)}
                              onKeyUp={(e: any) => checkEnter(e)}
                    ></IonInput>
                    <IonNote slot="error">Must be between 90-350 lb</IonNote>
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow className="m-t-20">
                <IonCol size="3" size-md="3"></IonCol>
                <IonCol size="6" size-md="6">
                  <IonButton className="initialize-form-submit" disabled={isPolling} onClick={submitInit}>Submit</IonButton>
                </IonCol>
                <IonCol size="2" size-md="3"></IonCol>
              </IonRow>
            </IonCardContent>
            <IonFooter className="standard-container-footer">
              <IonButton disabled={isPolling}
                         className="btn btn-back ion-float-right" onClick={handleBackToStart}>
                Back
              </IonButton>
            </IonFooter>
          </IonCard>
        </IonContent>
        <ConfirmCancelModal
          isOpen={hasInitStatusTimeoutError}
          headerText="An error occurred"
          subheaderText="Please try again. If this error persists, contact Casana Support."
          onButtonAction1={handleBackToStart}
          actionButtonText1="Try Again"
          showWarningIcon={true}
          bigHeader
        />
      </InitializationProvider>
    </IonPage>
  );
};

export default InitializeForm;

