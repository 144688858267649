import React, {useEffect, useState} from 'react';

import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonImg
} from '@ionic/react';

import AuthenticationForm from '../../components/forms/AuthenticationForm/AuthenticationForm';
import './Login.css';
import { Image } from '../../types/Image';
import ConfirmCancelModal from "../../components/Modal/ConfirmCancelModal";

const Login: React.FC = () => {

  useEffect(() => {
    document.title = "Login"
    checkBrowser();
  }, [])

  const loginImg: Image = { src: './assets/toilet.svg', text: 'Login' };
  const [userHasUnsupportedBrowser, setUserHasUnsupportedBrowser] = useState<boolean>(false);

  const checkBrowser = () => {
    console.debug('Browser:', window.navigator.userAgent);
    if (/Edg/.test(window.navigator.userAgent) || /Chrome/.test(window.navigator.userAgent)) {
      return;
    }

    setUserHasUnsupportedBrowser(true);
  }

  return (
    <IonPage>
      <IonContent fullscreen className="login-screen">
        <IonGrid className="ion-no-padding">
            <IonRow className="ion-justify-content-center">
              <IonCol className="ion-float-left login-image desktop">
                <IonImg src={loginImg.src} alt={loginImg.text} className="toilet-seat" />
              </IonCol>
              <IonCol className="ion-float-right login-form-column ion-no-padding">
                <AuthenticationForm />
              </IonCol>
            </IonRow>
        </IonGrid>
      </IonContent>
      <ConfirmCancelModal
        isOpen={userHasUnsupportedBrowser}
        headerText="You are using an unsupported browser"
        subheaderText="Please ensure you are using either Google Chrome or Microsoft Edge."
        onButtonAction1={() => {setUserHasUnsupportedBrowser(false)}}
        actionButtonText1="OK"
        showWarningIcon={true}
      />
    </IonPage>
  );
};

export default Login;
