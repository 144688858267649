import './Initialize.css';
import React, {useContext, useEffect, useState} from 'react';
import {
  IonContent,
  IonPage,
  IonText,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent, IonList, IonItem, IonLabel, IonFooter, IonButton
} from '@ionic/react';
import {useHistory} from "react-router-dom";
import {InitializationProvider} from "../../components/Initialize/InitializationProvider";
import {LocationData} from "../../types/LocationData";
import AppLocationContext from "../../components/Includes/AppLocationContext";
import {localStorageRefs} from "../../Refs";
import {SitSession} from "../../types/SitSession";
import useInitializationData from "../../components/Initialize/InitializationHook";
import usePatientData from "../../components/PatientDetails/PatientHook";
import AuthContext from "../../components/Auth/AuthContext";
import InitializationService from "../../services/InitializationService/InitializationService";


const InitializeExisting: React.FC = () => {
  const history = useHistory();
  const locationContext = useContext<LocationData>(AppLocationContext);

  const [errorText, setErrorText] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [successText, setSuccessText] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);

  const init = useInitializationData();
  const patient = usePatientData();

  const authContext = useContext(AuthContext);
  const initService = new InitializationService(authContext);
  const [sitSessionData, setSitSessionData] = useState([{
    id: 0,
    sit_start_time: '',
    raw_recording_filename: '',
    firmware_version: '',
    hardware_version: '',
    seat_user_id: 0,
  }]);

  useEffect(() => {
    document.title = "Initialize your seat";
    console.debug('InitializeExistingRecordingScreen.tsx useEffect()');
    let seatUserId = localStorage.getItem(localStorageRefs.patientId);
    let seatId = localStorage.getItem(localStorageRefs.seatId);

    if (!seatUserId || !seatId) {
      setBanner(false, 'Please select a patient and seat from Patient Details before initializing.')
      setSitSessionData([]);
      return;
    }

    initService.getInitRecordings(Number(seatId), Number(seatUserId)).then(async (response: any) => {

      if (!response.success) {
        return;
      }

      setSitSessionData(response.data);

    }).catch((error: any) => {
      console.error(error);
    });

  }, []);

  const selectSitSession = (sitSession: SitSession): any => {
    console.debug('InitializeExistingRecordingScreen.tsx selectSitSession() sit session', sitSession);
    console.debug('InitializeExistingRecordingScreen.tsx selectSitSession() patient', patient);
    init.data.sitSessionId = sitSession.id;
    init.data.rawRecordingFilename = sitSession.raw_recording_filename;
    init.data.firmwareVersion = sitSession.firmware_version;
    init.data.hardwareVersion = sitSession.hardware_version;
    init.data.seatUserId = sitSession.seat_user_id;
    init.data.dateOfBirth = patient.data.dateOfBirth || '2000-01-01';
    init.data.sitStartTime = sitSession.sit_start_time;
    init.data.heightFeet = patient.data.seatUserCalibrations.heightFeet;
    init.data.heightInches = patient.data.seatUserCalibrations.heightInches;
    init.data.sternalNotch = patient.data.seatUserCalibrations?.sternalNotch;
    init.parseInitializationData(init.data);
    handleFormScreenLink();
  }

  const formatSitDateTime = (sitDateTime: string): string => {
    return new Date(sitDateTime).toLocaleTimeString('en-us', {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true
    });
  }

  const setBanner = (success: boolean, message: string) => {
    if (success) {
      setSuccess(true);
      setSuccessText(message);
      setError(false);
    } else {
      setError(true);
      setErrorText(message);
      setSuccess(false);
    }
  }

  const clearBanner = () => {
    setSuccess(false);
    setError(false);
  }

  const handleFormScreenLink = () => {
    history.push('/initialize/form')
    locationContext.returnView = '/initialize/form';
  }

  const handleBackToStart = () => {
    clearBanner();
    history.push('/initialize')
    locationContext.returnView = '/initialize';
  }

  return (
    <IonPage>
      <InitializationProvider>
        <IonContent fullscreen className="container">
          <IonCard className={error ? 'ion-show flash-message' : 'ion-hide flash-message'}>
            <IonText className="danger">
              {errorText}
            </IonText>
          </IonCard>
          <IonCard className={success ? 'ion-show flash-message' : 'ion-hide flash-message'}>
            <IonText className="success">
              {successText}
            </IonText>
          </IonCard>
          <IonCard className="standard-container">
            <IonCardHeader>
              <IonCardTitle>Select a recent recording</IonCardTitle>
              <IonCardSubtitle className="m-t-20 m-b-20">Sits that meet the 4.5-minute criteria for initialization are listed
                below. Select a recording to continue.
              </IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
              {sitSessionData.length > 0 && <IonList className="sit-session-list">
                {sitSessionData.map(sitSession => {
                  return (
                    <IonItem key={sitSession.id}>
                      <IonLabel className="initialize-link" onClick={() => {
                        selectSitSession(sitSession)
                      }}>
                        {formatSitDateTime(sitSession.sit_start_time) + ' >'}
                      </IonLabel>
                    </IonItem>
                  );
                })}
                {/* Figure out why this IonItem below needs to be here or the whole list disappears */}
                <IonItem></IonItem>
              </IonList>}
              {!sitSessionData.length && <IonList className="sit-session-list">
                <IonCardSubtitle className="bold">No recordings found, please click "back" and make a new
                  recording</IonCardSubtitle>
              </IonList>}
            </IonCardContent>
            <IonFooter className="standard-container-footer">
              <IonButton className="btn btn-back ion-float-right" onClick={handleBackToStart}>Back</IonButton>
            </IonFooter>
          </IonCard>
        </IonContent>
      </InitializationProvider>
    </IonPage>
  );
};

export default InitializeExisting;

