import React, {createContext, useCallback, useState} from 'react';
import {InitializeData} from "../../types/InitializeData";


interface InitializeDataInterface {
  data: InitializeData,
  parseInitializationData: Function,
}

export const InitializeContext = createContext<InitializeDataInterface>({
  data: {
    seatUserId: 0,
    bpSystolic1: 0,
    bpDiastolic1: 0,
    bpSystolic2: 0,
    bpDiastolic2: 0,
    bpSystolic3: 0,
    bpDiastolic3: 0,
    sternalNotch: 0,
    heightFeet: 0,
    heightInches: 0,
    weight: 0,
    dateOfBirth: '',
    sexAssignedAtBirth: '',
    age: 0,
    firmwareVersion: '',
    rawRecordingFilename: '',
    hardwareVersion: '',
    sitSessionId: 0,
    sitStartTime: '',
    apiError: null,
    errors: [],
    warnings: []
  },
  parseInitializationData: (initializationData: InitializeData) => {}
});

export const InitializationProvider = ({ children } : React.HTMLAttributes<Element>) => {
  let [initializeData] = useState<InitializeData>({
    seatUserId: 0,
    bpSystolic1: 0,
    bpDiastolic1: 0,
    bpSystolic2: 0,
    bpDiastolic2: 0,
    bpSystolic3: 0,
    bpDiastolic3: 0,
    sternalNotch: 0,
    heightFeet: 0,
    heightInches: 0,
    weight: 0,
    dateOfBirth: '',
    sexAssignedAtBirth: '',
    age: 0,
    firmwareVersion: '',
    rawRecordingFilename: '',
    hardwareVersion: '',
    sitSessionId: 0,
    sitStartTime: '',
    apiError: null,
    errors: [],
    warnings: []
  });

  const parseInitializationData = (data: InitializeData) => {
    if(data.seatUserId) {
      initializeData.seatUserId = data.seatUserId
    }

    if(data.bpSystolic1) {
      initializeData.bpSystolic1 = data.bpSystolic1
    }

    if(data.bpDiastolic1) {
      initializeData.bpDiastolic1 = data.bpDiastolic1
    }

    if(data.bpSystolic2) {
      initializeData.bpSystolic2 = data.bpSystolic2
    }

    if(data.bpDiastolic2) {
      initializeData.bpDiastolic2 = data.bpDiastolic2
    }

    if(data.bpSystolic3) {
      initializeData.bpSystolic3 = data.bpSystolic3
    }

    if(data.bpDiastolic3) {
      initializeData.bpDiastolic3 = data.bpDiastolic3
    }

    if(data.sternalNotch) {
      initializeData.sternalNotch = data.sternalNotch
    }

    if(data.heightFeet) {
      initializeData.heightFeet = data.heightFeet
    }

    if(data.heightInches) {
      initializeData.heightInches = data.heightInches
    }

    if(data.weight) {
      initializeData.weight = data.weight
    }

    if(data.dateOfBirth) {
      initializeData.dateOfBirth = data.dateOfBirth
    }

    if(data.sexAssignedAtBirth) {
      initializeData.sexAssignedAtBirth = data.sexAssignedAtBirth
    }

    if(data.age) {
      initializeData.age = data.age
    }

    if(data.firmwareVersion) {
      initializeData.firmwareVersion = data.firmwareVersion
    }

    if(data.rawRecordingFilename) {
      initializeData.rawRecordingFilename = data.rawRecordingFilename
    }

    if(data.hardwareVersion) {
      initializeData.hardwareVersion = data.hardwareVersion
    }

    if(data.sitSessionId) {
      initializeData.sitSessionId = data.sitSessionId
    }

    if(data.sitStartTime) {
      initializeData.sitStartTime = data.sitStartTime
    }

    if(data.errors) {
      initializeData.errors = data.errors
    }

    if(data.warnings) {
      initializeData.warnings = data.warnings
    }
  }

  const contextValue = {
    data: initializeData,
    parseInitializationData: useCallback((data: InitializeData) => parseInitializationData(data), [])
  };


  return (
    <InitializeContext.Provider value={contextValue}>
      {children}
    </InitializeContext.Provider>
  );
};
