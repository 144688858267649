import { createContext } from "react";
import {PairData} from "../../types/PairData";

const PairContext = createContext<PairData>({
  firmwareVersion: 0,
  serialNumber: '',
  bleAuthToken: '',
  bleContext: null
});

export default PairContext;
