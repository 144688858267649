import {useContext} from "react";
import {AccountSettingsDataContext, PasswordSettingsDataContext} from "./AccountSettingsContext";

function useAccountSettingsData() {
  const {data} = useContext(AccountSettingsDataContext);
  return {data};
}

function useAccountSettingsPasswordData() {
  const {data} = useContext(PasswordSettingsDataContext);
  return {data};
}

export {useAccountSettingsData, useAccountSettingsPasswordData}
