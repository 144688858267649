import './PatientDetailsScreens.css';
import React, {useEffect, useState} from 'react';
import {
  IonButton, IonCard, IonCardContent, IonCardHeader, IonGrid, IonRow, IonCol,
  IonCardTitle, IonFooter, IonText, IonContent,
} from '@ionic/react';
import usePatientData from "./PatientHook";
import useSeatSettingsData from "../SeatSettings/SeatSettingsHook";
import ConfirmCancelModal from "../Modal/ConfirmCancelModal";

interface ContainerProps {
  handleCurrentPatient: Function,
  handleSelectView: Function
}

const ConfirmSeatUserScreen: React.FC<ContainerProps> = ({handleCurrentPatient, handleSelectView}) => {

  const patient = usePatientData();
  const seatSettings = useSeatSettingsData();

  const [isPatientPairedWithOtherSeat, setIsPatientPairedWithOtherSeat] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const modalHeaderText = 'Are you sure?';
  const modalConfirmText = 'This patient is currently paired to another seat. Pairing them to a new seat will unpair ' +
    'them from their current seat.';

  useEffect(() => {
    document.title = "Confirm Patient Name";

    console.debug('ConfirmSeatUserScreen.tsx useEffect() - patient', patient);

    if (
      patient.data.assignedSeatSerialNumber &&
      patient.data.assignedSeatSerialNumber !== seatSettings.settings.serialNumber) {
      setIsPatientPairedWithOtherSeat(true);
    }
  }, [patient.data, seatSettings.settings, isPatientPairedWithOtherSeat]);

  const pairCurrentPatient = () => {
    if (isPatientPairedWithOtherSeat) {
      setShowModal(true);
    } else {
      handleCurrentPatient();
    }
  }

  const selectNewId = () => {
    handleSelectView()
  }

  const handleConfirm = () => {
    setShowModal(false);

    // Hack to deal with React state/timing issues which prevents modal from disappearing on the next screen.
    setTimeout(function () {
      handleCurrentPatient();
    }, 500);
  }

  const handleCancel = () => {
    setShowModal(false);
  }

  return (
    <>
      <IonContent className="container">
        <IonCard className="standard-container">
          <IonCardHeader>
            <IonCardTitle className="m-b-20">Pair seat with this Patient?</IonCardTitle>
          </IonCardHeader>
          <IonCardContent className="standard-container-content">
            <IonGrid className="seat-user-id-form">
              <IonRow>
                <IonCol size="12" size-sm="12">
                  <IonCardContent className="seat-user-confirmation"
                                  onClick={pairCurrentPatient}>
                    <IonText className="patient-name md">{patient.data.firstName} {patient.data.lastName}</IonText>
                    <IonText>[{patient.data.seatUserId}]</IonText>
                  </IonCardContent>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardContent>
          <IonFooter className="standard-container-footer">
            <IonButton className="btn btn-next ion-float-right" onClick={pairCurrentPatient}>
              Next
            </IonButton>
            <IonButton className="btn btn-back ion-float-right" onClick={selectNewId}>
              Back
            </IonButton>
          </IonFooter>
        </IonCard>
        <ConfirmCancelModal
          isOpen={showModal}
          headerText={modalHeaderText}
          subheaderText={modalConfirmText}
          onButtonAction1={handleConfirm}
          onButtonAction2={handleCancel}
          actionButtonText1="Confirm"
          actionButtonText2="Cancel"
          showWarningIcon={true}
          bigHeader
        />
      </IonContent>
    </>
  );
};

export default ConfirmSeatUserScreen;
