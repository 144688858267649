import ParamDef from './ParamDef';

/* type representing a raw buffer parameter / return data */
class BytesDef extends ParamDef {
  async unpack(buf) {
    return buf;
  }

  async pack(unpackedArg) {
    return Buffer.from(unpackedArg);
  }

  print(unpackedArg) {
    return unpackedArg.toString();
  }
}

export default BytesDef;
