import {validationRefs} from "../Refs";

export default class FormHelper {

  /**
   * Format phone number for forms. Will format for domestic phone numbers and for foreign numbers based on length.
   *
   * @param str
   */
  static formatPhoneNumber(str: string) {
    str = str.replace(/\D/g, '');
    let size = str.length;

    /**
     * For domestic phone numbers.
     */
    if (size > 0 && size <= 10) {
      str = "(" + str
    }

    if (size > 3 && size <= 10) {
      str = str.slice(0, 4) + ") " + str.slice(4, 11);
    }

    if (size > 6 && size <= 10) {
      str = str.slice(0, 9) + "-" + str.slice(9)
    }

    /**
     * For international phone numbers.
     */
    if (size > 10) {
      str = `+${str}`;
    }

    return str;
  }
}
