import React, { useState, useCallback } from 'react';

export const ModalContext = React.createContext({
  error: {title: null, message: null, buttonText: null, buttonLocation: null},
  addError: (
    title: string | null,
    message: string | null,
    buttonText: string | null,
    buttonLocation: string | null
  ) => {},
  removeError: () => {}
});


export default function ModalProvider({children}:{children:React.ReactNode}){
  const [error, setError] = useState({title : null, message: null, buttonText: null, buttonLocation: null});

  const removeError = () => setError({title: null, message: null, buttonText: null, buttonLocation: null});

  const addError = (
    title: any,
    message: any,
    buttonText: any,
    buttonLocation: any
  ) => setError({
    title,
    message,
    buttonText,
    buttonLocation
  });

  const contextValue = {
    error,
    addError: useCallback((
      title: string | null,
      message: string | null,
      buttonText: string | null,
      buttonLocation: string | null,
    ) => addError(title, message, buttonText, buttonLocation), []),
    removeError: useCallback(() => removeError(), [])
  };

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
    </ModalContext.Provider>
  );
}
