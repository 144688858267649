import React, {createContext, useCallback, useState} from 'react';
import {Patient} from "../../types/Patient";

interface PatientDataInterface {
  data: Patient,
  parsePatientData: Function,
}

export const PatientDataContext = createContext<PatientDataInterface>({
  'data': {
    'assignedSeats': {},
    'assignedSeatId': 0,
    'assignedSeatSerialNumber': '',
    'seatUserId': 0,
    'cellPhoneId': 0,
    'cellPhone': '',
    'email': '',
    'firstName': '',
    'lastName': '',
    'dateOfBirth': '',
    'monitoredSince': '',
    'addressId': 0,
    'address': '',
    'addressOther': '',
    'city': '',
    'state': '',
    'zipCode': '',
    'country': '',
    'sexAssignedAtBirth': '',
    'seatUserCalibrations': {
      'seatUserCalibrationId': 0,
      'heightFeet': 0,
      'heightInches': 0,
      'sternalNotch': 0
    },
  },
  parsePatientData: (patientData: Patient) => {} ,
});

export const PatientProvider = ({ children } : React.HTMLAttributes<Element>) => {
  let [patient] = useState<Patient>({
    'assignedSeats': {},
    'assignedSeatId': 0,
    'assignedSeatSerialNumber': '',
    'seatUserId': 0,
    'cellPhoneId': 0,
    'cellPhone': '',
    'email': '',
    'firstName': '',
    'lastName': '',
    'dateOfBirth': '',
    'monitoredSince': '',
    'addressId': 0,
    'address': '',
    'addressOther': '',
    'city': '',
    'state': '',
    'zipCode': '',
    'country': '',
    'sexAssignedAtBirth': '',
    'seatUserCalibrations': {
      'seatUserCalibrationId': 0,
      'heightFeet': 0,
      'heightInches': 0,
      'sternalNotch': 0
    },
  });

  const parsePatientData = (patientData: Patient) => {

    console.debug('PatientProvider.tsx parsePatientData()', patientData);

    patient.seatUserId = patientData.seatUserId ?? 0;
    patient.assignedSeatId = patientData.assignedSeatId ?? 0;
    patient.assignedSeatSerialNumber = patientData.assignedSeatSerialNumber ?? '';
    patient.addressId = patientData.addressId ?? 0;
    patient.address = patientData.address ?? '';
    patient.addressOther = patientData.addressOther ?? '';
    patient.cellPhoneId = patientData.cellPhoneId ?? 0;
    patient.cellPhone = patientData.cellPhone ?? '';
    patient.city = patientData.city ?? '';
    patient.country = patientData.country ?? '';
    patient.dateOfBirth = patientData.dateOfBirth ?? '';
    patient.email = patientData.email ?? '';
    patient.firstName = patientData.firstName ?? '';
    patient.lastName = patientData.lastName ?? '';
    patient.monitoredSince = patientData.monitoredSince ?? '';
    patient.seatUserCalibrations.heightFeet = patientData.seatUserCalibrations?.heightFeet ?? 0;
    patient.seatUserCalibrations.heightInches = patientData.seatUserCalibrations?.heightInches ?? 0;
    patient.seatUserCalibrations.sternalNotch = patientData.seatUserCalibrations?.sternalNotch ?? 0;
    patient.state = patientData.state ?? '';
    patient.zipCode = patientData.zipCode ?? '';
    patient.sexAssignedAtBirth = patientData.sexAssignedAtBirth ?? '';
  }

  const contextValue = {
    data: patient,
    parsePatientData: useCallback((patientData: Patient) => parsePatientData(patientData), [])
  };

  return (
    <PatientDataContext.Provider value={contextValue}>
      {children}
    </PatientDataContext.Provider>
  );
};
