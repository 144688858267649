import React, {useContext, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import AuthContext from "../../components/Auth/AuthContext";
import AuthenticationService from "../../services/AuthenticationService/AuthenticationService";
import CloudApiService from "../../services/CloudApiService/CloudApiService";
import PairContext from "../../components/Pair/PairContext";
import AppLocationContext from "../../components/Includes/AppLocationContext";
import useSeatSettings from "../../components/SeatSettings/SeatSettingsHook";

interface DebugSettings {
  toggleDebug: Function;
}

const Logout: React.FC<DebugSettings> = ({toggleDebug}) => {
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const pairContext = useContext(PairContext);
  const seatSettings = useSeatSettings();
  const appLocationContext = useContext(AppLocationContext);
  const auth = new AuthenticationService(authContext);
  const api = new CloudApiService(authContext);

  const logout = () => {
    toggleDebug(false);
    auth.logout().then(()=>{
      console.debug('Logout.tsx logout()');
      history.push("/login");
      appLocationContext.returnView = '/pair';
    });
  }

  useEffect(() => {
    console.debug('Logout.tsx - pairContext', pairContext);
    console.debug('Logout.tsx - seatSettings', seatSettings);
    if(pairContext.serialNumber){
      api.getSeatBySerialNumber(pairContext.serialNumber).then((response: any) => {
        console.debug('Logout.tsx - CloudApiService.getSeat()', response);
        if(response.data.seatId){
          api.revertSeatConfiguration(response.data.seatId.toString()).then(() => {
            logout();
          });
        } else {
          logout();
        }
      }).catch(() => {
        logout();
      });
    } else {
      logout();
    }
  }, []);

  //Nothing to render, we just need to execute the logout logic and redirect the user.
  return null;
};

export default Logout;
