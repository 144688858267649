import ParamDef from './ParamDef';

/* type representing a JSON parameter / return data */
class JSONDef extends ParamDef {
  async unpack(buf) {
    const decoder = new TextDecoder('utf-8');
    return JSON.parse(decoder.decode(buf).split('\x00')[0]);
  }

  async pack(unpackedArg) {
    const encoder = new TextEncoder('utf-8');
    return Buffer.from([...encoder.encode(JSON.stringify(unpackedArg)), '\0']);
  }

  print(unpackedArg) {
    return JSON.stringify(unpackedArg, null, 4);
  }
}

export default JSONDef;
