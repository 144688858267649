import './modal.css';
import useModalError from "./ModalHook";
import React from "react";
import ConfirmCancelModal from "./ConfirmCancelModal";

function ModalNotification() {
  const { error, removeError } = useModalError();

  function onWillDismiss() {
    // This is a hack because in this context useHistory() returns null.
    window.location.href = error.buttonLocation || '/';
    setTimeout(function(){removeError();}, 50); // Small timeout to eliminate race conditions
  }

  return (
    <ConfirmCancelModal
      isOpen={!!error.message}
      headerText={error.title || 'Unknown Error Occurred'}
      subheaderText={error.message || ''}
      onButtonAction1={onWillDismiss}
      actionButtonText1={error.buttonText || 'Click'}
      showWarningIcon={true}
      bigHeader
    />
  )
}

export default ModalNotification
