import * as rs from 'restructure';
import { crc32 } from 'js-crc';
import moment from 'moment';
import HsConnStatus from './HsConnStatus';
import * as HsDefs from './hsParamTypes/HsDefs';
import CmdIface from './hsParamTypes/CmdIface';
import StructDef from './hsParamTypes/StructDef';
import StringDef from './hsParamTypes/StringDef';

const BLE_SERVICE_UUID_CARL = '6e400001-b5a3-f393-e0a9-e50e24dcca9e';
const BLE_TX_CHARACTERISTIC_UUID_CARL = '6e400002-b5a3-f393-e0a9-e50e24dcca9e';
const BLE_RX_CHARACTERISTIC_UUID_CARL = '6e400003-b5a3-f393-e0a9-e50e24dcca9e';

const BLE_SERVICE_UUID_JERRY = '6a4e3300-667b-11e3-949a-0800200c9a66';
const BLE_TX_CHARACTERISTIC_UUID_JERRY = '6a4e3304-667b-11e3-949a-0800200c9a66';
const BLE_RX_CHARACTERISTIC_UUID_JERRY = '6a4e3308-667b-11e3-949a-0800200c9a66';

class HeartSeat {
  constructor() {
    this.commandTypes = CmdIface;

    /* bind private methods */
    this.handleRxEvent = this.handleRxEvent.bind(this);
    this.onExpirationCheck = this.onExpirationCheck.bind(this);
    this.onSendCheck = this.onSendCheck.bind(this);
    this.onDisconnect = this.onDisconnect.bind(this);

    /* initialize event handler arrays */
    this.onConnectionEventCbs = [];
    this.onDebugCbs = [];
    this.onProcessCbs = [];
    this.onMsgErrorCbs = [];

    this.MSG_TYPES = {};
    /* dictionaries can't have numerical keys, unlike in python */
    this.MSG_TYPES[`${HsDefs.RSP_MAGIC}`] = {
      name: 'RESPONSE',
      handleFn: this.onResponseMsg,
    };
    this.MSG_TYPES[`${HsDefs.PROC_BEGIN_MAGIC}`] = {
      name: 'BEGIN',
      handleFn: this.onProcessBegin,
    };
    this.MSG_TYPES[`${HsDefs.PROC_END_MAGIC}`] = {
      name: 'END  ',
      handleFn: this.onProcessEnd,
    };
    this.MSG_TYPES[`${HsDefs.DBG_MAGIC}`] = {
      name: 'DEBUG',
      handleFn: this.onDebugMsg,
    };
    this.MSG_TYPES[`${HsDefs.DBG_MAGIC_V2}`] = {
      name: 'DEBUG',
      handleFn: this.onDebugV2Msg,
    };

    this.init();

    /* we check for expired command promises every second */
    setInterval(this.onExpirationCheck, 1000);

    /* we check the send queue every 100ms */
    setInterval(this.onSendCheck, 100);
  }

  init() {
    this.bluetooth = null;
    this.gattServer = null;
    this.primaryService = null;
    this.txCharacteristic = null;
    this.rxCharacteristic = null;
    this.cmdPromise = {};
    this.seqid = 0;
    this.expirationTicks = 10;
    this.sendCheckRunning = false;
    this.connecting = false;
    this.disconnecting = false;
    this.currentBuf = Buffer.from([]);
    this.sendQueue = [];
  }

  /* runs every 100ms to process the send queue */
  async onSendCheck() {
    /* check if this callback is already running */
    if (this.sendCheckRunning) return;

    this.sendCheckRunning = true;

    try {
      /* loop through each buffer to send */
      while (this.sendQueue.length !== 0) {
        /* on disconnection just exit the loop */
        if (this.getConnectionState() !== HsConnStatus.CONNECTED) break;

        const currentBuf = this.sendQueue[0];

        /*
         * Some BLE implementations only support sending / receiving 20 bytes
         * of data at a time. Break the message up to accomodate this.
         */
        for (let i = 0; i < currentBuf.length; i += 20) {
          try {
            await this.txCharacteristic.writeValue(currentBuf.slice(i, i + 20));
          } catch (err) {
            /* on disconnection just exit the loop or we'll spin here */
            if (this.getConnectionState() !== HsConnStatus.CONNECTED) break;

            /* on a normal error retry */
            i -= 20;
          }
        }
        this.sendQueue.shift();
      }
    } finally {
      this.sendCheckRunning = false;
    }
  }

  /* runs every second to check for expired promises and reject() them */
  onExpirationCheck() {
    /* loop through all pending response promises */
    for (let id in this.cmdPromise) {
      if (this.cmdPromise[id].expirationTicksLeft <= 0) {
        /* promise has expired */
        this.cmdPromise[id].reject(new Error('command timed out'));
      } else {
        /* promise still has more time left */
        this.cmdPromise[id].expirationTicksLeft -= 1;
      }
    }
  }

  debugLevelName(level) {
    /* map the debug level to its human readable meaning (fixed width) */
    switch (level) {
      case 0:
        return 'DEBUG';
      case 1:
        return 'INFO ';
      case 2:
        return 'WARN ';
      case 3:
        return 'ERROR';
      case 4:
        return 'PANIC';
      default:
        return 'UNKNO';
    }
  }

  /* called whenever we get a process begin event from the seat */
  async onProcessBegin(buf) {
    /* sanity check the begin header */
    const headerSize = HsDefs.PROC_BEGIN_HEADER_DEF.size();
    if (buf.length < headerSize) throw new Error('invalid process begin message length');

    const procEventDict = await HsDefs.PROC_BEGIN_HEADER_DEF.unpack(buf);

    /* call the user's callback */
    this.onProcessCbs.forEach((cb) => cb(procEventDict));
  }

  /* called whenever we get a process end event from the seat */
  async onProcessEnd(buf) {
    /* sanity check the end header */
    const headerSize = HsDefs.PROC_END_HEADER_DEF.size();
    if (buf.length < headerSize) throw new Error('invalid process end message length');

    /* parse the debug message */
    const headerBuf = buf.slice(0, headerSize);
    const procEventDict = await HsDefs.PROC_END_HEADER_DEF.unpack(headerBuf);
    const msg = await new StringDef().unpack(buf.slice(headerSize));
    procEventDict['msg'] = msg;
    console.debug('END : ', procEventDict);

    /* call the user's callback */
    this.onProcessCbs.forEach((cb) => cb(procEventDict));
  }

  /* called whenever we get a debug message from the seat */
  async onDebugMsg(buf) {
    /* sanity check the debug header */
    const headerSize = HsDefs.DBG_HEADER_DEF.size();
    if (buf.length < headerSize) throw new Error('invalid debug message length');

    /* parse the debug message */
    const headerBuf = buf.slice(0, headerSize);
    const headerDict = await HsDefs.DBG_HEADER_DEF.unpack(headerBuf);
    const msg = await new StringDef().unpack(buf.slice(headerSize));
    const msgObj = {
      level: this.debugLevelName(headerDict['level']),
      timestamp: moment().utc().format('YYYY/MM/DD - HH:mm:ss'),
      msg,
    };

    console.debug('DBG : ', msg);

    /* call the user's callback */
    this.onDebugCbs.forEach((cb) => cb(msgObj));
  }

  /* called whenever we get a debug message from the seat */
  async onDebugV2Msg(buf) {
    /* sanity check the debug header */
    const headerSize = HsDefs.DBG_V2_HEADER_DEF.size();
    if (buf.length < headerSize) throw new Error('invalid debug message length');

    /* parse the debug message */
    const headerBuf = buf.slice(0, headerSize);
    const headerDict = await HsDefs.DBG_V2_HEADER_DEF.unpack(headerBuf);
    const msg = await new StringDef().unpack(buf.slice(headerSize));
    const msgObj = {
      level: this.debugLevelName(headerDict['level']),
      timestamp: headerDict['timestamp'],
      msg,
    };

    console.debug('DBG : ', msg);

    /* call the user's callback */
    this.onDebugCbs.forEach((cb) => cb(msgObj));
  }

  /* called whenever we get a response message from the seat */
  async onResponseMsg(buf) {
    let cmdSeqId = null;
    try {
      /* sanity check the response header / body and unpack them */
      const headerSize = HsDefs.RSP_HEADER_DEF.size();

      if (buf.length < headerSize) throw new Error('invalid response length');

      const headerBuf = buf.slice(0, headerSize);
      const headerDict = await HsDefs.RSP_HEADER_DEF.unpack(headerBuf);

      if (buf.length < headerDict['len']) throw new Error('message buffer shorter than specified length');

      cmdSeqId = headerDict['seq'];
      const bodyBuf = buf.slice(headerSize);

      const cmdId = headerDict['cmd'];
      if (cmdId === 0 || cmdId >= this.commandTypes.length + 1) throw new Error('invalid response command id');

      /* find the command descriptor for this response */
      let desc = null;
      for (const cmdName in this.commandTypes) {
        if (this.commandTypes[cmdName]['cmd_id'] === cmdId) {
          desc = this.commandTypes[cmdName];
          break;
        }
      }

      if (desc === null) throw new Error('unknown response command id');

      /* Check if we received an error. If we did, the body is an error message string */
      if (headerDict['error'] !== 0) {
        const msg = await new StringDef().unpack(bodyBuf);
        const errObj = new Error(`command failed with error code ${headerDict['error']}: ${msg}`);
        errObj.hsErrorCode = headerDict['error'];
        throw errObj;
      }

      /*
       * Find the promise that was created to wait for this response and resolve it.
       * This may not exist if the command timed out.
       */
      if (this.cmdPromise[cmdSeqId]) this.cmdPromise[cmdSeqId].resolve(bodyBuf);
      else console.debug('RESP: no promise found', cmdSeqId, Object.keys(this.cmdPromise));
    } catch (err) {
      /*
       * If we hit an error give it to the caller who is awaiting this response.
       * We resolve() it to distinguish that we did actually receive a response.
       */
      if (cmdSeqId !== null && this.cmdPromise[cmdSeqId])
        this.cmdPromise[cmdSeqId].resolve(err); /* an error is a valid response, so we "resolve" it */
    }
  }

  /*
   * Attempt to parse data we have received from the seat. This function is
   * meant to be called whenever data is received to see if it constitues a
   * complete message we can handle. We return the number of bytes we were
   * able to handle (in some way) to indicate that the caller can remove that
   * many bytes from its buffer.
   */
  async parseBuf(buf) {
    let bytesHandled = 0;

    /* We need enough bytes to check for the message magic */
    if (buf.length < 2) return bytesHandled;

    try {
      /* sanity check the message header struct */
      const magicStruct = new StructDef(new rs.Struct({ magic: rs.uint16le }));
      const magicDict = await magicStruct.unpack(buf.slice(0, 2));
      const magic = magicDict['magic'];
      if (magic !== HsDefs.MSG_HEADER_MAGIC) throw new Error(`invalid message magic ${magic}`);

      const headerSize = HsDefs.MSG_HEADER_DEF.size();
      const footerSize = HsDefs.MSG_FOOTER_DEF.size();

      /* check that we have received enough data for the message framing */
      if (buf.length < headerSize + footerSize) return 0;

      /* parse the message header */
      const headerBuf = buf.slice(0, headerSize);
      const headerDict = await HsDefs.MSG_HEADER_DEF.unpack(headerBuf);

      /* check if we have received the entire message yet */
      if (buf.length < headerDict['len']) return 0;

      /* parse the message footer and splice out the body */
      const footerBuf = buf.slice(buf.length - footerSize);
      const footerDict = await HsDefs.MSG_FOOTER_DEF.unpack(footerBuf);

      const msgContents = buf.slice(headerSize, buf.length - footerSize);

      /* check the message checksum */
      const crc = Number('0x' + crc32(buf.slice(0, buf.length - 4)));
      if (footerDict['crc'] !== crc) {
        bytesHandled = buf.length;
        throw new Error('invalid response checksum');
      }

      /* route to the appropriate handler based on the type_magic */
      const typeMagic = headerDict['type_magic'];
      const msgCallbackDesc = this.MSG_TYPES[`${typeMagic}`];
      if (!msgCallbackDesc) {
        bytesHandled = buf.length;
        throw new Error(`invalid type_magic ${typeMagic}`);
      }

      await msgCallbackDesc['handleFn'].call(this, msgContents);

      bytesHandled = buf.length;
      return bytesHandled;
    } catch (err) {
      /* call the user's callback */
      this.onMsgErrorCbs.forEach((cb) => cb(err));

      /* If there's an error throw out whatever we have so we can start fresh */
      bytesHandled = buf.length;
      return bytesHandled;
    }
  }

  /* Called by WebBluetooth whenever we receive data over BLE */
  async handleRxEvent(event) {
    /* add the new data to out buffer */
    const newBuf = Buffer.from(event.target.value.buffer);
    const buf = Buffer.from([...this.currentBuf, ...newBuf]);

    try {
      /* Attempt to parse the buffer and handle it */
      const bytesHandled = await this.parseBuf(buf);

      if (bytesHandled === buf.length) {
        /* We handled all the bytes in the buffer. Clear it and the response waiter */
        this.currentBuf = Buffer.from([]);
        if (this.responseWaiter) {
          clearTimeout(this.responseWaiter);
          this.responseWaiter = null;
        }
      } else {
        /* We handled some of the bytes in the buffer. Slice it and reset the response waiter */
        this.currentBuf = buf.slice(bytesHandled);
        if (this.responseWaiter) {
          clearTimeout(this.responseWaiter);
          this.responseWaiter = null;
        }
        this.responseWaiter = setTimeout(() => {
          /*
           * This exists so that we clear out the BLE buffer on a timeout.
           * This allows us to start fresh when the seat is able to talk again.
           */
          this.onMsgErrorCbs.forEach((cb) => cb(new Error('timed out waiting for response')));

          this.currentBuf = Buffer.from([]);
          this.responseWaiter = null;
        }, 3000);
      }
    } catch (err) {
      /* We hit an error. Clear the buffer and response waiter so we can start fresh */
      this.onMsgErrorCbs.forEach((cb) => cb(err));

      this.currentBuf = Buffer.from([]);
      if (this.responseWaiter) {
        clearTimeout(this.responseWaiter);
        this.responseWaiter = null;
      }
    }
  }

  /* get a sequence id for coordinating responses to commands */
  getSeqId() {
    this.seqid = (this.seqid + 1) & 0xffff;
    return this.seqid;
  }

  /* wrap a buffer in a BLE frame */
  async wrapBleFrame(buf) {
    const bleHeader = await HsDefs.BLE_FRAME_DEF.pack({ magic: HsDefs.BLE_FRAME_MAGIC, len: buf.length });
    return Buffer.from([...bleHeader, ...buf]);
  }

  /* Wrap a buffer in a standardized message frame */
  async wrapMessage(typeMagic, buf) {
    /* generate a header */
    const msgLen = HsDefs.MSG_HEADER_DEF.size() + buf.length + HsDefs.MSG_FOOTER_DEF.size();

    const msgHeaderDict = {
      magic: HsDefs.MSG_HEADER_MAGIC,
      len: msgLen,
      type_magic: typeMagic,
      pad: 0,
    };
    const headerBuf = await HsDefs.MSG_HEADER_DEF.pack(msgHeaderDict);

    /* generate a footer (with a checksum) */
    const footerMagicStruct = new StructDef(new rs.Struct({ magic: rs.uint16le, pad: rs.uint16le }));
    const magicBytes = await footerMagicStruct.pack({ magic: HsDefs.MSG_FOOTER_MAGIC, pad: 0 });
    const combinedBuf = Buffer.from([...headerBuf, ...buf, ...magicBytes]);
    const crc = Number('0x' + crc32(combinedBuf));

    const footerDict = {
      magic: HsDefs.MSG_FOOTER_MAGIC,
      pad: 0,
      crc: crc,
    };
    const footerBuf = await HsDefs.MSG_FOOTER_DEF.pack(footerDict);

    /* assemble the complete message */
    const msgBuf = Buffer.from([...headerBuf, ...buf, ...footerBuf]);
    return msgBuf;
  }

  /* create a timeout-capable promise for awaiting on a command response */
  createResponsePromise() {
    let res, rej;

    /* create the response promise */
    const promise = new Promise((resolve, reject) => {
      res = resolve;
      rej = reject;
    });

    /* set up our callbacks for an external caller to resolve / reject this promise */
    promise.resolve = res; /* resolved by the response */
    promise.reject = rej; /* rejected by the timeout (see onExpirationCheck()) */
    promise.expirationTicksLeft = this.expirationTicks;

    return promise;
  }

  /* Add a buffer to the send queue (processed by this.onSendCheck()) */
  sendBuf(buf) {
    this.sendQueue.push(buf);
  }

  /* called by the caller to issue a command to the seat */
  async handleCmd(cmdName, params) {
    let cmdSeqId = null;
    try {
      /* sanity check that we are connected */
      if (this.getConnectionState() !== HsConnStatus.CONNECTED) throw new Error('not connected');

      /* sanity check that the command actually exists */
      const desc = this.commandTypes[cmdName];
      if (!desc) throw new Error('invalid command name');

      /* assemble the command as a standardized message */
      const bodyBuf = await desc['req_fmt'].pack(params);

      cmdSeqId = this.getSeqId();
      const headerDict = {
        cmd: desc['cmd_id'],
        seq: cmdSeqId,
        len: HsDefs.CMD_HEADER_DEF.size() + bodyBuf.length,
        pad: 0,
      };
      const headerBuf = await HsDefs.CMD_HEADER_DEF.pack(headerDict);

      const cmdBuf = Buffer.from([...headerBuf, ...bodyBuf]);
      const msgBuf = await this.wrapMessage(HsDefs.CMD_MAGIC, cmdBuf);
      const bleBuf = await this.wrapBleFrame(msgBuf);

      console.debug('SEND: ', cmdName, cmdSeqId, JSON.stringify(params));

      /*
       * if this is a no-response command (such as "software reset")
       * just send the buffer and return.
       */
      if (desc['no_response']) {
        this.sendBuf(bleBuf);
        return null;
      }

      /* Send the command and await the response */
      this.cmdPromise[cmdSeqId] = this.createResponsePromise();
      this.sendBuf(bleBuf);
      const rawResponse = await this.cmdPromise[cmdSeqId];
      delete this.cmdPromise[cmdSeqId];

      if (rawResponse instanceof Error) {
        /*
         * We received an error response from the seat. IO errors between the
         * us and the seat are thrown above.
         */
        throw rawResponse;
      } else {
        /* We got a response. Parse the contained data (if it exists) and return it */
        console.debug('RECV: ', cmdName, cmdSeqId, JSON.stringify(params));
        return await desc['res_fmt'].unpack(rawResponse);
      }
    } finally {
      /* if we hit an error delete our response waiter */
      if (cmdSeqId !== null) delete this.cmdPromise[cmdSeqId];
    }
  }

  /* convenience function for reading an entire file from the seat */
  async getFileData(path) {
    /* fetch the metadata info for this file */
    const file_dict = await this.handleCmd('file_get_info', path);
    const fsize = file_dict['fsize'];
    const max_chunk_size = file_dict['max_chunk_size'];

    /* read the file data */
    let index = 0;
    let file_data = Buffer.from([]);
    while (index < fsize) {
      const bytes = Math.min(fsize - index, max_chunk_size);
      const buf = await this.handleCmd('file_read_raw', { path, index, bytes });

      file_data = Buffer.from([...file_data, ...buf]);
      index += buf.length;
    }

    return file_data;
  }

  /* get the current connection state */
  getConnectionState() {
    if (this.connecting) return HsConnStatus.CONNECTING;
    else if (this.disconnecting) return HsConnStatus.DISCONNECTING;
    else if (this.bluetooth && this.bluetooth.gatt && this.bluetooth.gatt.connected && this.primaryService) return HsConnStatus.CONNECTED;
    else return HsConnStatus.DISCONNECTED;
  }

  /* called when the ble disconnects for any reason */
  onDisconnect() {
    this.disconnecting = true;
    this.pushConnectionEventUpdate();

    /* empty out our send queue and reject any promises awaiting responses */
    this.sendQueue = [];
    for (let id in this.cmdPromise) {
      this.cmdPromise[id].reject(new Error('BLE disconnected'));
    }

    this.disconnecting = false;
    this.pushConnectionEventUpdate();
  }

  /* disconnect from the seat if we are currently connected */
  disconnect() {
    if (this.getConnectionState() === HsConnStatus.CONNECTED) {
      /* Disconnect from web bluetooth. Events are emitted by onDisconnect() */
      this.bluetooth.gatt.disconnect();
    }
  }

  /* helper function to emit an event for the current BLE connection status */
  pushConnectionEventUpdate() {
    const connStatus = this.getConnectionState();
    this.onConnectionEventCbs.forEach((cb) => cb(connStatus));
  }

  registerConnectionStatusHandler(cb) {
    this.onConnectionEventCbs.push(cb);
  }

  registerDebugHandler(cb) {
    this.onDebugCbs.push(cb);
  }

  registerProcessEventHandler(cb) {
    this.onProcessCbs.push(cb);
  }

  registerMsgErrorHandler(cb) {
    this.onMsgErrorCbs.push(cb);
  }

  unregisterConnectionStatusHandler(searchCb) {
    this.onConnectionEventCbs = this.onConnectionEventCbs.filter((cb) => cb !== searchCb);
  }

  unregisterDebugHandler(searchCb) {
    this.onDebugCbs = this.onDebugCbs.filter((cb) => cb !== searchCb);
  }

  unregisterProcessEventHandler(searchCb) {
    this.onProcessCbs = this.onProcessCbs.filter((cb) => cb !== searchCb);
  }

  unregisterMsgErrorHandler(searchCb) {
    this.onMsgErrorCbs = this.onMsgErrorCbs.filter((cb) => cb !== searchCb);
  }

  /* get the bluetooth context for a nearby seat from the browser */
  async getBleContextFromBrowser(macAddress) {
    console.debug(`filtering for '${macAddress || ''}'`);
    let r3Filter = { namePrefix: `CS_${macAddress || ''}` };
    let r4Filter = { namePrefix: `CasanaSeat_${macAddress || ''}` };

    /* Prompt the user to select a bluetooth device. This will open a browser-native dialog */
    try {
      return await navigator.bluetooth.requestDevice({
        filters: [r3Filter, r4Filter],
        optionalServices: [BLE_SERVICE_UUID_CARL, BLE_SERVICE_UUID_JERRY],
      });
    } catch (err) {
      /* user cancelled the picker (or ble isn't available) */
      return null;
    }
  }

  /* connect to the seat */
  async connect(bluetooth) {
    let attempts = 0;

    /* re-initialize all working variables for this connection */
    this.init();

    /* put us into the connecting state */
    this.bluetooth = bluetooth;
    this.connecting = true;
    this.pushConnectionEventUpdate();

    /* Retry loop for connecting to the seat. This often takes multiple attempts */
    while (1) {
      try {
        attempts++;
        console.debug('connection attempt ' + attempts);

        /* setup the WebBluetooth driver */
        this.bluetooth.addEventListener('gattserverdisconnected', this.onDisconnect);
        this.gattServer = await this.bluetooth.gatt.connect();
        if (this.bluetooth.name.startsWith('CS')) {
          this.primaryService = await this.gattServer.getPrimaryService(BLE_SERVICE_UUID_JERRY);
          this.txCharacteristic = await this.primaryService.getCharacteristic(BLE_TX_CHARACTERISTIC_UUID_JERRY);
          this.rxCharacteristic = await this.primaryService.getCharacteristic(BLE_RX_CHARACTERISTIC_UUID_JERRY);
        } else {
          this.primaryService = await this.gattServer.getPrimaryService(BLE_SERVICE_UUID_CARL);
          this.txCharacteristic = await this.primaryService.getCharacteristic(BLE_TX_CHARACTERISTIC_UUID_CARL);
          this.rxCharacteristic = await this.primaryService.getCharacteristic(BLE_RX_CHARACTERISTIC_UUID_CARL);
        }
        this.rxCharacteristic.oncharacteristicvaluechanged = this.handleRxEvent;
        await this.rxCharacteristic.startNotifications();
        this.connecting = false;
        this.pushConnectionEventUpdate();
        return;
      } catch (err) {
        /* attempt to connect with 5 retries before giving up */
        if (attempts > 5) {
          this.bluetooth.gatt.disconnect();
          this.bluetooth = null;
          this.connecting = false;
          this.pushConnectionEventUpdate();
          throw err;
        }
      }
    }
  }

  /**
   * Extra methods we added. @todo see if we can keep lib files synced to heart-seat-fw repo lib files.
   */
  unregisterAllDebugHandlers() {
    this.onDebugCbs = [];
  }

  unregisterAllProcessEventHandlers() {
    this.onProcessCbs = [];
  }

  unregisterAllConnectionStatusHandler() {
    this.onConnectionEventCbs = [];
  }

  unregisterAllMsgErrorHandlers() {
    this.onMsgErrorCbs = [];
  }
}

/* Singleton implementation */
const hsi = new HeartSeat();
window.hsi = hsi;
export default hsi;
