import React, {useContext, useEffect, useState, useRef} from 'react';

import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
  IonInput,
  IonText,
  IonButton, IonNote
} from '@ionic/react';

import '../Login/Login.css';
import '../../components/forms/AuthenticationForm/AuthenticationForm.css';
import { Image } from '../../types/Image';
import {lockClosedOutline} from "ionicons/icons";
import SessionHelper from "../../helpers/SessionHelper";
import AuthContext from "../../components/Auth/AuthContext";
import AuthenticationService from "../../services/AuthenticationService/AuthenticationService";
import {validationRefs} from "../../Refs";


const ResetPassword: React.FC = () => {

  // We need to use useRef() to prevent the preCheck() from running on re-mounts.
  const effectRan = useRef(false);
  useEffect(() => {
    document.title = "Reset Password"

    // Run the token pre-check on mount.
    if (!effectRan.current) {
      preCheck();
    }

    effectRan.current = true;

  }, [])

  const casanaLogo: Image = {src: './assets/casana-logo.svg', text: 'Casana'};
  const loginImg: Image = { src: './assets/toilet.svg', text: 'Login' };
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(true);
  const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState<boolean>(true);
  const [passwordsMatch, setPasswordsMatch] = useState<boolean>(true);
  const [statusText, setStatusText] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const authContext = useContext(AuthContext);
  const auth = new AuthenticationService(authContext);


  /**
   * Pre-check to ensure the password reset token is still valid.
   */
  const preCheck = () => {
    SessionHelper.clearData();
    setIsSubmitting(true);

    let rawUrl = window.location.pathname.replace('reset-password/', '|');
    let url = rawUrl.split('|');
    const hashedToken = url[url.length - 1];

    setPassword('');
    setConfirmPassword('');

    auth.resetPassword(password, confirmPassword, hashedToken).then((response: any) => {

      if (!response.success) {
        if(response.errors.includes('This link has expired')){
          console.debug('ResetPassword.tsx - preCheck() ERROR - ', response);
          setStatusText(response.errors);
        }else{
          setIsSubmitting(false);
          setStatusText('Set a new password and confirm it. Once reset, we will redirect you to the login screen.');
        }
        return;
      }

    });

  }

  const handleFormSubmit = async () => {

    if (!validateForm()) {
      setStatusText('There were errors with your submission.');
      return;
    }

    SessionHelper.clearData();
    setIsSubmitting(true);

    let rawUrl = window.location.pathname.replace('reset-password/', '|');
    let url = rawUrl.split('|');
    const hashedToken = url[url.length - 1];

    auth.resetPassword(password, confirmPassword, hashedToken).then((response: any) => {

      if (!response.success) {
        console.debug('ResetPassword.tsx - handleFormSubmit() ERROR - ', response);
        setStatusText(response.errors);
        setIsSubmitting(false);
        return;
      }

      setPassword('');
      setConfirmPassword('');
      setStatusText('Your password has been successfully reset! Returning to Login...');

      setTimeout(function() {
        setIsSubmitting(false);
        window.location.href = '/login';
      }, 3000)
    });
  }

  const handleReturnToLogin = () => {
    window.location.href = '/login';
  }

  const handlePasswordChange = async (val: string) => {
    setPasswordsMatch(true);
    setPassword(val);
    setIsPasswordValid(checkPassword(val));
  }

  const handleConfirmPasswordChange = async (val: string) => {
    setPasswordsMatch(true);
    setConfirmPassword(val);
    setIsConfirmPasswordValid(checkPassword(val));
  }

  const checkPassword = (val: string) => {
    if (val.length === 0) {
      return false;
    }

    return !!val.match(validationRefs.passwordRegex);
  }

  /**
   * Ensure form is valid.
   */
  const validateForm = () => {
    handlePasswordChange(password);
    handleConfirmPasswordChange(confirmPassword);
    setPasswordsMatch(password === confirmPassword);

    return password !== ''
      && confirmPassword !== ''
      && isPasswordValid
      && isConfirmPasswordValid
      && password === confirmPassword;
  }

  return (
    <IonPage>
      <IonContent fullscreen className="login-screen">
        <IonGrid className="ion-no-padding">
          <IonRow className="ion-justify-content-center">
            <IonCol className="ion-float-left login-image desktop">
              <IonImg src={loginImg.src} alt={loginImg.text} className="toilet-seat" />
            </IonCol>
            <IonCol className="ion-float-right login-form-column ion-no-padding">
              <IonContent className='authentication-form'>
                <IonImg src={casanaLogo.src} alt={casanaLogo.text} className='casana-logo'></IonImg>
                <IonCard className="authentication-form-text">
                  <IonCardHeader>
                    <IonCardTitle>Reset Password</IonCardTitle>
                    <IonCardSubtitle>{statusText}</IonCardSubtitle>
                  </IonCardHeader>
                </IonCard>
                <IonList className="authentication-form">
                  <IonItem className={isPasswordValid && passwordsMatch ? 'ion-valid' : 'ion-invalid'}>
                    <IonLabel>
                      <IonIcon slot="start" ios={lockClosedOutline} md={lockClosedOutline}/>
                    </IonLabel>
                    <IonInput type="password" onIonChange={(e: any) => handlePasswordChange(e.detail.value)} placeholder="Password"></IonInput>
                    <IonNote slot="error">
                      <span className={isPasswordValid ? 'ion-hide' : 'ion-show'}>{validationRefs.passwordMsg}</span>
                      <span className={passwordsMatch ? 'ion-hide' : 'ion-show'}>{validationRefs.passwordMismatchMsg}</span>
                    </IonNote>
                  </IonItem>
                  <IonItem className={isConfirmPasswordValid && passwordsMatch ? 'ion-valid' : 'ion-invalid'}>
                    <IonLabel>
                      <IonIcon slot="start" ios={lockClosedOutline} md={lockClosedOutline}/>
                    </IonLabel>
                    <IonInput type="password"
                              onIonChange={(e: any) => handleConfirmPasswordChange(e.detail.value)}
                              placeholder="Confirm password"
                    >
                    </IonInput>
                    <IonNote slot="error">
                      <span className={isConfirmPasswordValid ? 'ion-hide' : 'ion-show'}>{validationRefs.passwordMsg}</span>
                      <span className={passwordsMatch ? 'ion-hide' : 'ion-show'}>{validationRefs.passwordMismatchMsg}</span>
                    </IonNote>
                  </IonItem>
                  <IonText className="ion-text-center">
                    <p>
                      <a onClick={handleReturnToLogin} className="auth-form-link">
                        Return to login
                      </a>
                    </p>
                  </IonText>
                  <IonButton expand="block" disabled={isSubmitting} onClick={handleFormSubmit}>Reset Password</IonButton>
                </IonList>
              </IonContent>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default ResetPassword;
