import './Initialize.css';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {
  IonContent,
  IonPage,
  IonText,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonProgressBar,
  IonCardSubtitle, IonCardContent, IonGrid, IonRow, IonCol, IonButton
} from '@ionic/react';
import {useHistory} from "react-router-dom";
import {InitializationProvider} from "../../components/Initialize/InitializationProvider";
import {LocationData} from "../../types/LocationData";
import AppLocationContext from "../../components/Includes/AppLocationContext";
import {localStorageRefs, seatProcessCheckinCode} from "../../Refs";
import {SeatProcessEvent} from "../../types/SeatProcessEvent";
import hsi from "../../lib/HeartSeatInterface";
import AuthContext from "../../components/Auth/AuthContext";
import useSeatSettings from "../../components/SeatSettings/SeatSettingsHook";
import useInitializationData from "../../components/Initialize/InitializationHook";
import usePatientData from "../../components/PatientDetails/PatientHook";
import InitializationService from "../../services/InitializationService/InitializationService";


const InitializeManualConfirm: React.FC = () => {
  const history = useHistory();
  const locationContext = useContext<LocationData>(AppLocationContext);

  const [errorText, setErrorText] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [successText, setSuccessText] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);

  const authContext = useContext(AuthContext);
  const seatSettings = useSeatSettings();
  const init = useInitializationData();
  const patient = usePatientData();
  const initService = new InitializationService(authContext);

  const [sitSessionData, setSitSessionData] = useState([{sitSessionId: 0, sitStartTime: ''}]);
  const [isUploadingRecording, setIsUploadingRecording] = useState<boolean>(false);

  /**
   * Again, a JS hack to deal with accessing the current useState() values
   * from within the fixed seatManualRecordingListener() callback.
   */
  const isUploadingRecordingRef = useRef<boolean>();
  isUploadingRecordingRef.current = isUploadingRecording;

  useEffect(() => {
    document.title = "Confirm your manual recording";
  }, []);

  const setBanner = (success: boolean, message: string) => {
    if (success) {
      setSuccess(true);
      setSuccessText(message);
      setError(false);
    } else {
      setError(true);
      setErrorText(message);
      setSuccess(false);
    }
  }

  const clearBanner = () => {
    setSuccess(false);
    setError(false);
  }

  const handleFormScreenLink = () => {
    history.push('/initialize/form')
    locationContext.returnView = '/initialize/form';
  }

  const handleCancelConfirmRecording = () => {
    clearBanner();
    history.push('/initialize/manual')
    locationContext.returnView = '/initialize/manual';
  }

  /**
   * Gets the latest init recording (the one you just recorded) then go to the initialization form screen.
   */
  const getRecording = async () => {
    let seatUserId = localStorage.getItem(localStorageRefs.patientId);
    let seatId = localStorage.getItem(localStorageRefs.seatId);

    console.debug('InitializeSitTimerManualConfirmScreen.tsx getRecording() patient', patient);

    if (!seatUserId || !seatId) {
      setBanner(false, 'Please select a patient and seat from Patient Details before initializing.')
      setSitSessionData([]);
    }

    initService.getLatestInitRecording(Number(seatId), Number(seatUserId)).then(async (response: any) => {

      if (!response.success) {
        console.error('InitializeSitTimerManualConfirmScreen -> getRecording()', response);
        setBanner(false, 'An error occurred: ' + response.errors);
        return;
      }

      let sitSession = response.data;
      init.data.sitSessionId = sitSession.id;
      init.data.rawRecordingFilename = sitSession.raw_recording_filename;
      init.data.firmwareVersion = sitSession.firmware_version;
      init.data.hardwareVersion = sitSession.hardware_version;
      init.data.seatUserId = sitSession.seat_user_id;
      init.data.dateOfBirth = patient.data.dateOfBirth;
      init.data.sitStartTime = sitSession.sit_start_time;
      init.data.heightFeet = patient.data.seatUserCalibrations?.heightFeet;
      init.data.heightInches = patient.data.seatUserCalibrations?.heightInches;
      init.data.sternalNotch = patient.data.seatUserCalibrations?.sternalNotch;

    }).catch((e: any) => {
      console.error('InitializeSitTimerManualConfirmScreen -> getRecording() -> catch()', e);
      setBanner(false, 'Error getting latest initialization recording');
      return false;
    });
  }

  const hasSeatUploadedRecording = (ev: SeatProcessEvent): boolean => {
    return !!isUploadingRecordingRef.current && ev.proc_magic === seatProcessCheckinCode && typeof ev.error !== 'undefined';
  }

  const seatManualRecordingListener = (ev: SeatProcessEvent) => {

    console.debug('SEAT MANUAL RECORDING LISTENER', ev);

    if (!isUploadingRecordingRef.current) {
      return;
    }

    if (hasSeatUploadedRecording(ev)) {
      setIsUploadingRecording(false);
    }
  }

  const registerRecordingListener = () => {
    hsi.registerProcessEventHandler(seatManualRecordingListener);
  }

  const unregisterRecordingListener = () => {
    hsi.unregisterAllProcessEventHandlers();
  }

  /**
   * This method is only used for manual recordings. For auto recordings the upload
   * process occurs immediately after the patient gets off the seat.
   */
  const uploadRecording = async () => {

    /**
     * Once user has decided to upload a recording, listen for seat BLE events.
     */
    registerRecordingListener();

    /**
     * Tell the seat to check in with upload at the next available opportunity (async)
     * NOTE: newer firmwares do NOT update the timeOfLastCheckin timestamp with the
     * checkin_with_upload command, so we have to listen to the seat BLE instead.
     */
    setIsUploadingRecording(true);
    await hsi.handleCmd('checkin_with_upload', null);

    /**
     * Check if the listener has determined that the upload has completed via isUploadingRecording state value.
     * @todo determine longest time this can run before getting logged out.
     */
    for (let i = 0; i < 600; i++) {
      await new Promise((r) => setTimeout(r, 1000));

      /**
       * Get the uploaded recording and move to the next step once upload has completed.
       */
      if (!isUploadingRecordingRef.current) {
        unregisterRecordingListener();
        setIsUploadingRecording(false);
        setBanner(true, "Upload was successful!");
        await getRecording();
        return handleFormScreenLink();
      }
    }

    setIsUploadingRecording(false);
    setBanner(false, "Upload timed out. Please try recording again.");
    return;
  }

  const handleCancel = () => {
    unregisterRecordingListener();
    handleCancelConfirmRecording();
  }

  return (
    <IonPage>
      <InitializationProvider>
        <IonContent fullscreen className="container">
          <IonCard className={error ? 'ion-show flash-message' : 'ion-hide flash-message'}>
            <IonText className="danger">
              {errorText}
            </IonText>
          </IonCard>
          <IonCard className={success ? 'ion-show flash-message' : 'ion-hide flash-message'}>
            <IonText className="success">
              {successText}
            </IonText>
          </IonCard>
          <IonCard className="standard-container">
            <IonCardHeader>
              <IonCardTitle>Use this recording?</IonCardTitle>
              { isUploadingRecording ? <IonProgressBar type="indeterminate"></IonProgressBar> : null }
              <IonCardSubtitle className="m-t-20 m-b-20">You may use this recording or go back to record another sit
              </IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
              <IonGrid className="m-b-100" id="timer-wrapper">
                <IonRow>
                  <IonCol size="6" size-md="6" className="ion-text-right">
                    <IonButton disabled={isUploadingRecording} onClick={handleCancel} className="retake-recording">Record another sit</IonButton>
                  </IonCol>
                  <IonCol size="6" size-md="6" className="ion-text-left">
                    <IonButton disabled={isUploadingRecording} onClick={uploadRecording} className="upload-recording">Upload this recording</IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCardContent>
          </IonCard>
        </IonContent>
      </InitializationProvider>
    </IonPage>
  );
};

export default InitializeManualConfirm;

