import './SeatDebugModalIcon.css'
import React from 'react';
import {IonIcon, IonItem, IonLabel} from "@ionic/react";
import {constructSharp} from "ionicons/icons";
import useSeatDebugModal from "./SeatDebugModalHook";

interface ViewSettings {
  display: boolean;
}

const SeatDebugModalIcon: React.FC<ViewSettings> = ({display}) => {
  const modal = useSeatDebugModal();

  const handleClick = () => {
    modal.addShowPanel()
  }

  return (
    <IonItem
      className={display ? 'ts ion-show off' : 'ts ion-hide off'}
      lines="none"
      onClick={handleClick}
    >
      <IonLabel>
        <IonIcon slot="content" ios={constructSharp} md={constructSharp} className="off"></IonIcon>
      </IonLabel>
    </IonItem>
  );
};

export default SeatDebugModalIcon;
