import React, {useState, useCallback} from 'react';

export const SeatDebugModalContext = React.createContext({
  showPanel: false,
  addShowPanel: () => {},
  removeShowPanel: () => {},
});

export default function SeatDebugModalProvider({children}:{children:React.ReactNode}){
  const [showPanel, setShowPanel] = useState(false);
  const removeShowPanel = () => setShowPanel(false);
  const addShowPanel = () => setShowPanel(true);

  const contextValue = {
    showPanel,
    addShowPanel: useCallback(() => addShowPanel(), []),
    removeShowPanel: useCallback(() => removeShowPanel(), [])
  };

  return (
    <SeatDebugModalContext.Provider value={contextValue}>
      {children}
    </SeatDebugModalContext.Provider>
  );
}
