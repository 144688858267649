import './PatientDetails.css';
import React, {useContext, useEffect} from 'react';
import {IonContent, IonPage} from '@ionic/react';
import PatientDetailsForm from '../../components/PatientDetails/PatientDetailsForm';
import usePatientData from "../../components/PatientDetails/PatientHook";
import {useHistory} from "react-router-dom";
import {LocationData} from "../../types/LocationData";
import AppLocationContext from "../../components/Includes/AppLocationContext";

const PatientForm: React.FC = () => {
  const patient = usePatientData();
  const history = useHistory();
  const locationContext = useContext<LocationData>(AppLocationContext);

  // patient-details/existing
  const handleExistingView = () => {
    history.push('/patient-details/existing');
    locationContext.returnView = '/patient-details/existing'
  }

  useEffect(() => {
    document.title = "Patient Details Form";
  }, [patient.data])

  return (
    <IonPage>
      <IonContent fullscreen>
        <PatientDetailsForm handleSelectIDView={handleExistingView} />
      </IonContent>
    </IonPage>
  );
};

export default PatientForm;
