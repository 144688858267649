import React, {useContext, useState} from 'react';
import AuthenticationService from '../../../services/AuthenticationService/AuthenticationService';
import './AuthenticationForm.css';
import {useHistory} from 'react-router-dom';
import {Image} from '../../../types/Image';
import AuthContext from "../../Auth/AuthContext";

import {
  IonButton,
  IonContent,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonText,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonToggle,
  IonModal,
  IonCol,
  IonRow
} from '@ionic/react';

import {
  personOutline,
  lockClosedOutline
} from 'ionicons/icons';
import useSeatSettings from "../../SeatSettings/SeatSettingsHook";
import SessionHelper from "../../../helpers/SessionHelper";

const AuthenticationForm: React.FC = () => {

  const history = useHistory();
  const authContext = useContext(AuthContext);
  const seatSettings = useSeatSettings();
  const auth = new AuthenticationService(authContext);
  const casanaLogo: Image = {src: './assets/casana-logo.svg', text: 'Casana'};

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [forgotPassword, setForgotPassword] = useState<boolean>(false);
  const [statusText, setStatusText] = useState<string>('Please sign into your account');
  const [forgotPasswordText, setForgotPasswordText] = useState<string>('Enter your email address to have a password reset link sent to your email address');
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState<string>('');
  const [needsMultifactor, setNeedsMultifactor] = useState<boolean>(false);
  const [multifactorSubtitle, setMultifactorSubtitle] = useState<string>('Please enter the authorization code sent to your email address.');
  const [multifactorCode, setMultifactorCode] = useState<string>('');
  const [isMultifactorTrusted, setIsMultifactorTrusted] = useState<boolean>(false);
  const [isLoginSubmitting, setIsLoginSubmitting] = useState<boolean>(false);
  const [isForgotPasswordSubmitting, setIsForgotPasswordSubmitting] = useState<boolean>(false);
  const [isMultifactorSubmitting, setIsMultifactorSubmitting] = useState<boolean>(false);
  const [isEulaRequired, setIsEulaRequired] = useState<boolean>(false);
  const [acceptEulaRequired, setAcceptEulaRequired] = useState<boolean>(false);
  const [eulaHtml, setEulaHtml] = useState<string>('');
  const [eulaSubtitle, setEulaSubtitle] = useState<string>('');
  const [isAcceptingEula, setIsAcceptingEula] = useState<boolean>(false);

  const resetPage = () => {
    setEmail('');
    setPassword('');
    setForgotPassword(false);
    setStatusText('Please sign into your account');
    setForgotPasswordText('Enter your email address to have a password reset link sent to your email address');
    setForgotPasswordEmail('');
    setNeedsMultifactor(false);
    setMultifactorSubtitle('Please enter the authorization code sent to your email address.');
    setMultifactorCode('');
    setIsMultifactorTrusted(false);
    setIsLoginSubmitting(false);
    setIsForgotPasswordSubmitting(false);
    setIsMultifactorSubmitting(false);
    setAcceptEulaRequired(false);
    setIsEulaRequired(false);
    setEulaHtml('');
    setEulaSubtitle('');
    setIsAcceptingEula(false);
  }

  //Submit the form data once the button is clicked
  const handleLoginSubmit = async () => {
    setIsLoginSubmitting(true);
    SessionHelper.clearData();

    if (!email || !password) {
      setStatusText('Please enter your email and password');
      setIsLoginSubmitting(false);
      return;
    }

    auth.login(email, password).then((response: any) => {
      console.debug('AuthenticationForm.tsx login()', response);
      if (!response.success) {

        // Handle any non-force-password-reset issues, generally 401 errors.
        if (!response.data.errors.includes('You must change your password')) {
          setStatusText(response.data.errors);
          setIsLoginSubmitting(false);
          return;
        }

        // Handle force password change.
        setStatusText('You must change your password. Please check your email for instructions.');
        auth.resetPasswordRequest(email).then((response: any) => {
          if (!response.success) {
            setStatusText('Error sending your reset password email. Please check with an administrator.');
          }
        });

        setIsLoginSubmitting(false);
        return;
      }

      // Handle error with JWT.
      if (!response.data.jwt.length) {
        setStatusText('An unknown login error occurred. Please try again.');
        setIsLoginSubmitting(false);
        return;
      }

      // If the authentication service returns a jwt, send the user to the pair page and set session timer.
      SessionHelper.setToken(response.data.jwt);
      authContext.jwt = response.data.jwt

      // Handle EULA - will occur if MFA input is needed or not.
      if (response.data.acceptedUserAgreementAt === null) {
        setIsEulaRequired(true);
      }

      // Multifactor authentication required.
      if (response.data['2fa']) {
        setIsLoginSubmitting(false);
        setNeedsMultifactor(true);
        return;
      }

      // User needs to accept EULA.
      if (response.data.acceptedUserAgreementAt === null) {
        getEula();
        return;
      }

      // Successful login.
      setIsLoginSubmitting(false);
      resetPage();
      history.push('/pair');
      seatSettings.setButtonClass('off');
    });
  }

  const getEula = async () => {
    auth.getEula().then((response: any) => {
      if (!response.success) {
        setStatusText('Error getting EULA agreement.');
      } else {
        let htmlContent = response.data + '<div style="height: 100px"></div>';
        setEulaSubtitle('Please review and accept our User Agreement');
        setEulaHtml(htmlContent);
      }
    });
    setIsLoginSubmitting(false);
    setAcceptEulaRequired(true);
  }

  const acceptEula = async () => {

    setIsAcceptingEula(true);
    auth.acceptEula().then((response: any) => {
      if (!response.success) {
        setEulaSubtitle('There was an error accepting the EULA agreement')
        setIsAcceptingEula(false);
        return;
      }

      setEulaSubtitle('EULA agreement accepted!')
      setTimeout(function () {
        resetPage();
        history.push('/pair');
      }, 1000)
      return;
    });
  }

  const dismissEula = async () => {
    setAcceptEulaRequired(false);
    SessionHelper.clearData();
  }

  const handleForgotPasswordFormSubmit = async () => {
    setIsForgotPasswordSubmitting(true);
    SessionHelper.clearData();
    auth.resetPasswordRequest(forgotPasswordEmail).then((response: any) => {

      if (!response.success) {
        console.debug('AuthenticationForm.tsx - handleForgotPasswordFormSubmit() ERROR - ', response);
        setForgotPasswordText(response.errors);
        setIsForgotPasswordSubmitting(false);
        return;
      }

      setIsForgotPasswordSubmitting(false);
      setForgotPasswordEmail('');
      setForgotPasswordText('Check your email for a link to reset your password. If it doesn’t appear within a few minutes, check your spam folder or try again.');
    });
  }

  const handleMultifactorSubmit = async () => {
    setIsMultifactorSubmitting(true);
    auth.confirmMultifactorCode(multifactorCode, isMultifactorTrusted).then((response: any) => {

      if (!response.success) {
        setIsMultifactorSubmitting(false);
        console.debug('AuthenticationForm.tsx - handleMultifactorSubmit() ERROR - ', response);
        setMultifactorSubtitle('Could not verify your code, please try again.');
        return;
      }

      setMultifactorSubtitle('Your code has been verified!');

      if (isEulaRequired) {
        getEula();
        return;
      }

      setTimeout(function () {
        resetPage();
        history.push('/pair');
        seatSettings.setButtonClass('off');
      }, 2000);
    });
  }

  //Two-way binding for the email field
  const handleEmailChange = async (val: string) => {
    setEmail(val);
  }

  //Two-way binding for the password field
  const handlePasswordChange = async (val: string) => {
    setPassword(val);
  }

  const handleForgotPasswordEmailChange = async (val: string) => {
    setForgotPasswordEmail(val);
  }

  const handleMultifactorChange = async (val: string) => {
    setMultifactorCode(val);
  }

  const handleIsMultifactorTrusted = async () => {
    setIsMultifactorTrusted(!isMultifactorTrusted);
  }

  const handleForgotPassword = () => {
    setForgotPassword(true);
  }

  const handleReturnToLogin = () => {
    setForgotPassword(false);
    setNeedsMultifactor(false);
  }

  const cancelMultifactor = () => {
    setNeedsMultifactor(false);
    setMultifactorSubtitle('Please enter the authorization code sent to your email address.');
  }

  // Hack to submit form when enter key is pressed
  const checkLoginEnter = async (e: any) => {
    if (e.key === 'Enter') {
      await handleLoginSubmit();
    }
  }

  // Hack to submit form when enter key is pressed
  const checkForgotPasswordEnter = async (e: any) => {
    if (e.key === 'Enter') {
      await handleForgotPasswordFormSubmit();
    }
  }

  // Hack to submit form when enter key is pressed
  const checkMultifactorEnter = async (e: any) => {
    if (e.key === 'Enter') {
      await handleMultifactorSubmit();
    }
  }

  return (
    <>
      <IonContent className={forgotPassword || needsMultifactor ? 'ion-hide' : 'ion-show authentication-form'}>
        <IonImg src={casanaLogo.src} alt={casanaLogo.text} className='casana-logo'></IonImg>
        <IonCard className="authentication-form-text">
          <IonCardHeader>
            <IonCardTitle>Welcome</IonCardTitle>
            <IonCardSubtitle>{statusText}</IonCardSubtitle>
          </IonCardHeader>
        </IonCard>
        <IonList className="authentication-form">
          <IonItem>
            <IonLabel>
              <IonIcon slot="start" ios={personOutline} md={personOutline}/>
            </IonLabel>
            <IonInput value={email} type="email" onIonChange={(e: any) => handleEmailChange(e.detail.value)}></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel>
              <IonIcon slot="start" ios={lockClosedOutline} md={lockClosedOutline}/>
            </IonLabel>
            <IonInput value={password} type="password" onIonChange={(e: any) => handlePasswordChange(e.detail.value)}
                      onKeyUp={(e: any) => checkLoginEnter(e)}></IonInput>
          </IonItem>
          <IonText className="ion-text-center">
            <p>
              <a onClick={handleForgotPassword} className="auth-form-link">
                Forgot password?
              </a>
            </p>
          </IonText>
          <IonButton type="submit" expand="block" disabled={isLoginSubmitting}
                     onClick={handleLoginSubmit}>Log In</IonButton>
        </IonList>
      </IonContent>

      <IonContent className={forgotPassword ? 'ion-show authentication-form' : 'ion-hide'}>
        <IonImg src={casanaLogo.src} alt={casanaLogo.text} className='casana-logo'></IonImg>
        <IonCard className="authentication-form-text">
          <IonCardHeader>
            <IonCardTitle>Reset Password</IonCardTitle>
            <IonCardSubtitle>{forgotPasswordText}</IonCardSubtitle>
          </IonCardHeader>
        </IonCard>
        <IonList className="authentication-form">
          <IonItem>
            <IonLabel>
              <IonIcon slot="start" ios={personOutline} md={personOutline}/>
            </IonLabel>
            <IonInput value={forgotPasswordEmail} type="email"
                      onIonChange={(e: any) => handleForgotPasswordEmailChange(e.detail.value)}
                      onKeyUp={(e: any) => checkForgotPasswordEnter(e)}></IonInput>
          </IonItem>
          <IonText className="ion-text-center">
            <p>
              <a onClick={handleReturnToLogin} className="auth-form-link">
                Return to login
              </a>
            </p>
          </IonText>
          <IonButton expand="block" disabled={isForgotPasswordSubmitting} onClick={handleForgotPasswordFormSubmit}>Reset
            Password</IonButton>
        </IonList>
      </IonContent>

      <IonContent className={needsMultifactor ? 'ion-show authentication-form' : 'ion-hide'}>
        <IonImg src={casanaLogo.src} alt={casanaLogo.text} className='casana-logo'></IonImg>
        <IonCard className="authentication-form-text">
          <IonCardHeader>
            <IonCardTitle>Two factor authentication</IonCardTitle>
            <IonCardSubtitle>{multifactorSubtitle}</IonCardSubtitle>
          </IonCardHeader>
        </IonCard>
        <IonList className="authentication-form">
          <IonItem>
            <IonLabel>
              <IonIcon slot="start" ios={lockClosedOutline} md={lockClosedOutline}/>
            </IonLabel>
            <IonInput
              value={multifactorCode}
              type="text"
              disabled={isMultifactorSubmitting}
              onIonChange={(e: any) => handleMultifactorChange(e.detail.value)}
              onKeyUp={(e: any) => checkMultifactorEnter(e)}
            >
            </IonInput>
          </IonItem>
          <IonItem>
            <IonToggle
              checked={isMultifactorTrusted}
              onIonChange={(e: any) => handleIsMultifactorTrusted()}
            >
            </IonToggle>
            <IonLabel>Trust this device for 30 days</IonLabel>
          </IonItem>
          <IonButton expand="block" onClick={handleMultifactorSubmit}>Confirm code</IonButton>
          <IonText className="ion-text-center">
            <p>
              <a onClick={cancelMultifactor} className="auth-form-link">
                Cancel
              </a>
            </p>
          </IonText>
        </IonList>
        <IonModal isOpen={acceptEulaRequired} backdropDismiss={false} className="auth-form-eula-modal">
          <IonContent className="auth-form-eula-content">
            <IonRow className="auth-form-eula-header">
              <IonCol size="12" className="confirm-cancel-modal-icon-container">
                <span className="small-header auth-form-eula-subtitle">{eulaSubtitle}</span>
              </IonCol>
            </IonRow>
            <div className="auth-form-eula-modal-inner-container">
            <IonContent
              id="auth-form-scrollable-eula-div"
              className="auth-form-eula-modal-inner"
              scrollY={true}
              dangerouslySetInnerHTML={{__html: eulaHtml}}
            />
            </div>
          </IonContent>
          <IonRow className="auth-form-eula-footer">
            <IonCol size="12">
              <IonButton
                className="auth-form-eula-modal-btn"
                onClick={acceptEula}
              >Accept
              </IonButton>
              <IonButton
                className="auth-form-eula-modal-btn"
                onClick={dismissEula}
              >Dismiss
              </IonButton>
            </IonCol>
          </IonRow>
        </IonModal>
      </IonContent>
    </>
  );
};

export default AuthenticationForm;
