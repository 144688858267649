import './PairScreens.css';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {
  IonAccordion,
  IonAccordionGroup,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle, IonCol,
  IonContent, IonGrid, IonIcon, IonImg, IonInput, IonItem, IonLabel,
  IonPage,
  IonProgressBar, IonRow
} from "@ionic/react";
import {chevronForwardOutline, searchOutline} from "ionicons/icons";
import ConfirmCancelModal from "../../components/Modal/ConfirmCancelModal";
import useSeatLog from "../../components/SeatSettings/HeartSeatLogHook";
import {PairData} from "../../types/PairData";
import PairContext from "../../components/Pair/PairContext";
import useSeatSettings from "../../components/SeatSettings/SeatSettingsHook";
import {Image} from "../../types/Image";
import {Token} from "../../types/Token";
import AuthContext from "../../components/Auth/AuthContext";
import CloudApiService from "../../services/CloudApiService/CloudApiService";
import {useHistory} from "react-router-dom";
import {LocationData} from "../../types/LocationData";
import AppLocationContext from "../../components/Includes/AppLocationContext";
import useModalError from "../../components/Modal/ModalHook";
import hsi from "../../lib/HeartSeatInterface";
import {seatBleReconnectAttempts} from "../../Refs";

interface ContainerProps {
  toggleDebug: Function
}

const PairSerial: React.FC<ContainerProps> = ({toggleDebug}) => {

  const hsl = useSeatLog();
  const [serialNumber, setSerialNumber] = useState<string>('');
  const [seatError, setSeatError] = useState<boolean>(false);
  const [seatErrorText, setSeatErrorText] = useState<string>('');
  const pairContext = useContext<PairData>(PairContext);
  const seatSettings = useSeatSettings();
  const [isPairing, setIsPairing] = useState(false);
  const reconnectBleAttemptsRef = useRef<number>(0);

  const pairSeatImg: Image = {
    src: './assets/pair-seat-instructions-serial-number.png',
    text: 'Instructions for connecting to the Heart Seat'
  };

  const auth = useContext<Token>(AuthContext);
  const ApiService = new CloudApiService(auth);
  const history = useHistory();
  const locationContext = useContext<LocationData>(AppLocationContext);
  const bluetoothErrorhandler = useModalError();

  useEffect(() => {
    document.title = "Pair to a seat via Bluetooth";
  }, []);

  /**
   * Set the serial number stateful variable. This way when
   * it changes the state is recompiled.
   *
   * @param val
   */
  const handleSerialNumberChange = async (val: string) => {
    setSerialNumber(val);
  }

  /**
   * Trigger the native bluetooth functionality and attempt to connect to the seat. If we are successful, attempt
   * to connect with pin from the database. If the pin is wrong or nonexistent, the
   * connectWithPin() error handler will route the user to the pin page.
   *
   * @param serialNumber
   */
  const handleBleConnect = async (serialNumber: string) => {
    console.debug('hsi.getBleContextFromBrowser requested');
    let bluetoothCtx = await hsi.getBleContextFromBrowser(serialNumber.toUpperCase());
    console.debug('hsi.getBleContextFromBrowser received');
    if (!bluetoothCtx) {
      return;
    }

    setIsPairing(true);
    pairContext.bleContext = bluetoothCtx;

    try {
      console.debug('hsi.connect requested');
      await hsi.connect(bluetoothCtx);
      console.debug('hsi.connect received');
      console.debug('get_fw_version requested');
      await hsi.handleCmd('get_fw_version', null).then((response: number) => {
        console.debug('get_fw_version received');
        pairContext.firmwareVersion = response;
        setIsPairing(false);
      });
      pairContext.serialNumber = bluetoothCtx.name.substring(bluetoothCtx.name.indexOf('_') + 1);
      await connectWithPin();
    } catch (error) {
      setIsPairing(false);
      console.error(error);
    }
  }

  /**
   * Attempt to reconnect to a seat if the BLE connection has disconnected.
   */
  const reconnectBleAfterDisconnect = async () => {
    try {
      console.debug(`ReconnectBleAfterDisconnect - attempt ${reconnectBleAttemptsRef.current}`);
      console.debug('BLE Context:', pairContext.bleContext);
      console.debug(`BLE auth token: ${pairContext.bleAuthToken}`);
      await hsi.connect(pairContext.bleContext);
      await hsi.handleCmd('ble_auth', pairContext.bleAuthToken);
      console.debug('ReconnectBleAfterDisconnect successful');
      return true;

    } catch (error) {
      console.debug('ReconnectBleAfterDisconnect failed');
      return false;
    }
  }

  /**
   * Fetch the seat pin from the cloud and connect to the seat, if we fail to
   * retrieve a pin, allow the user to enter a pin.
   */
  const connectWithPin = async () => {
    try {
      ApiService.getSeatBySerialNumber(pairContext.serialNumber).then(async (response: any) => {
        console.debug('PairSerialScreen -> getSeatBySerialNumber()', response);

        // If there was an error retrieving the seat for some reason. A 401 will return another modal via Axios hook.
        if (!response.success && response.error.code !== 401) {
          setSeatError(true);
          setSeatErrorText('Error retrieving seat. Please check that the seat has been registered and configured.');
          return;
        }

        let pin: string | null = response.data.bleAuthToken;

        try {
          await hsi.handleCmd('ble_auth', pin);

          await hsi.handleCmd('get_ble_auth_token', null).then((response: any) => {
            if (response) {
              seatSettings.parseSettings(response);
              pairContext.bleAuthToken = response;
              hsi.unregisterAllConnectionStatusHandler();
              hsi.unregisterAllDebugHandlers();
              hsi.unregisterAllMsgErrorHandlers();

              hsi.registerConnectionStatusHandler(async (connStatus: string) => {
                console.debug('BLE connection status handler callback:', connStatus);
                if (connStatus === 'connecting' || connStatus === 'connected') {
                  return;
                }

                /**
                 * If the seat disconnected from BLE, attempt to reconnect 5 times.
                 */
                while (reconnectBleAttemptsRef.current++ < seatBleReconnectAttempts) {
                  let success = await reconnectBleAfterDisconnect();
                  if (success) {
                    reconnectBleAttemptsRef.current = 0;
                    console.debug('Reset reconnect BLE counter to 0');
                    return;
                  }
                }

                toggleDebug(false);
                bluetoothErrorhandler.addError(
                  'Seat Disconnected', // title
                  'You are no longer connected to a Heart Seat. Please pair to a seat in order to continue.', // content
                  'Pair Seat', // button text
                  '/pair' // button location
                );
              });

              hsi.registerDebugHandler(hsl.onHsLogMsg);
              hsi.registerMsgErrorHandler(hsl.onHsLogMsg);
              toggleDebug(true);

              hsi.unregisterAllDebugHandlers();
              hsi.unregisterAllMsgErrorHandlers();
              hsi.registerDebugHandler(hsl.onHsLogMsg);
              hsi.registerMsgErrorHandler(hsl.onHsLogMsg);

              history.push('/wifi');
              locationContext.returnView = '/wifi';
            }
          });
        } catch (error) {
          toggleDebug(false);
          console.error(error);
          history.push("/pair/pin");
          locationContext.returnView = '/pair/pin';
        }

      });

    } catch (error) {
      history.push("/pair/pin");
      locationContext.returnView = '/pair/pin';
    }

  }

  // Hack to submit form if enter key is pressed.
  const checkEnter = async (e: any) => {
    if (e.key === 'Enter') {
      await handleBleConnect(serialNumber);
    }
  }

  return (
    <IonPage>
      <IonContent fullscreen className="container">
        <IonCard className="standard-container">
          <IonCardHeader>
            <IonCardTitle className="m-b-20">Pair with your selected seat</IonCardTitle>
            {isPairing ? <IonProgressBar type="indeterminate"></IonProgressBar> : null}
            <IonCardSubtitle className="m-t-20">After selecting the Pair Seat button, a list of available seats will
              appear in a pop-up menu. You can filter the list of available seats by typing the first digits of your
              seat's serial number into the Filter field.</IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent className="standard-container-content pair-select-serial-number">
            <IonGrid>
              <IonRow>
                <IonCol size="12" size-md="4">
                  <IonItem className="serial-number-input">
                    <IonIcon slot="start" ios={searchOutline} md={searchOutline}></IonIcon>
                    <IonInput className="serial-number-input"
                              autocapitalize="characters"
                              maxlength={16}
                              placeholder="Filter"
                              onIonChange={(e: any) => handleSerialNumberChange(e.detail.value)}
                              onKeyUp={(e: any) => checkEnter(e)}
                    ></IonInput>
                  </IonItem>
                </IonCol>
                <IonCol size="12" size-md="4">
                  <IonButton className="serial-number-submit" disabled={isPairing} onClick={() => handleBleConnect(serialNumber)}>
                    Pair Seat
                  </IonButton>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonAccordionGroup className="pair-accordion no-ripple">
                    <IonAccordion value="first" className="no-ripple" toggleIconSlot="start"
                                  toggleIcon={chevronForwardOutline}>
                      <IonItem slot="header" className="color-app">
                        <IonLabel className="pair-accordion-text">Show me where to find the seat serial number</IonLabel>
                      </IonItem>
                      <IonItem slot="content" lines="none">
                        <IonImg src={pairSeatImg.src} alt={pairSeatImg.text} className="ion-align-self-center seat-image"/>
                      </IonItem>
                    </IonAccordion>
                  </IonAccordionGroup>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardContent>
          <ConfirmCancelModal
            isOpen={seatError}
            headerText="Error retrieving seat"
            subheaderText={seatErrorText}
            onButtonAction1={() => {
              setSeatError(false)
            }}
            actionButtonText1="Ok"
            bigHeader
            showWarningIcon={true}
          />
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default PairSerial;
