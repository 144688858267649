import './PatientDetails.css';
import React, {useContext, useEffect} from 'react';
import {IonContent, IonPage} from '@ionic/react';
import ExistingPairScreen from '../../components/PatientDetails/ExistingPairScreen';
import usePatientData from "../../components/PatientDetails/PatientHook";
import {useHistory} from "react-router-dom";
import {LocationData} from "../../types/LocationData";
import AppLocationContext from "../../components/Includes/AppLocationContext";

const ExistingPatient: React.FC = () => {
  const patient = usePatientData();
  const history = useHistory();
  const locationContext = useContext<LocationData>(AppLocationContext);

  const handleCurrentPatient = () => {
    localStorage.setItem('casana-patient-id', String(patient.data.seatUserId));
    history.push('/patient-details/form');
    locationContext.returnView = '/patient-details/form'
  }

  const handleOtherPatient = () => {
    history.push('/patient-details/new');
    locationContext.returnView = '/patient-details/new'
  }

  useEffect(() => {
    document.title = "Existing patient";
  }, [])

  return (
    <IonPage>
      <IonContent fullscreen>
        <ExistingPairScreen handleCurrentPatient={handleCurrentPatient} handleOtherPatient={handleOtherPatient} />
      </IonContent>
    </IonPage>
  );
};

export default ExistingPatient;
