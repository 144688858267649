import React, { useState, useCallback } from 'react';

export const SessionExpirationModalContext = React.createContext({
  warning: false,
  addWarning: () => {},
  removeWarning: () => {}
});

export default function SessionExpirationModalProvider({children}:{children:React.ReactNode}){
  const [warning, setWarning] = useState(false);
  const removeWarning = () => setWarning(false);
  const addWarning = () => setWarning(true);

  const contextValue = {
    warning,
    addWarning: useCallback(() => addWarning(), []),
    removeWarning: useCallback(() => removeWarning(), [])
  };

  return (
    <SessionExpirationModalContext.Provider value={contextValue}>
      {children}
    </SessionExpirationModalContext.Provider>
  );
}
