import React, {useContext, useEffect, useState} from 'react';

import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonImg
} from '@ionic/react';

import {Image} from '../../types/Image';
import '../Login/Login.css';
import {useHistory} from "react-router-dom";
import AuthContext from "../../components/Auth/AuthContext";
import AccountSettingsService from "../../services/AccountSettings/AccountSettingsService";
import ConfirmCancelModal from "../../components/Modal/ConfirmCancelModal";

const VerifyEmail: React.FC = () => {

  useEffect(() => {

    document.title = "Verify Email Change";

    let rawUrl = window.location.pathname.replace('verify-email/', '|');
    let url = rawUrl.split('|');
    const token = url[url.length - 1];

    accountSettingsService.verifyEmailChangeWithToken(token).then(async (response: any) => {
      console.debug('VerifyEmail.tsx -> AccountSettingsService -> verifyEmailChangeWithToken()', response);
      if (response.success) {
        setHeaderText('Your new email has been verified!')
        setSubheaderText('You may now log in and continue.')
        setButtonClass('confirm-cancel-button');
      } else {
        setHeaderText('Email verification failed!')
        setSubheaderText('Please return to your account settings page and try again.')
        setButtonClass('confirm-cancel-button');
      }
    });
  }, [])

  const auth = useContext(AuthContext);
  const accountSettingsService = new AccountSettingsService(auth);
  const history = useHistory();
  const [showModal, setShowModal] = useState<boolean>(true);
  const [buttonClass, setButtonClass] = useState<string>('ion-hide');
  const [headerText, setHeaderText] = useState<string>('Verifying your new email...')
  const [subheaderText, setSubheaderText] = useState<string>('Once your email is verified you can return to your account settings page.')
  const loginImg: Image = {src: './assets/toilet.svg', text: 'Login'};

  const handleNext = () => {
    setShowModal(false);

    setTimeout(function () {
      history.push('/logout');
    }, 50)
  }

  return (
    <IonPage>
      <IonContent fullscreen className="main">
        <ConfirmCancelModal
          isOpen={showModal}
          headerText={headerText}
          subheaderText={subheaderText}
          onButtonAction1={handleNext}
          actionButtonText1="Return"
          actionButtonStyle1={buttonClass}
        />
        <IonGrid className="ion-no-padding">
          <IonRow className="ion-justify-content-center">
            <IonCol className="ion-float-left login-image desktop">
              <IonImg src={loginImg.src} alt={loginImg.text} className="toilet-seat"/>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default VerifyEmail;
