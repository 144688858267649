/* parent class representing any type of command parameter / response */
class ParamDef {
  async unpack(buf) {
    throw new Error('not implemented');
  }

  async pack(unpackedArg) {
    throw new Error('not implemented');
  }

  print(unpackedArg) {
    throw new Error('not implemented');
  }
}

export default ParamDef;
