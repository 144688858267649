import {
  IonButton, IonContent, IonIcon, IonImg, IonItem, IonText,
  IonLabel, IonList, IonMenu, IonMenuToggle, IonThumbnail, IonFooter
} from '@ionic/react';

import {
  bluetoothOutline, bluetoothSharp, personOutline, personSharp, pulseOutline,
  pulseSharp, wifiOutline, wifiSharp, logOutOutline
} from 'ionicons/icons';

import axios from 'axios';
import {useLocation, useHistory} from 'react-router-dom';
import React, {useContext, useEffect, useState} from 'react';
import {Image} from '../../types/Image';
import './Menu.css';
import AuthContext from "../Auth/AuthContext";
import AppLocationContext from "../Includes/AppLocationContext";
import useSeatSettings from "../SeatSettings/SeatSettingsHook";
import HelpPopup from "../Help/HelpPopup";
import AccountSettings from "../AccountSettings/AccountSettings";
import {localStorageRefs, sessionRefs} from "../../Refs";
import useSessionExpirationModalWarning from "../Modal/SessionExpirationModalHook";
import SessionHelper from "../../helpers/SessionHelper";
import useModalError from "../Modal/ModalHook";

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const appPages: AppPage[] = [
  {
    title: 'Pair Heart Seat',
    url: '/pair',
    iosIcon: bluetoothOutline,
    mdIcon: bluetoothSharp
  },
  {
    title: 'WiFi',
    url: '/wifi',
    iosIcon: wifiOutline,
    mdIcon: wifiSharp
  },
  {
    title: 'Patient Details',
    url: '/patient-details',
    iosIcon: personOutline,
    mdIcon: personSharp
  },
  {
    title: 'Initialize',
    url: '/initialize',
    iosIcon: pulseOutline,
    mdIcon: pulseSharp
  }
];

const Menu: React.FC = () => {

  const history = useHistory();
  const authContext = useContext(AuthContext);
  const appLocationContext = useContext(AppLocationContext);
  const location = useLocation();
  const seatSettings = useSeatSettings();
  const [menuClass, setMenuClass] = useState<string>('ion-hide');
  const casanaLogo: Image = {src: './assets/casana-logo.svg', text: 'Casana'};
  const casanaWordmark: Image = {src: './assets/casana-wordmark-white.svg', text: 'Casana'};
  const sessionTimeoutWarningHandler = useSessionExpirationModalWarning();
  const unauthorizedRequestErrorHandler = useModalError();

  useEffect(() => {
    setMenuClass(SessionHelper.hasToken() ? '' : 'ion-hide');

    /**
     * Query local storage for the authentication state.
     */
    if (!SessionHelper.hasToken()) {
      // Ignore on the unprotected reset password page and the complete registration page.
      if (location.pathname.includes('reset-password') || location.pathname.includes('user-complete-registration')) {
        return;
      }

      if (location.pathname !== '/login') {
        history.push('/login');
        appLocationContext.returnView = '/pair';
      }
    } else {
      if (location.pathname === '/login') {
        history.push('/pair');
        appLocationContext.returnView = '/pair';
      }
    }

    /**
     * Handle session timing/tracking if user is logged in to the app.
     */
    if (canHandleSession()) {
      handleSession();
    } else {
      SessionHelper.clearData();
    }

    /**
     * Axios event listener to alert user if any requests on authorized routes return a 401
     */
    axios.interceptors.response.use(
        (res: any) => res,
        (err: { response: { status: any; }; }) => {
        const statusCode = err.response.status;
        if (canDisplayUnauthorizedModal(statusCode, err)) {
          SessionHelper.clearData();
          unauthorizedRequestErrorHandler.addError(
            'Session Expired',
            'Your session is no longer authorized. Please log in to your account.',
            'Login',
            '/login'
          );
        }

          throw err;
      }
    );

  }, [location, history, authContext]);

  /**
   * Determines if the user should see an 'unauthorized' modal.
   *
   * @param statusCode
   * @param error
   */
  const canDisplayUnauthorizedModal = (statusCode: number, error: any) => {
    return statusCode === 401 && onAuthorizedRoute() && !error.request.responseURL.includes('logout');
  }

  /**
   * Perform session handling if user is not on any of these routes and has a JWT token.
   */
  const canHandleSession = () => {
    return SessionHelper.hasToken() && onAuthorizedRoute();
  }

  /**
   * Check routes that require authorization
   */
  const onAuthorizedRoute = () => {
    return location.pathname !== '/login'
    && location.pathname !== '/logout'
    && location.pathname !== '/'
    && !location.pathname.includes('reset-password')
    && !location.pathname.includes('user-complete-registration');
  }

  const handleSession = () => {
    if (!isTrackingSession()) {
      trackSession();
    }
  }

  const isTrackingSession = () => {
    return localStorage.getItem(localStorageRefs.sessionExpiry) !== null
      && localStorage.getItem(localStorageRefs.sessionToken) !== null
      && window.sessionTimerMethod !== null
      && window.sessionTimer !== null;
  }

  /**
   * This should occur when: (1) a user has just logged in or (2) if the page was refreshed
   * and thus the sessionTimer tracking function is null and its interval is not set.
   */
  const trackSession = () => {

    let sessionExpiryMs = localStorage.getItem(localStorageRefs.sessionExpiry) !== null
      ? Number(localStorage.getItem(localStorageRefs.sessionExpiry))
      : sessionRefs.expiryMs;

    window.clearInterval(window.sessionTimer);
    window.sessionTimerMethod = sessionTimer;
    window.sessionTimer = window.sessionTimerMethod(sessionExpiryMs);
    localStorage.setItem(localStorageRefs.sessionExpiry, sessionExpiryMs.toString());
  }

  /**
   * Set up the session timer function on the global window so that it is available to the entire application and
   * can be triggered or cleared from anywhere. This is required due to React / Ionic's scoping of components.
   */
  const sessionTimer = (sessionExpiryMs: number) => {

    return window.setInterval(() => {
      sessionExpiryMs -= sessionRefs.checkExpiryIntervalMs;
      localStorage.setItem(localStorageRefs.sessionExpiry, sessionExpiryMs.toString());
      console.debug('Menu.tsx - sessionTimer() - session will expire in: ' + (sessionExpiryMs/(1000*60)).toFixed(0) + ' minutes.');

      if (sessionExpiryMs <= 0) {
        sessionTimeoutWarningHandler.removeWarning(); // @todo test
        history.push('/logout');
        return;
      }

      if (sessionExpiryMs <= Number(sessionRefs.expiryCutoffMs)) {
        console.debug('Menu.tsx - sessionTimer() - Session will expire soon.');
        sessionTimeoutWarningHandler.addWarning();
      }

    }, Number(sessionRefs.checkExpiryIntervalMs))
  };

  const handleClick = (ev: React.MouseEvent<HTMLElement>) => {
    // If a seat is not yet paired, limit navigation
    if ((ev.currentTarget.getAttribute('href') !== '/pair' && ev.currentTarget.getAttribute('href') !== '/account-settings')
      && !seatSettings.settings.serialNumber) {
      ev.preventDefault();
      return;
    }

    if (ev.currentTarget) {
      if (ev.currentTarget.getAttribute('href') !== '/account-settings') {
        appLocationContext.returnView = ev.currentTarget.getAttribute('href') ?? '/pair';
      }

      seatSettings.setButtonClass('off');
    }
  }

  const getMenuItemClass = (appPage: AppPage) => {
    if (appPage.url === '/seat-settings' || (appPage.url !== '/pair' && location.pathname === '/pair')) {
      return 'progress-btn disabled';
    } else if (location.pathname.includes(appPage.url)) {
      return 'progress-btn active';
    } else {
      return 'progress-btn';
    }
  }

  return (
    <IonMenu contentId="main" type="overlay" className={menuClass}>
      <IonContent>
        <IonThumbnail>
          <IonImg src={casanaLogo.src} alt={casanaLogo.text} className="casana-logo"/>
          <div className="casana-wordmark">
            <IonImg src={casanaWordmark.src} alt={casanaWordmark.text} className="casana-wordmark-img"/>
          </div>
        </IonThumbnail>
        <IonList className={'progress'}>
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle
                className={getMenuItemClass(appPage)}
                key={index} autoHide={false}>
                <IonItem routerLink={appPage.url}
                         routerDirection="none"
                         lines="none"
                         detail={false}
                         onClick={(e: any) => handleClick(e)}
                >
                  <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon}/>
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>
      </IonContent>
      <IonFooter>
        <AccountSettings handleClick={handleClick}></AccountSettings>
        <HelpPopup></HelpPopup>
        <IonButton routerLink='/logout' expand="full">
          <IonIcon slot="start" ios={logOutOutline} md={logOutOutline}/>
          <IonText className="ion-text-capitalize">Log Out</IonText>
        </IonButton>
      </IonFooter>
    </IonMenu>
  );
};

export default Menu;
