import axios from 'axios';
import Service from '../Service/Service';

export default class InitializationService extends Service {

  private getInitRecordingsURL: string;
  private getLatestInitRecordingURL: string;
  private getLatestActiveEnabledAlgorithmVersionURL: string;
  private submitInitURL: string;
  private getInitStatusURL: string;

  constructor(authContext: any) {
    super(authContext);
    this.getInitRecordingsURL = `${process.env.REACT_APP_API_URL}/v2/setup_wizard/initialization/recordings`;
    this.getLatestInitRecordingURL = `${process.env.REACT_APP_API_URL}/v2/setup_wizard/initialization/latest_recording`;
    this.getLatestActiveEnabledAlgorithmVersionURL = `${process.env.REACT_APP_API_URL}/v2/algorithm_versions?active=true&disabled=false`;
    this.submitInitURL = `${process.env.REACT_APP_API_URL}/v2/seat_users/{seat_user_id}/sit_sessions/{sit_session_id}/initialize/{algo_id}`;
    this.getInitStatusURL = `${process.env.REACT_APP_API_URL}/v2/seat_users/initialization`;
  }

  /**
   * Get recent eligible recordings by seat user and seat
   *
   * @param seatId
   * @param seatUserId
   */
  public getInitRecordings(seatId: number, seatUserId: number): any {

    let config = this.getRequestConfig();

    return axios.get(`${this.getInitRecordingsURL}/${seatId}/${seatUserId}`, config).then((response) => {
      console.debug('InitializationService.tsx - getInitRecordings()', response);
      return {success: true, data: response.data}
    }).catch(function (error) {
      console.error('InitializationService.tsx - getInitRecordings()', error);
      return {success: false}
    });
  }

  /**
   * Get recent eligible recordings by seat user and seat
   *
   * @param seatId
   * @param seatUserId
   */
  public getLatestInitRecording(seatId: number, seatUserId: number): any {

    let config = this.getRequestConfig();

    return axios.get(`${this.getLatestInitRecordingURL}/${seatId}/${seatUserId}`, config).then((response) => {
      console.debug('InitializationService.tsx - getLatestInitRecording()', response);
      return {success: true, data: response.data}
    }).catch(function (error) {
      console.debug('InitializationService.tsx - getLatestInitRecording()', error);
      return {success: false, errors: error.response.data.detail}
    });
  }

  /**
   * Submit initialization.
   *
   * @param postData
   * @param patientId
   * @param sitSessionId
   */
  public async submitInit(postData: object, patientId: string, sitSessionId: string): Promise<any> {
    let config = this.getRequestConfig();

    /**
     * Get the latest active, un-disabled algorithm version ID from Cloud.
     */
    let algoResponse = await axios.get(this.getLatestActiveEnabledAlgorithmVersionURL, config);
    console.debug('InitializationService.submitInit() get latest algorithm version', algoResponse);

    if (algoResponse.status !== 200 || algoResponse.data?.length === 0) {
      return {success: false, error: {message: 'Unable to retrieve algorithm to perform initialization.'}}
    }

    let algorithmId = algoResponse.data[0].id;

    let url = this.submitInitURL
      .replace('{seat_user_id}', patientId)
      .replace('{sit_session_id}', sitSessionId)
      .replace('{algo_id}', algorithmId.toString());

    return axios.post(url, postData, config).then((response) => {
      return {success: true, data: response.data}
    }).catch(function (error) {
      return {success: false, error: error.response.data}
    });
  }

  public getInitStatus(initToken: string) {

    let config = this.getRequestConfig();

    return axios.post(`${this.getInitStatusURL}/${initToken}`, null, config).then((response) => {
      console.debug('InitializationService.ts -> getInitStatus()', response);

      switch (response.data.status) {
        case 'success':
          return {status: 'success', errors: [], warnings: []};
        case 'failed':
          return {status: 'failed', errors: response.data?.data?.errors , warnings: response.data?.data?.warnings}
        default:
          return {status: 'pending', errors: [], warnings: []}
      }

    }).catch(function (error) {
      if (error.response.status === 409) {
        return {status: 'pending', errors: [], warnings: []}
      }

      console.error('InitializationService.ts -> getInitStatus() ERROR', error);
      return {status: 'failed', apiError: error.message, errors: [], warnings: []}
    });
  }

}
