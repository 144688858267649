import ParamDef from './ParamDef';

/* type representing no parameters / no return data */
class NoneDef extends ParamDef {
  async unpack(buf) {
    return null;
  }

  async pack(unpackedArg) {
    if (unpackedArg) throw new Error('unexpected argument');

    return Buffer.from([]);
  }

  print(unpackedArg) {
    return '';
  }
}

export default NoneDef;
