import React, {useCallback, useState} from "react";
import moment from "moment/moment";
import {LogEntry} from "../../types/LogEntry";

interface HeartSeatLog {
  logData: string[],
  setLogData: Function,
  onHsLogMsg: Function,
  onUserAction: Function,
  getLogLineClassName: Function,
  getLogLineString: Function
}

export const HeartSeatLogContext = React.createContext<HeartSeatLog>({
  logData: [],
  setLogData: (logData: Array<string>) => {},
  onHsLogMsg: (hsLogMsg: LogEntry) => {},
  onUserAction:  (userAction: string) => {},
  getLogLineClassName: (hsLogMsg: LogEntry) => {},
  getLogLineString: (hsLogMsg: LogEntry) => {},
});

export default function HeartSeatLogProvider({children}:{children:React.ReactNode}){
  let [logData, setLogData] = useState<string[]>([]);

  /**
   * When a log message comes through from the seat, parse it to the logData and scroll the log view accordingly.
   *
   * @param hsLogMsg - The log message directly from the seat.
   */
  const onHsLogMsg = (hsLogMsg: any) => {
    if(hsLogMsg.level){
      setLogData((logData: Array<string>) => [...logData, hsLogMsg]);
    }
  }

  /**
   * Convenience method to determine CSS classes for log levels.
   *
   * @param entry
   */
  function getLogLineClassName(entry: LogEntry) {
    let className = 'log';

    if (entry.level === 'DEBUG') className += ' log-debug'
    else if (entry.level === 'INFO' ) className += ' log-info'
    else if (entry.level === 'WARN' ) className += ' log-warn'
    else if (entry.level === 'ERROR') className += ' log-error'
    else if (entry.level === 'PANIC') className += ' log-panic'
    else if (entry.level === 'USER' ) className += ' log-success'

    return className;
  }

  /**
   * Convenience method for parsing a log entry into a string. Print with timestamps if this message has one.
   **/
  const getLogLineString = (entry: LogEntry) => {
    return entry.timestamp ? `[${entry.level}] ${entry.timestamp} | ${entry.msg}` : `[${entry.level}] ${entry.msg}`;
  }

  /**
   * When the user performs and action manually we need to log it similarly to the seat log messages.
   *
   * @param userAction
   */
  const onUserAction = (userAction: string) => {
    const logMsg = {
      level: 'USER',
      timestamp: moment().utc().format('YYYY/MM/DD - HH:mm:s.SSS UTC'),
      msg: `user requested '${userAction}'`,
    };

    setLogData((oldLog: any) => [...oldLog, logMsg]);
  }

  const contextValue = {
    logData: logData,
    setLogData: useCallback((hsLogData: string[]) => setLogData(hsLogData),[]),
    onHsLogMsg: useCallback((hsLogMsg: any) => onHsLogMsg(hsLogMsg),[]),
    onUserAction:  useCallback((userAction: string) => onUserAction(userAction),[]),
    getLogLineClassName: useCallback((hsLogMsg: LogEntry) => getLogLineClassName(hsLogMsg),[]),
    getLogLineString: useCallback((hsLogMsg: LogEntry) => getLogLineString(hsLogMsg),[])
  };

  return (
    <HeartSeatLogContext.Provider value={contextValue}>
      {children}
    </HeartSeatLogContext.Provider>
  );
}
