import './PatientDetailsScreens.css';
import React, {useState, useEffect, useContext} from 'react';
import {
  IonCardContent, IonCardHeader, IonCardTitle, IonGrid, IonRow,
  IonCol, IonInput, IonLabel, IonFooter, IonButton, IonItem,
  IonContent, IonNote, IonIcon, IonCard, IonText, IonProgressBar, IonSelect, IonSelectOption
} from '@ionic/react';
import {useHistory} from "react-router-dom";
import AuthContext from "../../components/Auth/AuthContext";
import PatientDetailsService from "../../services/PatientDetailsService/PatientDetailsService";
import {star} from "ionicons/icons";
import {LocationData} from "../../types/LocationData";
import AppLocationContext from "../Includes/AppLocationContext";
import usePatientData from "./PatientHook";
import moment from "moment";
import useSeatSettings from "../SeatSettings/SeatSettingsHook";
import FormHelper from "../../helpers/FormHelper";
import {initRefs, validationRefs} from "../../Refs";
import UnitConversionHelper from "../../helpers/UnitConversionHelper";

interface ContainerProps {
  handleSelectIDView: Function;
}

const PatientDetailsForm: React.FC<ContainerProps> = ({handleSelectIDView}) => {
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const patient = usePatientData();
  const seatSettings = useSeatSettings();
  const locationContext = useContext<LocationData>(AppLocationContext);
  const patientDetailsService = new PatientDetailsService(authContext, patient.data);
  const [errorText, setErrorText] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [successText, setSuccessText] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const [isSavingPatientDetails, setIsSavingPatientDetails] = useState(false);

  // Define the valid constants that will be used in the form error checking
  const [isFirstNameValid, setIsFirstNameValid] = useState<boolean>(true);
  const [isLastNameValid, setIsLastNameValid] = useState<boolean>(true);
  const [isDOBValid, setIsDOBValid] = useState<boolean>(true);
  const [isMonitoredSinceValid, setIsMonitoredSinceValid] = useState<boolean>(true);
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
  const [isCellPhoneValid, setIsCellPhoneValid] = useState<boolean>(true);
  const [isStateValid, setIsStateValid] = useState<boolean>(true);
  const [isZipCodeValid, setIsZipCodeValid] = useState<boolean>(true);
  const [isSexAssignedAtBirthValid, setIsSexAssignedAtBirthValid] = useState<boolean>(true);
  const [isHeightFeetValid, setIsHeightFeetValid] = useState<boolean>(true);
  const [isHeightInchesValid, setIsHeightInchesValid] = useState<boolean>(true);
  const [isSternalNotchValid, setIsSternalNotchValid] = useState<boolean>(true);
  const [isTotalHeightValid, setIsTotalHeightValid] = useState<boolean>(true);
  const [isFullAddressValid, setIsFullAddressValid] = useState<boolean>(true);
  const [cellPhone, setCellPhone] = useState<string>('');

  //Define some variables for use as validation values and limitations in form fields
  const today = new Date();
  const minYearsDob = 110;
  const maxYearsDob = 22;

  let minDOBDate = new Date(today.getFullYear() - minYearsDob, today.getMonth(), today.getDate());
  let maxDOBDate = new Date(today.getFullYear() - maxYearsDob, today.getMonth(), today.getDate());
  let tempMinMonitoredSince = new Date(2020, 0, 1);
  let tempMaxMonitoredSince = new Date(today.getFullYear(), today.getMonth(), today.getDate());
  const [minDateOfBirth] = useState<string>(formattedDate(minDOBDate));
  const [maxDateOfBirth] = useState<string>(formattedDate(maxDOBDate));
  const [minMonitoredSince] = useState<string>(formattedDate(tempMinMonitoredSince));
  const [maxMonitoredSince] = useState<string>(formattedDate(tempMaxMonitoredSince));


  useEffect(() => {
      document.title = "Patient Details";

      // Listen to enter key press to submit form.
      function handleKeyDown(e: any) {
        if (e.code === 'Enter') {
          document.removeEventListener('keydown', handleKeyDown);
          showInitialize();
        }
      }

      document.addEventListener('keydown', handleKeyDown);
      setCellPhone(patient.data.cellPhone);

    }, [patient.data]
  );

  /**
   * Let's check that all validation processes are successful. If they are then we should update
   * the Cloud via the API. Success will take the user to the initialization flow.
   */
  const showInitialize = () => {

    // Validate form
    if (!isFormValid()) {
      setError(true);
      setSuccess(false);
      setSuccessText('');
      setErrorText('Please correct the details in the highlight fields below before continuing.')
      setIsSavingPatientDetails(false);
      window.scrollTo(0, 0);
      return;
    }

    setError(false);
    setIsSavingPatientDetails(true);
    console.debug('PatientDetailsForm.tsx showInitialize() - patient', patient);
    patientDetailsService.updateSeatUserDetails(patient.data).then((response: any) => {
      console.debug('PatientDetailsForm.tsx -> patientDetailsService.tsx - updateSeatUserDetails()', response);
      if (!response.success) {
        setError(true);
        setSuccess(false);
        setErrorText('Error with form submission: ' + response.data.errors);
        setSuccessText('');
        setIsSavingPatientDetails(false);
        return;
      }

      setIsSavingPatientDetails(false);
      history.push('/initialize');
      locationContext.returnView = '/initialize';
      seatSettings.setButtonClass('off');
    }).catch((error: any) => {
      console.error(error);
      setIsSavingPatientDetails(false);
      setSuccess(false);
      setSuccessText('');
      setError(true);
      setErrorText('Error with form submission. Please try again later');
    });
  }

  const isFormValid = () => {
    // Ensure required fields are correct
    return handleFirstNameChange(patient.data.firstName)
      && handleLastNameChange(patient.data.lastName)
      && handleSexAssignedAtBirthChange(patient.data.sexAssignedAtBirth)
      && handleDOBChange(patient.data.dateOfBirth)
      && handleMonitoredSinceChange(patient.data.monitoredSince)
      && handleSternalNotchChange(patient.data.seatUserCalibrations.sternalNotch)
      && handleCellPhoneChange(patient.data.cellPhone)
      && handleEmailChange(patient.data.email)
      && handleHeightFeetChange(patient.data.seatUserCalibrations.heightFeet)
      && handleHeightInchesChange(patient.data.seatUserCalibrations.heightInches)
      && checkTotalHeight()
      && checkAddress();
  }

  const checkTotalHeight = () => {
    let heightCm = UnitConversionHelper.feetInchesToCentimeters(
      patient.data.seatUserCalibrations.heightFeet,
      patient.data.seatUserCalibrations.heightInches
    );

    let isValid = heightCm > initRefs.heightTotalCmMin && heightCm < initRefs.heightTotalCmMax;
    setIsTotalHeightValid(isValid)

    return isValid;
  }

  const checkAddress = () => {
    if (!patient.data.address
      && !patient.data.addressOther
      && !patient.data.city
      && !patient.data.state
      && !patient.data.zipCode) {
      return true;
    }

    return handleStateChange(patient.data.state)
      && handleZipCodeChange(patient.data.zipCode)
      && !!patient.data.address
      && !!patient.data.city;
  }

  const selectUserId = () => {
    handleSelectIDView();
  }

  /**
   * Convert a date provided into a time string that can be used in comparisons and for the form element.
   *
   * @param providedDate
   */
  function formattedDate(providedDate: Date) {
    return moment(providedDate).format('MM/DD/YYYY');
  }

  const handleFirstNameChange = (val: string): boolean => {
    patient.data.firstName = val;
    let isValid = val.length !== 0 && !!val.match(validationRefs.nameRegex);
    setIsFirstNameValid(isValid);
    return isValid;
  }

  const handleLastNameChange = (val: string): boolean => {
    patient.data.lastName = val;
    let isValid = val.length !== 0 && !!val.match(validationRefs.nameRegex);
    setIsLastNameValid(isValid);
    return isValid;
  }

  const handleDOBChange = (val: string): boolean => {
    patient.data.dateOfBirth = val;
    let dobAsTime = new Date(val).getTime();
    let isValid = val.length !== 0 && (dobAsTime > minDOBDate.getTime() && dobAsTime < maxDOBDate.getTime());
    setIsDOBValid(isValid);
    return isValid;
  }

  const handleMonitoredSinceChange = (val: string): boolean => {
    patient.data.monitoredSince = val;
    let monitoredSinceAsTime = new Date(val).getTime();
    let isValid = (val.length === 0 || (
        monitoredSinceAsTime >= tempMinMonitoredSince.getTime() &&
        monitoredSinceAsTime <= tempMaxMonitoredSince.getTime())
    );
    setIsMonitoredSinceValid(isValid);
    return isValid;
  }

  const handleEmailChange = (val: string) => {
    patient.data.email = val;

    if (val === '' || val === null) {
      setIsEmailValid(true);
      return true;
    }

    let isValid = checkEmailValid(patient.data.email);
    setIsEmailValid(isValid);
    return isValid;
  }


  const checkEmailValid = (email: string): boolean => {
    return !!String(email).toLowerCase().match(validationRefs.emailRegex);
  };

  const handleCellPhoneChange = (val: string): boolean => {
    if (val === '' || val === null) {
      patient.data.cellPhone = val;
      setIsCellPhoneValid(true);
      setCellPhone(val);
      return true;
    }

    let formattedCellPhone = val ? FormHelper.formatPhoneNumber(val) : '';
    const parsedVal = val.replace(/[^0-9]/g, '');

    if (parsedVal && formattedCellPhone) {
      patient.data.cellPhone = formattedCellPhone;
      setCellPhone(formattedCellPhone);
    } else {
      patient.data.cellPhone = '';
      setCellPhone('');
    }

    let isValid = parsedVal.length >= 10;
    setIsCellPhoneValid(isValid);

    return isValid;
  }

  // This is added to the inches and sent over to the initialization endpoint as single metric value (cm)
  const handleHeightFeetChange = (val: number): boolean => {
    patient.data.seatUserCalibrations.heightFeet = val;
    checkTotalHeight();

    let isValid = patient.data.seatUserCalibrations.heightFeet >= initRefs.heightFtMin
      && patient.data.seatUserCalibrations.heightFeet <= initRefs.heightFtMax;

    setIsHeightFeetValid(isValid);
    return isValid;
  }

  // This is added to the feet and sent over to the initialization endpoint as single metric value (cm)
  const handleHeightInchesChange = (val: number): boolean => {
    patient.data.seatUserCalibrations.heightInches = val;
    checkTotalHeight();

    let isValid = patient.data.seatUserCalibrations.heightInches >= initRefs.heightInMin
      && patient.data.seatUserCalibrations.heightInches <= initRefs.heightInMax;

    setIsHeightInchesValid(isValid);
    return isValid;
  }

  // This is sent over to the initialization endpoint as metric (13 - 30 inches inclusive, or 33.02 - 76.2 cm)
  const handleSternalNotchChange = (val: number): boolean => {
    patient.data.seatUserCalibrations.sternalNotch = val;

    let isValid = patient.data.seatUserCalibrations.sternalNotch >= initRefs.sternalNotchInchesMin
      && patient.data.seatUserCalibrations.sternalNotch <= initRefs.sternalNotchInchesMax

    setIsSternalNotchValid(isValid);
    return isValid;
  }

  /**
   * If state is enter then we should ensure that it matches an actual
   * state that is listed in the usStateOptions array.
   *
   * @param val
   */
  const handleStateChange = (val: string) => {
    patient.data.state = val.toUpperCase();
    let isValid = val === '' || usStateOptions.includes(val.toUpperCase());
    setIsStateValid(isValid);
    return isValid;
  }

  /**
   * This function will return true when the field is empty or
   * has 5 digits in it (no alpha chars)
   *
   * @param val
   */
  const handleZipCodeChange = (val: string) => {

    let isValid;

    if (val.length === 0) {
      patient.data.zipCode = val;
      isValid = true;
    } else if (val.length === 5) {
      patient.data.zipCode = val;
      isValid = true;
    } else if (val.length > 5) {
      patient.data.zipCode = val.slice(0, 5);
      isValid = false;
    } else {
      patient.data.zipCode = val;
      isValid = false;
    }

    setIsZipCodeValid(isValid);
    return isValid;
  }

  const handleAddressChange = (val: string) => {
    patient.data.address = val;
  }

  const handleAddressOtherChange = (val: string) => {
    patient.data.addressOther = val;
  }

  const handleSexAssignedAtBirthChange = (val: string) => {
    patient.data.sexAssignedAtBirth = val;
    let isValid = !!val;
    setIsSexAssignedAtBirthValid(isValid);
    return isValid;
  }

  const handleCityChange = (val: string) => {
    patient.data.city = val;
  }

  const usStateOptions = [
    'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL',
    'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH',
    'NJ', 'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX',
    'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY'
  ];

  return (
    <>
      <IonContent className="container">
        <IonCard className={error ? 'ion-show flash-message' : 'ion-hide flash-message'}>
          <IonText className="danger">
            {errorText}
          </IonText>
        </IonCard>
        <IonCard className={success ? 'ion-show flash-message' : 'ion-hide flash-message'}>
          <IonText className="success">
            {successText}
          </IonText>
        </IonCard>
        <IonCard className="standard-container casana-form">
          <IonCardHeader>
            <IonCardTitle>Patient information</IonCardTitle>
            {isSavingPatientDetails ? <IonProgressBar type="indeterminate"></IonProgressBar> : null}
          </IonCardHeader>
          <IonCardContent className="standard-container-content">
            <form className="casana-form patient-details-form">
              <IonGrid className="casana-form">
                <IonRow>
                  <IonCol size="12" size-md="6">
                    <IonLabel>
                      <IonIcon className="required" slot="start" ios={star} md={star}/>
                      First name
                    </IonLabel>
                    <IonItem className={isFirstNameValid ? 'ion-valid' : 'ion-invalid'}>
                      <IonInput
                        required={true}
                        autofocus={true}
                        inputmode={"text"}
                        maxlength={255}
                        type="text"
                        debounce={300}
                        placeholder="Patient first name"
                        onIonChange={(e: any) => handleFirstNameChange(e.detail.value)}
                        value={patient.data.firstName}
                      />
                      <IonNote slot="error">First name is a required field and cannot include numbers or special
                        characters.</IonNote>
                    </IonItem>
                  </IonCol>
                  <IonCol size="12" size-md="6">
                    <IonLabel>
                      <IonIcon className="required" slot="start" ios={star} md={star}/>
                      Last name
                    </IonLabel>
                    <IonItem className={isLastNameValid ? 'ion-valid' : 'ion-invalid'}>
                      <IonInput
                        required={true}
                        inputmode={"text"}
                        maxlength={255}
                        type="text"
                        debounce={300}
                        placeholder="Patient last name"
                        onIonChange={(e: any) => handleLastNameChange(e.detail.value)}
                        value={patient.data.lastName}
                      />
                      <IonNote slot="error">Last name is a required field and cannot include numbers or special
                        characters.</IonNote>
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow>

                  <IonCol size="12" size-md="6">
                    <IonLabel>
                      <IonIcon className="required" slot="start" ios={star} md={star}/>
                      Date of birth
                    </IonLabel>
                    <IonItem className={isDOBValid ? 'ion-valid' : 'ion-invalid'}>
                      <IonInput
                        required={true}
                        maxlength={255}
                        type="date"
                        debounce={300}
                        min={minDateOfBirth}
                        max={maxDateOfBirth}
                        placeholder="MM/DD/YYYY"
                        onIonChange={(e: any) => handleDOBChange(e.detail.value)}
                        value={patient.data.dateOfBirth}
                      />
                      <IonNote slot="error">
                        You must enter a valid date of birth in the format MM/DD/YYYY. Patients must be at
                        least {maxYearsDob} years old, and no older than {minYearsDob} years old.
                        <br/>
                        <br/>
                        <strong>Oldest:</strong> {minDateOfBirth} <br/>
                        <strong>Youngest:</strong> {maxDateOfBirth}
                      </IonNote>
                    </IonItem>
                  </IonCol>

                  <IonCol size="12" size-md="6" no-padding>
                    <IonLabel>
                      <IonIcon className="required" slot="start" ios={star} md={star}/>
                      Sex assigned at birth
                    </IonLabel>
                    <IonItem className={isSexAssignedAtBirthValid ? 'ion-valid' : 'ion-invalid'}>
                      <IonSelect
                        okText="SAVE"
                        placeholder="Select sex assigned at birth"
                        value={patient.data.sexAssignedAtBirth}
                        onIonChange={(e: any) => handleSexAssignedAtBirthChange(e.detail.value)}
                      >
                        <IonSelectOption value="M">Male</IonSelectOption>
                        <IonSelectOption value="F">Female</IonSelectOption>
                      </IonSelect>
                      <IonNote slot="error">Sex assigned at birth is a required field.</IonNote>
                    </IonItem>
                  </IonCol>

                </IonRow>
                <IonRow>

                  <IonCol size="5.9" size-md="2.9">
                    <IonLabel><IonIcon className="required" slot="start" ios={star} md={star}/>Height</IonLabel>
                    <IonItem className={isHeightFeetValid && isTotalHeightValid ? 'ion-valid' : 'ion-invalid'}>
                      <IonInput
                        placeholder="feet"
                        inputmode="numeric"
                        type="number"
                        minlength={1}
                        maxlength={1}
                        min={initRefs.heightFtMin}
                        max={initRefs.heightFtMax}
                        debounce={300}
                        onIonChange={(e: any) => handleHeightFeetChange(e.detail.value)}
                        value={patient.data.seatUserCalibrations.heightFeet}
                      />
                      <IonNote slot="error">
                        <span className={isHeightFeetValid ? 'ion-hide' : 'ion-show'}>
                          Height (feet) should be between 0 feet and 9 feet.&nbsp;
                        </span>
                        <span className={isTotalHeightValid ? 'ion-hide' : 'ion-show'}>
                          Total height must be between {initRefs.heightTotalInMin} inches and {initRefs.heightTotalInMax} inches.
                        </span>
                      </IonNote>
                      <span>feet</span>
                    </IonItem>
                  </IonCol>


                  <IonCol size="5.8" size-md="2.9">
                    <IonLabel>&nbsp;</IonLabel>
                    <IonItem
                      className={isHeightInchesValid && isTotalHeightValid ? 'ion-valid' : 'ion-invalid'}>
                      <IonInput
                        placeholder="inches"
                        inputmode="numeric"
                        type="number"
                        minlength={1}
                        maxlength={2}
                        min={initRefs.heightInMin}
                        max={initRefs.heightInMax}
                        debounce={300}
                        onIonChange={(e: any) => handleHeightInchesChange(e.detail.value)}
                        value={patient.data.seatUserCalibrations.heightInches}
                      />
                      <span>inches</span>

                      <IonNote slot="error">
                        <span className={isHeightInchesValid ? 'ion-hide' : 'ion-show'}>
                          Height (inches) is a required field and should be between 0 inches and 99 inches.&nbsp;
                        </span>
                        <span className={isTotalHeightValid ? 'ion-hide' : 'ion-show'}>
                          Total height must be between {initRefs.heightTotalInMin} inches and {initRefs.heightTotalInMax} inches.
                        </span>
                      </IonNote>
                    </IonItem>
                  </IonCol>

                  <IonCol size="5.9" size-md="2.9" offset-md="0.2">
                    <IonLabel>
                      <IonIcon className="required" slot="start" ios={star} md={star}/>
                      Sternal notch
                    </IonLabel>
                    <IonItem
                      className={isSternalNotchValid ? 'ion-valid' : 'ion-invalid'}>
                      <IonInput
                        placeholder="Sternal notch (inches)"
                        inputmode="numeric"
                        type="number"
                        minlength={2}
                        maxlength={5}
                        min={initRefs.sternalNotchInchesMin}
                        max={initRefs.sternalNotchInchesMax}
                        debounce={300}
                        onIonChange={(e: any) => handleSternalNotchChange(e.detail.value)}
                        value={patient.data.seatUserCalibrations.sternalNotch}
                      />
                      <span>inches</span>
                      <IonNote slot="error">Sternal notch is a required field and should be between 13 inches and 30
                        inches</IonNote>
                    </IonItem>
                  </IonCol>
                  <IonCol size="5.8" size-md="2.9">
                    <IonLabel>Monitored since</IonLabel>
                    <IonItem
                      className={isMonitoredSinceValid ? 'ion-valid' : 'ion-invalid'}>
                      <IonInput
                        required={true}
                        maxlength={255}
                        type="date"
                        debounce={300}
                        min={minMonitoredSince}
                        max={maxMonitoredSince}
                        onIonChange={(e: any) => handleMonitoredSinceChange(e.detail.value)}
                        value={patient.data.monitoredSince}
                      />
                      <IonNote slot="error">The monitored since date must be in the following format MM/DD/YYYY.<br/>
                        <strong>Minimum:</strong> {minMonitoredSince}<br/>
                        <strong>Maximum:</strong> {maxMonitoredSince}
                      </IonNote>
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12" size-md="6">
                    <IonLabel>Email</IonLabel>
                    <IonItem className={isEmailValid ? 'ion-valid' : 'ion-invalid'}>
                      <IonInput
                        autofocus={true}
                        inputmode={"text"}
                        maxlength={180}
                        type="text"
                        debounce={300}
                        placeholder="Email"
                        onIonChange={(e: any) => handleEmailChange(e.detail.value)}
                        value={patient.data.email}
                      />
                      <IonNote slot="error">Email must be a valid email address.</IonNote>
                    </IonItem>
                  </IonCol>

                  <IonCol size="12" size-md="6">
                    <IonLabel>Phone number</IonLabel>
                    <IonItem className={isCellPhoneValid ? 'ion-valid' : 'ion-invalid'}>
                      <IonInput
                        autofocus={true}
                        inputmode="numeric"
                        type="text"
                        placeholder="(555) 555-5555"
                        minlength={14} // 10 digits min plus the four formatting characters for domestic numbers.
                        maxlength={16} // 15 digits max plus the leading + character for international numbers.
                        onIonChange={(e: any) => handleCellPhoneChange(e.detail.value)}
                        debounce={300}
                        value={cellPhone}
                      />
                      <IonNote slot="error">
                        Must be 10-15 digits, no dashes or spaces.
                      </IonNote>
                    </IonItem>
                  </IonCol>
                  {/*<IonCol size="12" size-md="6"></IonCol>*/}
                </IonRow>
                <IonRow>
                  <IonCol size="12" size-md="6">
                    <IonLabel>Address</IonLabel>
                    <IonItem className={isFullAddressValid ? 'ion-valid' : 'ion-invalid'}>
                      <IonInput
                        placeholder="Street address or P.O. Box"
                        type="text"
                        debounce={300}
                        required={true}
                        onIonChange={(e: any) => handleAddressChange(e.detail.value)}
                        value={patient.data.address}
                      />
                      <IonNote slot="error">The address, city, state and zip code fields are required for address
                        submission</IonNote>
                    </IonItem>
                  </IonCol>
                  <IonCol size="12" size-md="6">
                    <IonLabel>Address other</IonLabel>
                    <IonItem>
                      <IonInput
                        placeholder="Apt, Suite, Unit, Building"
                        type="text"
                        debounce={300}
                        value={patient.data.addressOther}
                        onIonChange={(e: any) => handleAddressOtherChange(e.detail.value)}
                      />
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12" size-md="6">
                    <IonLabel>City</IonLabel>
                    <IonItem>
                      <IonInput
                        placeholder="City"
                        type="text"
                        debounce={300}
                        value={patient.data.city}
                        onIonChange={(e: any) => handleCityChange(e.detail.value)}
                      />
                      <IonNote slot="error">City is a required field.</IonNote>
                    </IonItem>
                  </IonCol>
                  <IonCol size="5.8" size-md="2.9">
                    <IonLabel>State</IonLabel>
                    <IonItem className={isStateValid ? 'ion-valid' : 'ion-invalid'}>
                      <IonInput
                        placeholder="State"
                        type="text"
                        minlength={2}
                        maxlength={2}
                        debounce={300}
                        onIonChange={(e: any) => handleStateChange(e.detail.value)}
                        value={patient.data.state}
                      />
                      <IonNote slot="error">State is a required field should be a valid abbreviation.</IonNote>
                    </IonItem>
                  </IonCol>
                  <IonCol size="5.9" size-md="2.9">
                    <IonLabel>Zip code</IonLabel>
                    <IonItem className={isZipCodeValid ? 'ion-valid' : 'ion-invalid'}>
                      <IonInput
                        placeholder="Zip Code"
                        inputmode="numeric"
                        type="number"
                        maxlength={5}
                        debounce={300}
                        onIonChange={(e: any) => handleZipCodeChange(e.detail.value)}
                        value={patient.data.zipCode}
                      />
                      <IonNote slot="error">Zip code must be 5 digits.</IonNote>
                    </IonItem>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </form>
          </IonCardContent>
          <IonFooter className="standard-container-footer">
            <IonButton className="btn btn-next ion-float-right" onClick={showInitialize}>
              Next
            </IonButton>
            <IonButton className="btn btn-back ion-float-right" onClick={selectUserId}>
              Back
            </IonButton>
          </IonFooter>
        </IonCard>
      </IonContent>
    </>
  );
};

export default PatientDetailsForm;
