import {localStorageRefs} from "../Refs";
import axios from "axios";

export default class SessionHelper {

  /**
   * This ensures no data is hanging around if the user is at the /login, /logout routes, or upon
   * successful email change on /validate-email where the token is deleted by the back end.
   */
  static clearData() {
    console.debug('SessionHelper.ts - clearData()');
    window.clearInterval(window.sessionTimer);
    window.clearInterval(window.blePulseHeartbeatInterval);
    window.sessionTimer = null;
    window.sessionTimerMethod = null;
    localStorage.removeItem(localStorageRefs.installerId);
    localStorage.removeItem(localStorageRefs.patientId);
    localStorage.removeItem(localStorageRefs.seatId);
    localStorage.removeItem(localStorageRefs.sessionExpiry);
    localStorage.removeItem(localStorageRefs.sessionToken);
    axios.interceptors.response.clear();
  }

  /**
   * Check if user has a login session JWT token.
   */
  static hasToken() {
    return localStorage.getItem(localStorageRefs.sessionToken) !== null;
  }

  /**
   * Set the token to local storage upon successful login.
   *
   * @param token
   */
  static setToken(token: string) {
    localStorage.setItem(localStorageRefs.sessionToken, token);
  }

  /**
   * Check to see if seat is paired.
   */
  static hasSeat() {
    return localStorage.getItem(localStorageRefs.seatId) !== null;
  }
}
