import {
  IonIcon, IonItem, IonLabel
} from '@ionic/react';
import React from "react";
import "../AccountSettings/AccountSettings.css";
import {
  settingsOutline
} from "ionicons/icons";

interface ContainerProps {
  handleClick: Function
}

const AccountSettings: React.FC<ContainerProps> = ({handleClick}) => {

  return (
    <IonItem className={window.location.pathname === '/account-settings'
               ? 'account-settings-item-container progress-btn active'
               : 'account-settings-item-container progress-btn'
    }
             routerLink="/account-settings"
             routerDirection="none"
             lines="none"
             detail={false}
             onClick={(e: any) => handleClick(e)}
    >
      <IonIcon slot="start" className="account-settings-icon" ios={settingsOutline} md={settingsOutline}></IonIcon>
      <IonLabel className="account-settings-label">Account Settings</IonLabel>
    </IonItem>
  );
};

export default AccountSettings;
