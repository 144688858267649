/* This file is automatically generated from cmd.json */
import * as rs from 'restructure';
import * as proto from './recording_pb';
import NoneDef from './NoneDef';
import BytesDef from './BytesDef';
import StringDef from './StringDef';
import JSONDef from './JSONDef';
import StructDef from './StructDef';
import ProtobufDef from './ProtobufDef';

/* List of all supported commands */
const HsCommandTypes = {
  /* cmd_id 0 is invalid */
  get_fw_version: {
    cmd_id: 1,
    description: 'Get the current firmware version',
    req_fmt: new NoneDef(),
    res_fmt: new StringDef(),
  },
  echo: {
    cmd_id: 2,
    description: 'echo back the provided parameters param_a and param_b',
    req_fmt: new StructDef(new rs.Struct({ param_a: rs.uint16le, param_b: rs.uint16le, })),
    res_fmt: new StructDef(new rs.Struct({ param_a: rs.uint16le, param_b: rs.uint16le, })),
  },
  fw_update_begin: {
    cmd_id: 3,
    description: 'Begin a firmware update',
    req_fmt: new NoneDef(),
    res_fmt: new NoneDef(),
  },
  fw_file_header: {
    cmd_id: 4,
    description: 'Send a firmware update file header',
    req_fmt: new StructDef(new rs.Struct({ mod_id: rs.uint32le, fsize: rs.uint32le, })),
    res_fmt: new NoneDef(),
  },
  fw_file_data: {
    cmd_id: 5,
    description: 'Send a firmware update file data',
    req_fmt: new BytesDef(),
    res_fmt: new NoneDef(),
  },
  fw_update_status: {
    cmd_id: 6,
    description: 'Get the status of a firmware update',
    req_fmt: new NoneDef(),
    res_fmt: new StructDef(new rs.Struct({ is_running: rs.uint16le, error: rs.uint16le, })),
  },
  stay_awake: {
    cmd_id: 7,
    description: 'Tell the firmware to stay awake',
    req_fmt: new StructDef(new rs.Struct({ seconds: rs.uint16le, })),
    res_fmt: new StringDef(),
  },
  checkin: {
    cmd_id: 8,
    description: 'Check in with the cloud',
    req_fmt: new NoneDef(),
    res_fmt: new NoneDef(),
  },
  factory_reset: {
    cmd_id: 9,
    description: 'Performs a factory reset of the seat',
    req_fmt: new NoneDef(),
    res_fmt: new NoneDef(),
    no_response: true,
  },
  software_reset: {
    cmd_id: 10,
    description: 'Performs software reset of the MCU',
    req_fmt: new NoneDef(),
    res_fmt: new NoneDef(),
    no_response: true,
  },
  create_recording: {
    cmd_id: 11,
    description: 'Creates a recording with reason={0-4} and duration={seconds}',
    req_fmt: new StructDef(new rs.Struct({ reason: rs.uint16le, duration: rs.uint16le, })),
    res_fmt: new NoneDef(),
  },
  checkin_with_upload: {
    cmd_id: 12,
    description: 'Force the seat to check in with the cloud and upload logs and recording',
    req_fmt: new NoneDef(),
    res_fmt: new NoneDef(),
  },
  old_get_config: {
    cmd_id: 13,
    description: 'Deprecated in favor of reading config files directly',
    req_fmt: new NoneDef(),
    res_fmt: new NoneDef(),
  },
  old_set_config: {
    cmd_id: 14,
    description: 'Deprecated in favor of set_user_config and cloud-based operating config',
    req_fmt: new NoneDef(),
    res_fmt: new NoneDef(),
  },
  get_status: {
    cmd_id: 15,
    description: 'Get the status of the heart seat (e.g., # of recordings)',
    req_fmt: new NoneDef(),
    res_fmt: new ProtobufDef(proto.HeartSeatStatus),
  },
  file_get_info: {
    cmd_id: 16,
    description: 'Get the size of a file along with the max chunk size',
    req_fmt: new StringDef(),
    res_fmt: new StructDef(new rs.Struct({ fsize: rs.int32le, max_chunk_size: rs.uint32le, })),
  },
  file_read_raw: {
    cmd_id: 17,
    description: 'Reads the actual data from the file one chunk at a time',
    req_fmt: new StructDef(new rs.Struct({ path: new rs.Buffer(64), index: rs.uint32le, bytes: rs.uint32le, })),
    res_fmt: new BytesDef(),
  },
  stop_recording: {
    cmd_id: 18,
    description: 'Stop a running recording',
    req_fmt: new NoneDef(),
    res_fmt: new NoneDef(),
  },
  get_recording_status: {
    cmd_id: 19,
    description: 'Get the recording transport status',
    req_fmt: new NoneDef(),
    res_fmt: new ProtobufDef(proto.RecordingStatus),
  },
  clear_recordings: {
    cmd_id: 20,
    description: 'Delete all recordings saved locally on the seat',
    req_fmt: new NoneDef(),
    res_fmt: new StringDef(),
  },
  dump_profile: {
    cmd_id: 21,
    description: 'Display function profiling log and reset profiling statistics',
    req_fmt: new NoneDef(),
    res_fmt: new NoneDef(),
  },
  clear_logs: {
    cmd_id: 22,
    description: 'Clear logs and start a new one',
    req_fmt: new NoneDef(),
    res_fmt: new NoneDef(),
  },
  set_ppg_on: {
    cmd_id: 23,
    description: 'Set PPG power on until next reset',
    req_fmt: new NoneDef(),
    res_fmt: new StringDef(),
  },
  set_user_config: {
    cmd_id: 24,
    description: 'Set the user config',
    req_fmt: new JSONDef(),
    res_fmt: new NoneDef(),
  },
  set_next_led_currents: {
    cmd_id: 25,
    description: 'Set the LED currents (red_current_mA and ir_current_mA) for the next recording',
    req_fmt: new StructDef(new rs.Struct({ red_current_mA: rs.floatle, ir_current_mA: rs.floatle, })),
    res_fmt: new NoneDef(),
  },
  get_public_key: {
    cmd_id: 26,
    description: 'Get the public key used for cloud access (in hex)',
    req_fmt: new NoneDef(),
    res_fmt: new StringDef(),
  },
  ble_auth: {
    cmd_id: 27,
    description: 'Authenticate a BLE connection with a token string',
    req_fmt: new StringDef(),
    res_fmt: new NoneDef(),
  },
  get_ble_auth_token: {
    cmd_id: 28,
    description: 'Get the auth token used for BLE access (in hex)',
    req_fmt: new NoneDef(),
    res_fmt: new StringDef(),
  },
  run_test: {
    cmd_id: 29,
    description: 'Run a firmware test by name',
    req_fmt: new StringDef(),
    res_fmt: new NoneDef(),
  },
  force_set_user_config: {
    cmd_id: 30,
    description: 'Set the user config and mark it as verified without checking',
    req_fmt: new JSONDef(),
    res_fmt: new NoneDef(),
  },
  get_wifi_list: {
    cmd_id: 31,
    description: 'Get a list of available wifi networks',
    req_fmt: new NoneDef(),
    res_fmt: new StringDef(),
  },
  get_scrubbed_user_cfg: {
    cmd_id: 32,
    description: 'Get a display-safe version of the user config',
    req_fmt: new NoneDef(),
    res_fmt: new JSONDef(),
  },
};

export default HsCommandTypes;
