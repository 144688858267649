import './modal.css';
import useSessionExpirationModalWarning from "./SessionExpirationModalHook";
import React, {useContext} from "react";
import ConfirmCancelModal from "./ConfirmCancelModal";
import AuthContext from "../Auth/AuthContext";
import AuthenticationService from "../../services/AuthenticationService/AuthenticationService";
import {sessionRefs} from "../../Refs";


function SessionExpirationModalNotification() {

  const authContext = useContext(AuthContext);
  const auth = new AuthenticationService(authContext);
  const { warning, removeWarning } = useSessionExpirationModalWarning();

  async function refreshSession() {
    removeWarning();
    // @todo is this needed?
    window.clearInterval(window.sessionTimer);
    let response = await auth.refreshSession();

    if (!response.success) {
      console.debug('SessionExpirationModal.tsx - refreshSession() failed', response);
      alert('Refresh was unsuccessful, you will be logged out now.')
      return logout();
    }

    console.debug('SessionExpirationModal.tsx - refreshSession() success', response);

    // @todo this is redundant as the auth service does this anyway
    window.clearInterval(window.sessionTimer);
    window.sessionTimer = window.sessionTimerMethod(sessionRefs.expiryMs);
  }

  function logout() {
    removeWarning();
    window.clearInterval(window.sessionTimer);
    window.sessionTimer = null;
    window.sessionTimerMethod = null;
    window.location.href = '/logout'; // useHistory() does not work in this context
  }

  return (
    <ConfirmCancelModal
      isOpen={warning}
      headerText="Session expiring soon"
      subheaderText="Your session is about to end. Please choose to continue this session or log out."
      onButtonAction1={refreshSession}
      actionButtonText1="Continue session"
      onButtonAction2={logout}
      actionButtonText2="Log out"
      showWarningIcon={true}
      bigHeader
    />
  )
}

export default SessionExpirationModalNotification
