import {createContext} from 'react';
import {AccountSettings, PasswordSettings} from "../../types/AccountSettings";

interface AccountSettingsInterface {
  data: AccountSettings
}

interface PasswordInterface {
  data: PasswordSettings
}


export const AccountSettingsDataContext = createContext<AccountSettingsInterface>({
  'data': {
    'firstName': '',
    'lastName': '',
    'email': '',
    'resetEmailRequestId': 0,
    'cellPhoneId': 0,
    'cellPhone': '',
    'timezone': '',
    'enableTwoFactorAuth': false,
  }
});

export const PasswordSettingsDataContext = createContext<PasswordInterface>({
  'data': {
    'currentPassword': '',
    'newPassword': '',
    'confirmPassword': ''
  }
});
