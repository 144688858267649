import React, {useContext, useEffect, useState} from 'react';

import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
  IonInput,
  IonText,
  IonButton,
  IonNote
} from '@ionic/react';

import '../Login/Login.css';
import '../../components/forms/AuthenticationForm/AuthenticationForm.css';
import { Image } from '../../types/Image';
import {checkmark, lockClosedOutline, personOutline} from "ionicons/icons";
import AuthContext from "../../components/Auth/AuthContext";
import AuthenticationService from "../../services/AuthenticationService/AuthenticationService";
import {validationRefs} from "../../Refs";
import ConfirmCancelModal from "../../components/Modal/ConfirmCancelModal";


const CompleteUserRegistration: React.FC = () => {

  useEffect(() => {
    document.title = "User Registration"
  }, [])

  const casanaLogo: Image = {src: './assets/casana-logo.svg', text: 'Casana'};
  const loginImg: Image = { src: './assets/toilet.svg', text: 'Login' };
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [statusText, setStatusText] = useState<string>('Enter your name and password to register your account');

  const [isFirstNameValid, setIsFirstNameValid] = useState<boolean>(true);
  const [isLastNameValid, setIsLastNameValid] = useState<boolean>(true);
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(true);
  const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState<boolean>(true);
  const [passwordsMatch, setPasswordsMatch] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [hasTokenError, setHasTokenError] = useState<boolean>(false);
  const [tokenErrorMessage, setTokenErrorMessage] = useState<string>('');

  const authContext = useContext(AuthContext);
  const auth = new AuthenticationService(authContext);

  useEffect(() => {
    document.title = "Complete User Registration";
    console.debug('CompleteUserRegistration.tsx UseEffect() -> Check Hashed Token');
    checkHashedToken().then();
  }, []);

  const checkHashedToken = async () => {
    let rawUrl = window.location.pathname.replace('user-complete-registration/', '|');
    let url = rawUrl.split('|');
    let token = url[url.length - 1];

    auth.checkUserRegistrationToken(token).then((response: any) => {
      console.debug('auth.checkUserRegistrationToken', response);
      if (!response.success) {
        setHasTokenError(true);
        setTokenErrorMessage(response.errors);
      }
    });
  }

  const handleFormSubmit = async () => {

    if (!validateForm()) {
      setStatusText('There were errors with your submission.');
      return;
    }

    setStatusText('');
    setIsSubmitting(true);

    let rawUrl = window.location.pathname.replace('user-complete-registration/', '|');
    let url = rawUrl.split('|');
    const setupHashedToken = url[url.length - 1];

    auth.completeUserRegistration(firstName, lastName, password, confirmPassword, setupHashedToken).then((response: any) => {

      if (!response.success) {
        console.debug('CompleteUserRegistration.tsx - handleFormSubmit() ERROR - ', response);
        setStatusText(response.errors);
        setIsSubmitting(false);
        return;
      }

      setFirstName('');
      setLastName('');
      setPassword('');
      setConfirmPassword('');
      setPasswordsMatch(true);
      setStatusText('Your account has been registered! Redirecting to login...');

      setTimeout(function() {
        setIsSubmitting(false);
        window.location.href = '/login';
      }, 3000)
    });
  }

  const handleReturnToLogin = () => {
    window.location.href = '/login';
  }

  const handleFirstNameChange = (val: string) => {
    setFirstName(val);

    if (val.length === 0) {
      setIsFirstNameValid(false);
    } else {
      setIsFirstNameValid(true);
    }
  }

  const handleLastNameChange = (val: string) => {
    setLastName(val);

    if (val.length === 0) {
      setIsLastNameValid(false);
    } else {
      setIsLastNameValid(true);
    }
  }

  const handlePasswordChange = (val: string) => {
    setPasswordsMatch(true);
    setPassword(val);
    setIsPasswordValid(checkPassword(val));
  }

  const handleConfirmPasswordChange = (val: string) => {
    setPasswordsMatch(true);
    setConfirmPassword(val);
    setIsConfirmPasswordValid(checkPassword(val));
  }

  const checkPassword = (val: string) => {
    if (val.length === 0) {
      return false;
    }

    return !!val.match(validationRefs.passwordRegex);
  }

  /**
   * Ensure form is valid.
   */
  const validateForm = () => {
    handleFirstNameChange(firstName);
    handleLastNameChange(lastName);
    handlePasswordChange(password);
    handleConfirmPasswordChange(confirmPassword);
    setPasswordsMatch(password === confirmPassword);

    return firstName !== '' && lastName !== '' && password !== '' && confirmPassword !== ''
      && isFirstNameValid && isLastNameValid && isPasswordValid && isConfirmPasswordValid
      && password === confirmPassword;
  }

  return (
    <IonPage>
      <IonContent fullscreen className="login-screen">
        <IonGrid className="ion-no-padding">
          <IonRow className="ion-justify-content-center">
            <IonCol className="ion-float-left login-image desktop">
              <IonImg src={loginImg.src} alt={loginImg.text} className="toilet-seat" />
            </IonCol>
            <IonCol className="ion-float-right login-form-column ion-no-padding">
              <IonContent className='authentication-form'>
                <IonImg src={casanaLogo.src} alt={casanaLogo.text} className='casana-logo'></IonImg>
                <IonCard className="authentication-form-text">
                  <IonCardHeader>
                    <IonCardTitle>User Registration</IonCardTitle>
                    <IonCardSubtitle>{statusText}</IonCardSubtitle>
                  </IonCardHeader>
                </IonCard>
                <IonList className="authentication-form">
                  <IonItem className={isFirstNameValid ? 'ion-valid' : 'ion-invalid'}>
                    <IonLabel>
                      <IonIcon slot="start" ios={personOutline} md={personOutline}/>
                    </IonLabel>
                    <IonInput type="text" onIonChange={(e: any) => handleFirstNameChange(e.detail.value)} placeholder="First name"></IonInput>
                    <IonNote slot="error">First name is a required field.</IonNote>
                  </IonItem>
                  <IonItem className={isLastNameValid ? 'ion-valid' : 'ion-invalid'}>
                    <IonLabel>
                      <IonIcon slot="start" ios={personOutline} md={personOutline}/>
                    </IonLabel>
                    <IonInput type="text" onIonChange={(e: any) => handleLastNameChange(e.detail.value)} placeholder="Last name"></IonInput>
                    <IonNote slot="error">Last name is a required field.</IonNote>
                  </IonItem>
                  <IonItem className={isPasswordValid && passwordsMatch ? 'ion-valid' : 'ion-invalid'}>
                    <IonLabel>
                      <IonIcon slot="start" ios={lockClosedOutline} md={lockClosedOutline}/>
                    </IonLabel>
                    <IonInput type="password" onIonChange={(e: any) => handlePasswordChange(e.detail.value)} placeholder="Password"></IonInput>
                    <IonNote slot="error">
                      <span className={isPasswordValid ? 'ion-hide' : 'ion-show'}>{validationRefs.passwordMsg}</span>
                      <span className={passwordsMatch ? 'ion-hide' : 'ion-show'}>{validationRefs.passwordMismatchMsg}</span>
                    </IonNote>
                  </IonItem>
                  <IonItem className={isConfirmPasswordValid && passwordsMatch ? 'ion-valid' : 'ion-invalid'}>
                    <IonLabel>
                      <IonIcon slot="start" ios={lockClosedOutline} md={lockClosedOutline}/>
                    </IonLabel>
                    <IonInput type="password" onIonChange={(e: any) => handleConfirmPasswordChange(e.detail.value)} placeholder="Confirm password"></IonInput>
                    <IonNote slot="error">
                      <span className={isConfirmPasswordValid ? 'ion-hide' : 'ion-show'}>{validationRefs.passwordMsg}</span>
                      <span className={passwordsMatch ? 'ion-hide' : 'ion-show'}>{validationRefs.passwordMismatchMsg}</span>
                    </IonNote>
                  </IonItem>
                  <IonText className="user-registration-password-requirement-text">
                    <p>Passwords must:</p>
                    <p><IonIcon slot="start" ios={checkmark} md={checkmark}/>&nbsp;Be a minimum of 8 characters</p>
                    <p><IonIcon slot="start" ios={checkmark} md={checkmark}/>&nbsp;Include at least one lowercase letter (a-z)</p>
                    <p><IonIcon slot="start" ios={checkmark} md={checkmark}/>&nbsp;Include at least one uppercase letter (A-Z)</p>
                    <p><IonIcon slot="start" ios={checkmark} md={checkmark}/>&nbsp;Include at least one number (0-9)</p>
                  </IonText>
                  <IonText className="ion-text-center">
                    <p>
                      <a onClick={handleReturnToLogin} className="auth-form-link">
                        I already have an account
                      </a>
                    </p>
                  </IonText>
                  <IonButton expand="block" disabled={isSubmitting} onClick={handleFormSubmit}>Register</IonButton>
                </IonList>
              </IonContent>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <ConfirmCancelModal
        isOpen={hasTokenError}
        headerText="An Error Occurred..."
        subheaderText={tokenErrorMessage}
        onButtonAction1={() => {setHasTokenError(false);}}
        actionButtonText1="Ok"
        showWarningIcon={true}
        bigHeader
      />
    </IonPage>
  );
};

export default CompleteUserRegistration;
