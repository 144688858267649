import './Initialize.css';
import React, {ReactNode, useContext, useEffect, useRef, useState} from 'react';
import {
  IonContent,
  IonPage,
  IonText,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent, IonButton, IonFooter, IonProgressBar
} from '@ionic/react';
import {useHistory} from "react-router-dom";
import {InitializationProvider} from "../../components/Initialize/InitializationProvider";
import {LocationData} from "../../types/LocationData";
import AppLocationContext from "../../components/Includes/AppLocationContext";
import {CountdownCircleTimer} from "react-countdown-circle-timer";
import {defaultSitTime, seatProcessCheckinCode} from "../../Refs";
import hsi from "../../lib/HeartSeatInterface";
import {SeatProcessEvent} from "../../types/SeatProcessEvent";


const InitializeManual: React.FC = () => {
  const history = useHistory();
  const locationContext = useContext<LocationData>(AppLocationContext);

  const [errorText, setErrorText] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [successText, setSuccessText] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [hasResetTimer, setHasResetTimer] = useState<boolean>(false);

  const hasResetTimerRef = useRef<boolean>();
  hasResetTimerRef.current = hasResetTimer;

  useEffect(() => {
    document.title = "Initialize your seat";
    unregisterSeatListener();
    registerSeatListener();
  }, []);

  const setBanner = (success: boolean, message: string) => {
    if (success) {
      setSuccess(true);
      setSuccessText(message);
      setError(false);
    } else {
      setError(true);
      setErrorText(message);
      setSuccess(false);
    }
  }

  const clearBanner = () => {
    setSuccess(false);
    setError(false);
  }

  const handleRecordingCompletedLink = () => {
    history.push('/initialize/manual/confirm');
    locationContext.returnView = '/initialize/manual/confirm';
  }

  const handleBackToStart = () => {
    history.push('/initialize')
    locationContext.returnView = '/initialize';
  }

  const setTime = ({remainingTime}: any): any => {

    let minutes = Math.floor(remainingTime / 60);
    let seconds = remainingTime - minutes * 60;
    let formattedSeconds = seconds >= 10 ? seconds : '0' + seconds;

    if (remainingTime === 0) {
      return (
        <div className="timer">
          <div className="value">{minutes}:{formattedSeconds}</div>
        </div>
      );
    }

    return (
      <div className="timer">
        <div className="value">{minutes}:{formattedSeconds}</div>
      </div>
    );
  };

  const handleSitTimerCompletion = () => {
    resetSitTimer(false);
    onSitTimerComplete();
  }

  const [sitTimerContent, setSitTimerContent] = useState<ReactNode>(
    <CountdownCircleTimer
      isPlaying={false}
      duration={defaultSitTime}
      colors={["#6a6efe", "#6a6efe"]}
      colorsTime={[10, 0]}
      strokeWidth={4}
      onComplete={() => ({shouldRepeat: false, delay: 1})}>
      {setTime}
    </CountdownCircleTimer>);

  const startSitTimer = (): any => {

    clearBanner();

    let duration = parseInt(`${defaultSitTime}`); // Bizarre hack to make sure duration is an integer

    let hsRecConfig = {
      reason: 1,
      duration: duration
    };

    // The create_recording command will record for the time specified but will not upload due to seat auto upload configuration
    try {
      hsi.handleCmd('create_recording', hsRecConfig).then((res: any) => {
        console.debug('InitializeManualSitTimerScreen.tsx startSitTimer() hsi start_recording');
        setIsRecording(true);
        setSitTimerContent(<CountdownCircleTimer
          key={0}
          isPlaying={true}
          duration={defaultSitTime}
          colors={["#6A6EFF", "#6A6EFF"]}
          colorsTime={[10, 0]}
          strokeWidth={4}
          onComplete={() => {
            handleSitTimerCompletion()
            return {shouldRepeat: false, delay: 1}
          }}
        >
          {setTime}
        </CountdownCircleTimer>);

      }).catch((e: any) => {
        setBanner(false, 'Bluetooth error. Failed to create recording. Please pair your seat via bluetooth before proceeding.');
        console.error('BLUETOOTH ERROR', e);
      });

    } catch (error) {
      setBanner(false, 'Application error. Failed to create recording.');
      console.error('APP ERROR', error);
    }
  }

  const resetSitTimer = (forceStopRecording: boolean): any => {

    /**
     * For Carl seats the stop_recording command will also trigger a check-in, and the completion of check-in is what
     * the listener is listening for. For Kevin seats the stop_recording command does not trigger a check-in, so
     * this command is made after the stop_recording command to ensure the UI gets updated properly.
     */
    if (forceStopRecording) {
      hsi.handleCmd('stop_recording').then((res: any) => {
        hsi.handleCmd('checkin').then((res: any) => {
          setBanner(true, 'Resetting seat for recording.');
          console.debug('InitializeManualSitTimerScreen - resetSitTimer() hsi stop_recording and checkin');
          setIsRecording(false);
          setHasResetTimer(true);
        });
      });
    } else {
      hsi.handleCmd('checkin').then((res: any) => {
        setBanner(true, 'Resetting seat for recording.');
        console.debug('InitializeManualSitTimerScreen - resetSitTimer() hsi checkin');
        setIsRecording(false);
        setHasResetTimer(true);
      });
    }


    clearBanner();

    setSitTimerContent(<CountdownCircleTimer
      key={1}
      isPlaying={false}
      duration={defaultSitTime}
      colors={["#6A6EFF", "#6A6EFF"]}
      colorsTime={[10, 0]}
      strokeWidth={4}
      onComplete={() => ({shouldRepeat: false, delay: 1})}>
      {setTime}
    </CountdownCircleTimer>);
  }

  const onSitTimerComplete = () => {
    setBanner(true, 'Sit recording completed successfully!');
    setIsRecording(false);
    handleRecordingCompletedLink();
  }

  /**
   * If the recording was reset, listen for the seat to complete any pending tasks so clinician
   * can record again. This only does anything if hasResetTimer === true.
   *
   * @param ev
   */
  const postResetSeatListener = (ev: SeatProcessEvent) => {

    console.debug('POST AUTO ERROR SEAT LISTENER', ev);

    if (!hasResetTimerRef.current) {
      return;
    }

    if (hasSeatCompletedTasksAfterReset(ev)) {
      console.debug('Seat has completed resetting');
      handleSeatReadyToRecordAfterReset();
    }
  }

  const handleSeatReadyToRecordAfterReset = () => {
    setBanner(true, 'Seat is ready to re-record.');
    setHasResetTimer(false);
  }

  const hasSeatCompletedTasksAfterReset = (ev: SeatProcessEvent): boolean => {
    return ev.proc_magic === seatProcessCheckinCode && typeof ev.error !== 'undefined';
  }

  const registerSeatListener = () => {
    hsi.registerProcessEventHandler(postResetSeatListener);
  }

  const unregisterSeatListener = () => {
    hsi.unregisterAllProcessEventHandlers();
  }

  return (
    <IonPage>
      <InitializationProvider>
        <IonContent fullscreen className="container">
          <IonCard className={error ? 'ion-show flash-message' : 'ion-hide flash-message'}>
            <IonText className="danger">
              {errorText}
            </IonText>
          </IonCard>
          <IonCard className={success ? 'ion-show flash-message' : 'ion-hide flash-message'}>
            <IonText className="success">
              {successText}
            </IonText>
          </IonCard>
          <IonCard className="standard-container">
            <IonCardHeader>
              <IonCardTitle>Start a manual recording</IonCardTitle>
              {hasResetTimer ? <IonProgressBar type="indeterminate" className="upload-progress"></IonProgressBar> : null}
              <IonCardSubtitle className="m-t-20 m-b-20">Initialization recordings must be a minimum of 270 seconds (4m30s).
              </IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
              <div className="timer-wrapper">
                {sitTimerContent}
              </div>
            </IonCardContent>
            <IonCardContent className="initialize-timer">
              <div className="timer-wrapper">
                <IonButton className="timer-btn start" disabled={isRecording || hasResetTimer} onClick={startSitTimer}>Start</IonButton>
                <IonButton className="timer-btn reset" disabled = {hasResetTimer || !isRecording} onClick={() => {resetSitTimer(true)}}>Reset</IonButton>
              </div>
            </IonCardContent>
            <IonFooter className="standard-container-footer">
              <IonButton className="btn btn-back ion-float-right" disabled={isRecording || hasResetTimer} onClick={handleBackToStart}>Back</IonButton>
            </IonFooter>
          </IonCard>
        </IonContent>
      </InitializationProvider>
    </IonPage>
  );
};

export default InitializeManual;

