import Service from '../Service/Service';
import axios from "axios";
import {SeatConfiguration} from "../../types/SeatConfiguration";

export default class CloudApiService extends Service {

  private casanaCloudApiUrl: string;

  constructor(authContext: any) {
    super(authContext);
    this.casanaCloudApiUrl = `${process.env.REACT_APP_API_URL}/v2/setup_wizard/`;
  }

  /**
   * Retrieve seat details from the Cloud API via the seat's serial number.
   *
   * @param serialNumber
   */
  public getSeatBySerialNumber(serialNumber: string): any {
    let config = this.getRequestConfig();

    return axios.get(this.casanaCloudApiUrl + 'seat/find/' + serialNumber, config).then((response) => {
      console.debug('CloudApiService.tsx -> getSeatBySerialNumber()', response);

      return {
        success: true,
        data: {
          currentBatteryLevel: response.data.current_battery_level,
          serialNumber: response.data.serial_number,
          firmwareVersion: response.data.seat_firmware_version,
          hardwareVersion: response.data.seat_hardware_version,
          seatId: response.data.seat_id,
          targetFirmware: response.data.target_seat_firmware_version,
          bleAuthToken: response.data.bt_auth_token,
          assignedUsers: response.data.assigned_users,
          assignedSeatUserName: this.extractSeatUserDetail(response.data.assigned_patients, 'name'),
          assignedSeatUserId: this.extractSeatUserDetail(response.data.assigned_patients, 'id'),
          seatConfiguration: response.data.seat_configuration
        }
      }
    })
      .catch(function (error) {
        console.error('CloudApiService.tsx -> getSeatBySerialNumber() ERORR', error);
        return {success: false, error: error.response.data}
      });
  }

  /**
   * Update the Seat entity on the Casana Cloud via the API and turn off auto recording and auto uploads.
   * This also sets the seat_status to 3 (active, updating) to mark that Setup Wizard is in progress.
   *
   * @param seatId
   */
  public prepareSeatForAutoRecording(seatId: number): any {
    let config = this.getRequestConfig();
    let data;

    data = {
      uploadInterval: 0,
      automaticRecording: true,
      seatStatus: 3
    }

    return axios.patch(this.casanaCloudApiUrl + 'seat/config/' + seatId, data, config).then(() => {
      return {
        success: true,
      }
    })
      .catch(function (error) {
        console.error('CloudApiService -> prepareSeatForAutoRecording() ERROR - ', error);
        return {
          success: false,
        }
      });
  }

  /**
   * Update the Seat entity on the Casana Cloud via the API and turn off auto recording and auto uploads.
   * This also sets the seat_status to 3 (active, updating) to mark that Setup Wizard is in progress.
   *
   * @param seatId
   */
  public prepareSeatForManualRecording(seatId: number): any {
    let config = this.getRequestConfig();
    let data;

    data = {
      uploadInterval: 14400,
      automaticRecording: false,
      seatStatus: 3
    }

    return axios.patch(this.casanaCloudApiUrl + 'seat/config/' + seatId, data, config).then((res) => {
      return {
        success: true,
      }
    })
      .catch(function (error) {
        console.error('CloudApiService -> prepareSeatForManualRecording() ERROR - ', error);
        return {
          success: false,
          error: error.response.data
        }
      });
  }

  /**
   * Update the Seat entity on the Casana Cloud via the API and turn on auto recording and auto uploads.
   * This occurs after completing the initialization process or upon logout. The seat_status is reset
   * to 1 (active) since disableAutoRecordingAndUpload() sets seat_status to 3 (active, updating)
   * when seats are being configured and initialized via Setup Wizard.
   *
   * @param seatId
   */
  public revertSeatConfiguration(seatId: string): any {
    let config = this.getRequestConfig();

    let data = {
      automaticRecording: true,
      uploadInterval: 0,
      seatStatus: 1
    }

    return axios.patch(this.casanaCloudApiUrl + 'seat/config/' + seatId, data, config).then(() => {
      return {
        success: true,
      }
    })
      .catch(function (error) {
        console.error(error);
        return {
          success: false,
        }
      });
  }

  /**
   * Update the Seat entity on the Casana Cloud to the maximum battery charge. This is invoked in the SeatDebug
   * component, and requires the user to physically replace the battery before making this API call.
   *
   * @param seatId
   */
  public resetSeatBattery(seatId: string): any {
    let config = this.getRequestConfig();

    return axios.patch(this.casanaCloudApiUrl + 'seat/replace_battery/' + seatId, [], config).then(() => {
      return {
        success: true,
      }
    })
      .catch(function (error) {
        console.error(error);
        return {
          success: false,
        }
      });
  }

  /**
   * This function should take in a value from the API response for assigned_seats
   * Sample: [{"1459":"F20AB7EC88FB"}] and return the seat_id for the first
   * value returned even if there is multiple assigned/connected seats.
   * @param assignedSeats
   * @return int
   */
  public extractSeatUserDetail(assignedSeatUsers: any, field: string) {

    // TODO: add some defensive programming to make sure if there are no assigned seats or multiple assigned seats
    // TODO: it still works
    if (assignedSeatUsers.length > 0) {
      if (field === 'id') {
        return parseInt(assignedSeatUsers[0]['seat_user_id']);
      } else {
        return assignedSeatUsers[0]['name'];
      }
    }
  }

}
