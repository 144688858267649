import React, {useEffect, useContext, useState} from 'react';
import {
  IonItem, IonCardContent, IonCardHeader, IonCardTitle, IonCardSubtitle, IonContent,
  IonCard, IonGrid, IonRow, IonCol, IonLabel, IonNote, IonInput, IonFooter, IonButton, IonText
} from '@ionic/react';
import PatientDetailsService from "../../services/PatientDetailsService/PatientDetailsService";
import AuthContext from "../Auth/AuthContext";
import usePatientData from "./PatientHook";
import {localStorageRefs} from "../../Refs";
import {isNumber} from "util";

interface ContainerProps {
  confirmPatient: Function;
  handleBackView: any;
}

const SeatUserId: React.FC<ContainerProps> = ({confirmPatient, handleBackView}) => {
  const patient = usePatientData();
  const authContext = useContext(AuthContext);
  const [isValid, setIsValid] = useState<boolean>(true);
  const [patientPin, setPatientPin] = useState<string>('');
  const [errorText, setErrorText] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const patientDetailsService = new PatientDetailsService(authContext, patient.data);

  useEffect(() => {
    document.title = "Enter Patient's Cloud PIN";
  }, [])

  /**
   * The six character unique user PIN.
   *
   * @param ev
   */
  const handleUserPinSubmission = (ev: Event) => {
    const val = (ev.target as HTMLInputElement).value.toUpperCase();
    setPatientPin(val);
    setIsValid(val.length === 6)
  }

  const handleNext = () => {
    setError(false);
    setErrorText('');
    if(isValid){
      patientDetailsService.retrieveSeatUserDetails(patient.data.seatUserId, patientPin).then((response: any) => {
        console.debug('SeatUserId.tsx -> PatientDetailsService.tsx -> retrieveSeatUserDetails()', response);
        if(response.success){
          patient.parsePatientData(response.data);
          localStorage.setItem(localStorageRefs.patientId, String(patient.data.seatUserId));
          console.debug('SeatUserId.tsx handleNext - patient', patient);
          confirmPatient();
        } else {
          setError(true);
          setErrorText('Unable to find patient. Please try again.');
        }
      }).catch(() => {
        setError(true);
        setErrorText('An unknown error occurred.');
      })
    }
    else {
      setIsValid(false);
      setError(true);
      setErrorText('An unknown error occurred.');
    }
  }

  const handleBack = () => {
    handleBackView();
  }

  // Hack to submit form when enter key is pressed
  const checkEnter = async (e: any) => {
    if (e.key === 'Enter') {
      await handleNext();
    }
  }

  return (
    <>
      <IonContent className="container">
        <IonCard className={error ? 'ion-show flash-message' : 'ion-hide flash-message'}>
          <IonText className="danger">
            {errorText}
          </IonText>
        </IonCard>
        <IonCard className="standard-container">
          <IonCardHeader>
            <IonCardTitle>Enter the Patient Cloud PIN</IonCardTitle>
            <IonCardSubtitle className="m-t-20 m-b-20">You can find the Patient's Cloud PIN in your installer notes or by contacting Casana's support team.</IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent className="standard-container-content">
            <IonGrid className="casana-form seat-user-id-form">
              <IonRow>
                <IonCol size="12" size-md="4">
                  <IonLabel>Patient Cloud PIN</IonLabel>
                  <IonItem className={isValid ? 'ion-valid' : 'ion-invalid'}>
                    <IonInput
                      autofocus={true}
                      type="text"
                      minlength={6} // The patient's unique 6 character PIN.
                      maxlength={6}
                      value={patientPin}
                      onIonChange={(e: any) => handleUserPinSubmission(e)}
                      onKeyUp={(e: any) => checkEnter(e)}
                      debounce={100}
                    ></IonInput>
                    <IonNote slot="error">Please enter the patient's unique six character PIN.</IonNote>
                  </IonItem>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardContent>
          <IonFooter className="standard-container-footer">
            <IonButton className="btn btn-next ion-float-right" onClick={ handleNext }>
              Next
            </IonButton>
            <IonButton className="btn btn-back ion-float-right m-r-10" onClick={ handleBack }>
              Back
            </IonButton>
          </IonFooter>
        </IonCard>
      </IonContent>
    </>
  );
};

export default SeatUserId;
